import { spp } from "utils/network/jsons"

//------------------------------------------------------------------------------
//initial
const inits = {
  ItemNum: 10,
}

class itemNoData {
  static init() {
    return {
      start: 1,
      end: inits.ItemNum,
    }
  }
  static create(start, end) {
    return { start, end }
  }
  static get(pageNoNow, itemsPerPage, itemCount) {
    let itemStart = ((pageNoNow - 1) * itemsPerPage)
    let count = itemCount
    if (count > 0 && count < itemsPerPage)
      return (this.create(itemStart + 1, itemStart + count))
    else if (count === 0)
      return (this.create(0, 0))
    else
      return (this.create(itemStart + 1, itemStart + itemsPerPage))
  }
}

class pageNoData {
  static words = {
    page: ['Previous', '...', 'Next'],
  }
  static init() {
    return {
      now: 1,
      last: 1,
    }
  }
  static create(now, last) {
    return { now, last }
  }
  static get(pageNoNow, itemsPerPage, total) {
    let pageTotal = Math.ceil(total / itemsPerPage)
    return (this.create(pageNoNow, pageTotal))
  }
  
  static getPages(pageNoNow, itemsPerPage, total) {
    let pages = []
    let pageTotal = Math.ceil(total / itemsPerPage)
    const maxPagesToShow = 5;
    let startPage, endPage;
  
    if (pageTotal < 5) {
      if (pageNoNow > 1) {
        pages.push(this.words.page[0]) // Previous
      } else {
        pages.push("　　　　")
      }
  
      // Display all the page numbers
      for (let index = 1; index <= pageTotal; index++) {
        pages.push(`${index}`)
      }
  
      if (pageNoNow < pageTotal) {
        pages.push(this.words.page[2]) // Next
      }
  
    } else {
      // Fixed pages at the head
      let pageStart = ((pageNoNow + 2) > pageTotal ? pageTotal - 2 : pageNoNow - 1) 
  
      if (pageStart < 1) pageStart = 1
  
      if (pageNoNow > 1) {
        pages.push(this.words.page[0]) // Previous
      } else {
        pages.push("　　　　") 
      }
  
      // Centered page numbers
      if (pageNoNow <= 3) {
        startPage = 1;
        endPage = Math.min(maxPagesToShow, pageTotal);
      }
      // Fixed pages at the tail
      else if (pageNoNow >= pageTotal - 2) {
        startPage = Math.max(1, pageTotal - maxPagesToShow + 1);
        endPage = pageTotal;
      }
       // Centered page numbers
      else {
        startPage = pageNoNow - 2;
        endPage = pageNoNow + 2;
      }
  
       // Generate the page numbers array
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
  
      if (pageNoNow < pageTotal) {
        pages.push(this.words.page[2]) // Next
      }
    }
  
    return pages
  }
}

function getNewPageNo(pageNo, buttonText) {
  if(!Object.isObject(pageNo) || !buttonText)
    return null;
  
  let pageNew = pageNo.now
  const value = buttonText
  switch (value) {
    case 'Next':
      if ((pageNew + 1) > pageNo.last)
        return null;
      pageNew += 1
      break
    case 'Previous':
      if ((pageNew - 1) < 1)
        return null;
      pageNew -= 1
      break
    case '...':
      return null;
    default:
      let num = Number(value)
      if (num !== undefined && num > 0)
        pageNew = num
      else
        return null;
  }
  return pageNew
}
//-----------------------------------------------------------------------------

class tableSource {
  isProtein = false
  isProteinQuick = false
  isProteinCount = false
  
  isPeptide = false
  isPeptideList = false
  isPeptideMulti = false
  
  isToolManualPeptide = false
  isACEiPPAnOxPPMutation = false
  
  constructor(tableBodyCell) {
    // console.log('tableBodyCell', tableBodyCell);
    //------------------------------------------------------------------------------
    this.isProtein = 
         (tableBodyCell === spp.protein.search.quick.output)
      || (tableBodyCell === spp.protein.search.classify.output)
      || (tableBodyCell === spp.protein.search.source.output)
      || (tableBodyCell === spp.protein.search.fragment_count.output)
      || (tableBodyCell === spp.protein.search.classify_count.output)
    this.isProteinQuick = 
         (tableBodyCell === spp.protein.search.quick.output)
    this.isProteinCount = 
         (tableBodyCell === spp.protein.search.fragment_count.output)
      || (tableBodyCell === spp.protein.search.classify_count.output)
    //------------------------------------------------------------------------------
    this.isPeptide = 
         (tableBodyCell === spp.peptide.search.advanced.output)
      || (tableBodyCell === spp.peptide.search.classify.output)
      || (tableBodyCell === spp.peptide.search.classify.output_ic50)
      || (tableBodyCell === spp.peptide.search.multi.output)
      || (tableBodyCell === spp.peptide.search.quick_classify.output)
      || (tableBodyCell === spp.peptide.search.inductive_analysis.items)
      || (tableBodyCell === spp.peptide.search.inductive_analysis.items_ic50)
      || (tableBodyCell === spp.peptide.search.classification_advanced_search.output)
      || (tableBodyCell === spp.tool.css_tool_result.items)
      || (tableBodyCell === spp.tool.css_tool_result.items_multi)
    this.isPeptideList = 
         (tableBodyCell === spp.protein.search.peptide_list.output)
    this.isPeptideMulti = 
         (tableBodyCell === spp.peptide.search.multi.output)
      || (tableBodyCell === spp.tool.css_tool_result.items_multi)
      || (tableBodyCell === spp.peptide.search.advanced_multi.output)
    //------------------------------------------------------------------------------
    this.isToolManualPeptide = 
         (tableBodyCell === spp.tool.hot_spot_result_chart.items_adv)
    this.isACEiPPAnOxPPMutation = 
         (tableBodyCell === spp.tool.aceipp_anoxpp_manual_mutation.items)
  }
}

export { inits, itemNoData, pageNoData, getNewPageNo, tableSource }