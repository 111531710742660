import Grid from '@mui/material/Grid';

import scss2 from './ToolsV2.module.scss';
import { breadData } from 'components/bread/BreadBarV2';
import ToolItem from "components/tool/ToolItem";
import paths from 'utils/network/apiPath';
import imgs, { images } from 'utils/imgs';
import cookie from 'utils/cookie';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// http://localhost:3000/tools
// Go in: homepage -> 'Tools' tab -> this page
// path: paths.site.tools
const texts = {
  bread:  breadData.create('Tools', 'Prediction & Calculation Tools'),
}

function toolData(title, content, imgUrl, link) {
  return {title, content, imgUrl, link}
}
export const toolList = [
  toolData(
    'PepHotMap', //1
    "Mapping the peptides to the proteins.",
    images._03Tools['01_HotSpot_Search_test-Photoroom.png'],
    paths.spp.tool.hotspot_search(),
  ),
  toolData(
    'ProteinPeptize', //2
    "Simulating enzyme hydrolysis for proteins.",
    images._03Tools['02_EHP-Tool-test-Photoroom.png'],
    paths.spp.tool.ehp_tool_search(),
  ),
  toolData(
    'PepBitter', //3
    "Predicting the bitterness of peptides.",
    images._03Tools['03_BPP-Tool-test-Photoroom.png'],
    paths.spp.tool.bpp_tool(),
  ),
  toolData(
    'PepVector', //4
    "Obtaining the descriptors of peptides.",
    images._03Tools['04_AASD-Tool_test-Photoroom.png'],
    paths.spp.tool.aasd_tool(),
  ),
  toolData(
    'PepPropCalc', //5
    "Estimating the properties of peptides.",
    images._03Tools['05_PeptideCalculator_test-Photoroom.png'],
    paths.spp.tool.peptide_calculator(),
  ),
  toolData(
    'MultiPepNetwork', //6
    "Inferring the relationships between peptides.",
    images._03Tools['06_Multi-cross_Analysis_test-Photoroom.png'],
    paths.spp.tool.multi_cross_analysis(),
  ),
  toolData(
    'PDCAAS', //7
    "Calculation of PDCAAS value for a mixture.",
    images._03Tools['07_PDCAAS_test-Photoroom.png'],
    paths.spp.tool.pdcaas(),
  ),
  toolData(
    'ProtPeptFold', //8
    "Predicting peptide's structure from amino acid sequence.",
    images._03Tools['08_Structure_prediction_test-Photoroom.png'],
    paths.spp.tool.pp_structure_prediction(),
  ),
  toolData(
    'ProtPepDock', //08-2
    "An efficient and accurate protein-peptide docking tool that integrates structural inference and interaction analysis.",
    images._03Tools['08b_Docking_test-Photoroom.png'],
    paths.spp.tool.pp_docking(),
  ),
  toolData(
    'PepSpeciesMap', //10
    "Mapping the peptides to the proteins. Cross source search tool",
    images._03Tools['10_HotSpot_PDCAAS-Photoroom.png'],
    paths.spp.tool.hotspot_css_tool(),
  ),
  toolData(
    'ACEiP-Predictor', //11
    'ACE-inhibitory Peptide Predictor',
    images._03Tools['11_ACEiPP-Photoroom.png'],
    paths.spp.tool.aceipp(),
  ),
  toolData(
    'AnOxP-Predictor', //12
    'Antioxidative Peptide Predictor',
    images._03Tools['12_AnOxPP-Photoroom.png'],
    paths.spp.tool.anoxpp(),
  ),
]

//---------------------------------------------------------------------------
const Tools = () => {
  return (
    <LayoutPageV2 bread={texts.bread}>
      <div className={scss2.layout}>
        <div className={scss2.frame_grid}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {toolList.map((item, index) => (
              <Grid item key={index}>
                <ToolItem imgUrl={item.imgUrl} altHint={item.title} link={item.link}
                  title={item.title} content={item.content}></ToolItem>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </LayoutPageV2>
  )
}

export default Tools;