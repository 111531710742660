import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Checkbox, Button, FormControl, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, ListItem, OutlinedInput } from "@mui/material";
import { useEffect, useState } from "react";
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js';
import { Icon } from "@mdi/react";
// import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { bool } from 'prop-types';

import v from "assets/scss/_variables.scss";
import v2 from "assets/scss/_variablesV2.scss";
import scss from "./SignUp.module.scss";
import scssLogin from "./Login.module.scss";
import axios, { apiConfig, axiosCatch, axiosState } from "utils/network/axios";
// import { dbList } from "utils/network/axios";
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import paths from "utils/network/apiPath";
import jsons, { spp } from "utils/network/jsons";
import cookie from "utils/cookie";
import { RouterLink } from 'components/router/RouterLink';
import MuiCheckbox from 'components/mui/MuiCheckbox';
import StyledTextField from 'components/tool/StyledTextField';
import ButtonSec12 from 'components/button/ButtonSec12';
import ButtonPrim from 'components/button/ButtonPrim';
import { textData } from './Login';
// import { setRegisterData } from "store/registerSlice"

//---------------------------------------------------------------------------
// http://localhost:3000/signup
const isDisableInput = 0 //true=disable input components
const texts = {
  title: 'Sign up',
  policyStart: 'By clicking Sign up, you agree to our ',
  policyEnd: ' , Data Policy and Cookies Policy.',
  terms: 'Terms',
  back: 'Back',
  signup: 'Sign up',
  already: 'Already have an account? ',
  login: 'Login',
  close: 'Registration is CLOSED.',
}

//---------------------------------------------------------------------------
export default function SignUp(props) {
  const { setInfo } = props
  window.document.body.onbeforeunload = function () {
    return 'Are you sure to leave? Data will be lost.';
  }

  const [nickname, setNickname] = useState(textData.init(cookie.getCookie(cookie.keys.auth.nickname)));
  const [email, setEmail] = useState(textData.init(cookie.getCookie(cookie.keys.auth.email)));
  const [passwd, setPasswd] = useState(textData.init(cookie.getCookie(cookie.keys.auth.password)));
  const [confirmPasswd, setConfirmPasswd] = useState(textData.init(cookie.getCookie(cookie.keys.auth.confirmPassword)))

  const isCheckedTemp = cookie.getCookie(cookie.keys.auth.isChecked);
  let boolValue = isCheckedTemp === "true" ? true : false;
  const [isChecked, setIsChecked] = useState(boolValue);
  const [tncAgree, setTncAgree] = useState(textData.init())
  const [tncVersion, setTncVersion] = useState("")

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const [showPasswdHelpBlock, setShowPasswdHelpBlock] = useState(false)
  const [passwdHelpList, setPasswdHelpList] = useState({
    // uppercase: { isPass: false, text: "at least one uppercase letter" },
    //lowercase: { isPass: false, text: "at least one lowercase letter" },
    letter: { isPass: false, text: "at least one letter" },
    number: { isPass: false, text: "at least 1 number" },
    // specialChar: { isPass: false, text: "at least 1 special character" },
    len8: { isPass: false, text: "minimum 8 characters" },
    // len34: { isPass: false, text: "maximum 34 characters" },
  })

  const [showPasswd, setShowPasswd] = useState(false)
  const [showConfirmPasswd, setShowConfirmPasswd] = useState(false)

  const registerSuccessText = "Successful registration. Please check your email to verify account."
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false)
  const [feedbackMsg, setFeedbackMsg] = useState("")
  // const [feedbackMsg, setFeedbackMsg] = useState(registerSuccessText) //debug
  const [signupBtnText, setSignUpBtnText] = useState(texts.signup)

  //const [outputList, setOutputList] = useState({})
  //const [itemsList, setItemsList] = useState([])

  const maxLenStr = (number) => {
    return `Maximum length is ${number}`
  }

  const emailHandler = (value) => {
    /* eslint-disable-next-line */
    const emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    /* eslint-disable-next-line */
    // const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(value)
    const isValidEmail = /^[\w.-]+@(amway\.com|zymber\.tech|zymotech\.dev)$/.test(value); //only @amway.com, @zymber.tech, @zymber.dev
    // console.log(isValidEmail, value);
    const isRuleEmail = value.search(emailRule)
    // console.log(isRuleEmail, value);

    // validated or not
    if (isRuleEmail !== -1 ) {
      if(isValidEmail) {
        // console.log('length', value.length);
        if (value.length > 50) {
          setEmail(textData.error(value, maxLenStr(50)))
        } else {
          setEmail(textData.init(value))
        }
      } else {
        setEmail(textData.error(value, "Unsupported email domain."))
      }
    } else {
      setEmail(textData.error(value, "Invalid format"))
    }
  }

  const passwdHandler = (value) => {

    let errHelpObj = JSON.parse(JSON.stringify(passwdHelpList))
    let passAmount = 0;

    // at least one uppercase letter
    // if (value.match(/[A-Z]/g)) {
    //   errHelpObj["uppercase"].isPass = true
    //   passAmount++
    // } else {
    //   errHelpObj["uppercase"].isPass = false
    //   passAmount--
    // }

    // // at least one lowercase letter
    // if (value.match(/[a-z]/g)) {
    //   errHelpObj["lowercase"].isPass = true
    //   passAmount++
    // } else {
    //   errHelpObj["lowercase"].isPass = false
    //   passAmount--
    // }
    //at least one letter
    if (value.match(/[a-z]/i)) {
      errHelpObj["letter"].isPass = true
      passAmount++
    } else {
      errHelpObj["letter"].isPass = false
      passAmount--
    }
    // at least 1 number
    if (value.match(/[0-9]/g)) {
      errHelpObj["number"].isPass = true
      passAmount++
    } else {
      errHelpObj["number"].isPass = false
      passAmount--
    }

    // at least 1 special character
    // if (value.match(/[^a-zA-Z\d]/g)) {
    //   errHelpObj["specialChar"].isPass = true
    //   passAmount++
    // } else {
    //   errHelpObj["specialChar"].isPass = false
    //   passAmount--
    // }

    // minimum 8 characters
    if (value.length >= 8) {
      errHelpObj["len8"].isPass = true
      passAmount++
    } else {
      errHelpObj["len8"].isPass = false
      passAmount--
    }

    // maximum 34 characters
    // if (value.length <= 34) {
    //   errHelpObj["len34"].isPass = true
    //   passAmount++
    // } else {
    //   errHelpObj["len34"].isPass = false
    //   passAmount--
    // }


    setPasswdHelpList(errHelpObj)
    if (passAmount < 3) {
      setPasswd(textData.error(value, ""))
      setShowPasswdHelpBlock(true)
    } else {
      setPasswd(textData.init(value))
      setShowPasswdHelpBlock(false)
    }
  }

  const PasswdErrHelpBlock = () => {
    return (
      <Grid className={scss.passwd_err_help_block}>
        {
          Object.values(passwdHelpList).map((item, index) => {
            return (<ListItem key={index} className={scss.err_list_item}>
              <Icon path={item.isPass ? mdiCheckCircle : mdiCloseCircle}
                size={0.6}
                color={item.isPass ? "green" : "red"} />
              <span className={scss.err_text} style={{ color: item.isPass ? "green" : "red" }}>{item.text}</span>
            </ListItem>)
          })
        }
      </Grid>
    )
  }

  const confirmPasswdHandler = (value) => {
    if (value !== passwd.value) {
      setConfirmPasswd(textData.error(value, "Password does not match" ))
    } else {
      setConfirmPasswd(textData.init(value))
    }
  }
  const nicknameHandler = (value) => {
    const validNickname = /^[a-zA-Z0-9 ]+$/;
    // validated or not
    if (value.length >= 1 && value.length <= 10 && validNickname.test(value)) {
      setNickname(textData.init(value));
    } else {
      setNickname(textData.error(value, "Nickname is not available"));
    }
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && isSubmitEnabled) {
      submitData()
    }
  }
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const getTncVersion = () => {
    const config = apiConfig.auth.get_tnc()
    //console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
      
        let item = result.data[jsons.auth.tnc.output.version]
        setTncVersion(item) 
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  let storeVersion = tncVersion;

  const checkboxHandler = (value) => {
    boolValue =value.target.checked
    setIsChecked(boolValue);

    if (boolValue === true) {
      setTncAgree(textData.init(storeVersion)); 

    } else {
      setTncAgree(textData.error('', "Please checkout TNC"));
    }
  };
  // const handleMouseDownPassword = (event) => {
  //     event.preventDefault();
  // }
  
  // const dispatch = useDispatch();
  const saveInfo = () => {
    cookie.setCookie(cookie.keys.auth.nickname, nickname)
    cookie.setCookie(cookie.keys.auth.email, email)
    cookie.setCookie(cookie.keys.auth.password, passwd)
    cookie.setCookie(cookie.keys.auth.confirmPassword, confirmPasswd)
    cookie.setCookie(cookie.keys.auth.isChecked, isChecked)
  };
  const submitData = () => {
    let dataToSend = jsons.auth.register.input(
      // username.value,
      // userID.value,
      email.value,
      passwd.value,
      nickname.value,
      tncAgree.value,
    )

    setSignUpBtnText("Signing Up...")
    setIsSubmitEnabled(false);

    const config = apiConfig.auth.signup(dataToSend)
    // console.log(config);

    axios(config)
      .then(result => {
        // console.log("Register result:", result)
        switch (result.data.result_code) {
          case 200:
            setIsRegisterSuccess(true)
            setSignUpBtnText(texts.signup)
            setFeedbackMsg(registerSuccessText)
            setIsSubmitEnabled(true);
            setInfo(snackInfo.openSuccess("Success register. Will redirect to Login"))

            setEmail(textData.init())
            setPasswd(textData.init())
            setConfirmPasswd(textData.init())
            setNickname(textData.init())
            setTncAgree(textData.init())
            
            break;
          default:
            setIsRegisterSuccess(false)
            setSignUpBtnText(texts.signup)
            setFeedbackMsg("")
            setIsSubmitEnabled(true);
            setInfo(snackInfo.openError(result.data.reason))
        }

      }).catch(err => {
        console.error("Register error:", err)
        let msg = axiosCatch.getMsg(err)
        let code = axiosCatch.getResultCode(err)
        // console.log('msg', msg);

        setIsRegisterSuccess(false)
        setSignUpBtnText(texts.signup)
        setFeedbackMsg("")
        setIsSubmitEnabled(true);
        setInfo(snackInfo.openError(msg))
        if (axiosCatch.needLogin(err))
          cookie.removeCookieAndJump(navigate, location)
      })

  }
  
  useEffect(() => {
    let passAmount = 0
    let fieldList = []
    fieldList.push(email)
    fieldList.push(passwd)
    fieldList.push(confirmPasswd)
    fieldList.push(nickname)
    fieldList.push(tncAgree)

    fieldList.forEach(item => {
      if (item.value !== "" && !item.isErr) passAmount++
    })

    // console.log('fieldList.length', fieldList.length);
    if (passAmount === fieldList.length) {
      setIsSubmitEnabled(true)
    } else {
      setIsSubmitEnabled(false)
    }

    if (axiosState.keepRest(state))
      getTncVersion()

  }, [email, passwd, confirmPasswd, nickname, tncAgree, isChecked, tncVersion]);

  const css = {
    close: 'Title-Audiowide28px-Regular',
    terms: 'Contents-QuanticoBody16px-Medium-White_75',
    already: 'Contents-QuanticoCaption12px-Medium-White_50',
    feedback: 'Contents-QuanticoBody16px-Medium-Green',
  }
  
  return (
    <div className={scssLogin.main}>
      <Grid className={scssLogin.title}>{texts.title}</Grid>
      
      <Grid
        container
        direction="column"
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
      {isDisableInput
      ? <span className={css.close} style={{color: v2.red}}>{texts.close}</span>
      : null }
      
      {/* //------------------------------------------------------------------------------ */}
      { !isRegisterSuccess &&
        <FormGroup className={scssLogin.frame_input}>
          <StyledTextField
            // className={scssLogin.input}
            multiline={false}
            disabled={isDisableInput}
            required
            id="nickname-required"
            placeholder="Nickname"
            autoFocus
            fullWidth
            value={nickname.value}
            helperText={nickname.errMsg}
            error={nickname.isErr}
            onChange={(event) => { nicknameHandler(event.target.value) }}
            onKeyUp={handleKeyPress}
            data-cy="register-nickname"
          />

          <StyledTextField
            // className={scssLogin.input}
            multiline={false}
            disabled={isDisableInput}
            required
            id="email-required"
            placeholder="E-mail"
            autoFocus
            fullWidth
            value={email.value}
            helperText={email.errMsg}
            error={email.isErr}
            onChange={(event) => { emailHandler(event.target.value) }}
            onKeyUp={handleKeyPress}
            data-cy="register-email"
          />

          <FormControl variant="outlined"
            fullWidth
            sx={{'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid rgba(100, 255, 218, 0.16)',
              },
            }}
          >
            <OutlinedInput
              className={scssLogin.input}
              disabled={isDisableInput}
              required
              fullWidth
              type={showPasswd ? 'text' : 'password'}
              id="passwd-required"
              placeholder="Password"
              value={passwd.value}
              error={passwd.isErr}
              onChange={(event) => { passwdHandler(event.target.value) }}
              onKeyUp={handleKeyPress}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPasswd(!showPasswd)
                    }}
                    // onMouseDown={(event) => { handleMouseDownPassword(event) }}
                    edge="end"
                    sx={{color: v2.greenNormal}}
                    disabled={isDisableInput}
                  >
                    {showPasswd ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              }
              data-cy="register-password"
            />
            {passwd.isErr && <FormHelperText sx={{ color: "#d32f2f" }}>{passwd.errMsg}</FormHelperText>}
          </FormControl>

          {showPasswdHelpBlock && <PasswdErrHelpBlock />}

          <FormControl variant="outlined" 
            fullWidth
            sx={{'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid rgba(100, 255, 218, 0.16)',
              },
            }}
          >
            <OutlinedInput
              className={scssLogin.input}
              required
              fullWidth
              type={showConfirmPasswd ? 'text' : 'password'}
              id="passwd-confirm-required"
              placeholder="Confirm Password"
              value={confirmPasswd.value}
              error={confirmPasswd.isErr}
              onChange={(event) => { confirmPasswdHandler(event.target.value) }}
              onKeyUp={handleKeyPress}
              disabled={isDisableInput || ( !isDisableInput && showPasswdHelpBlock)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowConfirmPasswd(!showConfirmPasswd)
                    }}
                    // onMouseDown={(event) => { handleMouseDownPassword(event) }}
                    edge="end"
                    sx={{color: v2.greenNormal}}
                    disabled={isDisableInput}
                  >
                    {showConfirmPasswd ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              }
              data-cy="register-confirm-password"
            />
            {confirmPasswd.isErr && <FormHelperText sx={{ color: "#d32f2f", mt: "0" }}>{confirmPasswd.errMsg}</FormHelperText>}
          </FormControl>
          
          <div className={scss.terms}>
            <MuiCheckbox
              disabled={isDisableInput}
              checked={isChecked}
              onChange={checkboxHandler}
            />
            <div>
              <span className={css.terms}>{texts.policyStart}</span>
              <RouterLink to={paths.auth.terms} onClick={saveInfo}>
                {texts.terms}</RouterLink>
              <span className={css.terms}>{texts.policyEnd}</span>
            </div>
          </div>
        </FormGroup>
      }
      
      {/* //------------------------------------------------------------------------------ */}
      { isRegisterSuccess &&
        // { true && //debug
        <div className={scssLogin.frame_button}>
          <CheckCircleOutlineIcon style={{ paddingLeft: "10px", fontSize: 32, color: 'var(--chart-colors-green)' }} />
          <div className={css.feedback}>{feedbackMsg}</div>
        </div>
      }
      
      {/* //------------------------------------------------------------------------------ */}
      <div className={scssLogin.frame_button}>
        <Button
          className={scssLogin.login_btn}
          variant="contained"
          size="small"
          disabled={isDisableInput || (!isDisableInput && !isSubmitEnabled)}
          // disabled={
          //     !isNextStepBtnEnabled() || !isUsernamePass()
          // }
          sx={{ backgroundColor: `${true ? v.mainColor : v.mainGrey}` }}
          onClick={() => { submitData() }}
          data-cy="register-submit_btn"
        >{signupBtnText}</Button>
        
        <Button
          className={scssLogin.login_btn}
          variant="contained"
          size="small"
          sx={{ backgroundColor: `${true ? v.mainColor : v.mainGrey}` }}
          onClick={() => {
            navigate(-1)
          }}
        >{texts.back}</Button>
      </div>

      <Grid className={scss.go_login_block}>
        <span className={css.already}>{texts.already}</span>
        <ButtonSec12 text={texts.login} onClick={() => navigate(paths.auth.login)} />
      </Grid>
      </Grid>
      
    </div>
  )
}