import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from "react";
// import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';

import scss from './SearchV2.module.scss';
// import v from 'assets/scss/_variables.scss';
import v2 from 'assets/scss/_variablesV2.scss';
import NoneButton from 'components/button/NoneButton';
import NoneTextField from 'components/NoneTextField';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import TextList, { listDate } from 'components/TextList';
import cookie from 'utils/cookie';
import imgs from 'utils/imgs';
import links from 'utils/links';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import Text, { TextLink } from 'components/tag/Text';
import Img from 'components/tag/Img';
import CountUp from 'react-countup';
import TotalV2 from './TotalV2';

//---------------------------------------------------------------------------
/*
bExampleOnValue=false, Search examples will shown in the search bar hit, figma design
bExampleOnValue=true, Search examples will shown in search bar text
 */
const bExampleOnValue = true
const texts = {
  // title: 'Comprehensive database of \nfood-derived bioactive peptides',
  title: 'Smart Protein and Peptide Database',
  // number: 'We have 6289 entries of food-derived bioactive peptides, \n21249 entries of food-derived proteins.', //move to words

  tabs: [
    'Peptide',
    'Protein',
  ],
  search: 'Search',
  // multi: 'Multifunctional Peptide', //move to linkList.js
  // advanced: 'Advanced Search',
}

export const types = {
  peptide: [
    (bExampleOnValue ? listDate('All', '') : listDate('All', 'Search')),
    listDate('SPP ID', 'SPPACEI0018'),
    listDate('AA sequence', 'VY'),
    listDate('AA length', '3'),
    listDate('Peptide name', 'β-Lactorphin'),
    listDate('Source/Organism', 'Milk'),
    listDate('Precursor protein', 'β-Casein'),
    listDate('PubDate', '2020'),
  ],
  protein: [
    (bExampleOnValue ? listDate('All', '') : listDate('All', 'Search')),
    listDate('SPP ID', 'SPPPR0115'),
    listDate('UniProtKB', 'P02662'),
    listDate('Source/Organism', 'Bovine'),
    listDate('Protein name', 'Alpha-S1-casein'),
    listDate('AA length', '214'),
  ]
}

// from BE api
export const filters = {
  peptide: [ //5 peptides_quick_search Remark(I7)
    "All",
    "SPP_ID",
    "Peptide_sequence",
    "Peptide_length",
    "Term",
    "Source",
    "Precursor_protein",
    "PubDate"
  ],

  protein: [ //11 proteins_search Remark(J15)
    "All",
    "SPP_ID",
    "Database_reference",
    "Source",
    "Term",
    // "PubDate",
    "Sequence"
  ]
}

const Line = ({ ...props }) => (
  <span className={scss.text_line} {...props}></span>
)

const StyledTab2 = styled((props) => <Tab
  value={props.value} label={props.value} id={props.value}
   {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    color: v2.white50,

    '&.Mui-selected': {
      color: v2.white,
    },
    /* CSS 'Global class' in MUI xxx component docs */
    // '&.Mui-focusVisible': {},
    // '&.Mui-disabled': {}
  }),
);

export const StyledTab = (props) => {
  return (
    <Tab
      value={props.value} label={props.value} id={props.value}
      {...props} 
    />
  )
}

function TabDiv(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <div className={scss.search_option}>
          {children}
        </div>
      )}
    </div>
  );
}
TabDiv.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

// https://stackoverflow.com/questions/52222988/how-to-focus-a-material-ui-textfield-on-button-click
export function setFocus(ref, isSelectAll = false) {
  setTimeout(() => {ref && ref.current.focus()}, 100);
  if(isSelectAll)
    setTimeout(() => {ref && ref.current.select()}, 100);
}

//---------------------------------------------------------------------------
export default function SearchV2(props) {
  const { output, setInfo } = props
  // console.log(scss.selectColor);
  // console.log(links.home.search.advanced);
  // console.log(links.home.search.advanced.url);
  
  if (output === undefined || output === null || output.length === 0) { //jsons.site.home_number.output
    output["peptide_entries"] = 6983
    output["food_borne_proteins"] = 70372
  }
  const words = {
    number: function (entriesPeptide, entriesProtein) {
      return (<span>
        {`We have `}
        <span style={{color: v2.greenLight}}>
          <CountUp
            start={0}
            end={entriesPeptide}
            duration={1}
          />
        </span>
        {` entries of food-derived bioactive peptides, \n`}
        <span style={{color: v2.greenLight}}>
          <CountUp
            start={0}
            end={entriesProtein}
            duration={1}
          />
        </span>
        {` entries of food-derived proteins.`}
      </span>)},
  }
  
  const cookieTabName = cookie.getCookie(cookie.keys.quickSearch.tabName)
  const cookieTypeIndex = cookie.getCookie(cookie.keys.quickSearch.typeIndex)
  const cookieTypeName = cookie.getCookie(cookie.keys.quickSearch.typeName)
  // const cookieSearchValue = cookie.getCookie(cookie.keys.quickSearch.filterValue)

  const initTabName = (cookieTabName === '' ? texts.tabs[0] : cookieTabName)
  const initTabOption = getTypeOption(initTabName)
  const initTypeIndex = (cookieTypeIndex === '' ? 0 : cookieTypeIndex)
  const initTypeName = (cookieTypeName === '' ? types.peptide[0].label : cookieTypeName)
  // const initSearch = (cookieSearchValue === '' ? types.peptide[0].init : cookieSearchValue)
  const initSearch = ''

  // const [tabValue, setTab] = useState(texts.tabs[0]); //tab name
  // const [typeOption, setTypeOption] = useState(types.peptide);
  // const [typeIndex, setTypeIndex] = useState(0);
  // const [typeValue, setType] = useState(types.peptide[0].label); //type name
  // const [searchValue, setSearch] = useState(types.peptide[0].init);
  const [tabValue, setTab] = useState(initTabName); //tab name
  const [typeOption, setTypeOption] = useState(initTabOption);
  const [typeIndex, setTypeIndex] = useState(initTypeIndex); //index of type
  const [typeValue, setType] = useState(initTypeName); //type name
  const [searchValue, setSearch] = useState(initSearch); //search keyword
  const [isExampleValue, setIsExampleValue] = useState(true) //search keyword is example
  const searchRef = useRef(null)
  
  const [state, setState] = useState(axiosState.init())
  const [multiPeptideObj, setMultiPeptideObj] = useState(snackInfo.init())

  const [proteinClsObj, setProteinClsObj] = useState({})
  function getTypeOption(tabName) {
    if (tabName === texts.tabs[0]) //Peptide
      return types.peptide
    else if (tabName === texts.tabs[1]) //Protein
      return types.protein
  }

  const handleChangeTab = (event, newValue) => {
    // console.debug(newValue)
    setTab(newValue)

    let type = ''
    if (newValue === texts.tabs[0]) //Peptide
    {
      type = types.peptide[0].label
      setTypeOption(types.peptide)
      setTypeIndex(0)
      setType(type)

    }
    else if (newValue === texts.tabs[1]) //Protein
    {
      type = types.protein[0].label
      setTypeOption(types.protein)
      setTypeIndex(0)
      setType(type)
    }
    cookie.setCookie(cookie.keys.quickSearch.tabName, newValue)
    cookie.setCookie(cookie.keys.quickSearch.typeIndex, 0)
    cookie.setCookie(cookie.keys.quickSearch.typeName, type)
  };

  const handleChangeType = (event) => {
    // console.debug(event.target.value)
    let newValue = event.target.value
    // console.debug(newValue)
    setType(newValue)

    // console.debug(tabValue)
    var type = undefined;
    type = getTypeOption(tabValue)

    if (type !== undefined) {
      setTypeOption(type)

      let index = type.findIndex(object => { return object.label === newValue; })
      if (index !== -1) {
        setTypeIndex(index)
        if(isExampleValue) {
          setSearch(type[index].init)
          setSearchDisabled(false)
        }
      }
      else {
        setSearch('')
        setSearchDisabled(true)
      }
      setFocus(searchRef)
    }
  }

  const [searchDisabled, setSearchDisabled] = useState(true)
  const handleChangeKeyword = (event) => {
    setSearch(event.target.value);
    // console.log(event.target.value);
    
    const disabled = event.target.value ? false : true
    setSearchDisabled(disabled)
    // console.log(disabled);
  }
  const handleKeyDown = (e) => {
    if( e.key === 'Enter')
      handleClickSearch()
    else
      setIsExampleValue(false)
  }

  const navigate = useNavigate()
  let location = useLocation()
  const handleClickSearch = () => {
    // console.log('typeValue', typeValue);
    cookie.setCookie(cookie.keys.quickSearch.tabName, tabValue)
    cookie.setCookie(cookie.keys.quickSearch.typeIndex, typeIndex)
    cookie.setCookie(cookie.keys.quickSearch.typeName, typeValue)
    cookie.setCookie(cookie.keys.quickSearch.filterValue, searchValue)

    if (searchValue !== "") {

      if (tabValue === texts.tabs[0]) //Peptide
      {
        let filterValue = filters.peptide[typeIndex]
        // console.log('typeIndex', typeIndex);
        // console.log('filterValue', filterValue);
        cookie.setCookie(cookie.keys.quickSearch.filterSelection, filterValue)

        navigate(paths.spp.peptide.quick_search())
      }
      else if (tabValue === texts.tabs[1]) //Protein
      {
        let filterValue = filters.protein[typeIndex]
        // console.log('typeIndex', typeIndex);
        // console.log('filterValue', filterValue);
        cookie.setCookie(cookie.keys.quickSearch.filterSelection, filterValue)

        navigate(paths.spp.protein.quick_search())
      }
    } else {

      setInfo(snackInfo.openError("Search field cannot be empty"))
    }
  }

  const apiList = () => {
    const config = apiConfig.peptide.listWithDefaultToken()
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let data = result.data[spp.common.output.items]
        const multiPeptideData = data.filter(item => item.classification_col === "multifunctionalpeptides")

        setMultiPeptideObj(multiPeptideData[0])

      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  const getProteinClsList = () => {
    const config = apiConfig.protein.list_class_default_token()
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let data = result.data[spp.common.output.items]
        setProteinClsObj(data)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }


  useEffect(() => {
    apiList()
    getProteinClsList()
  }, []);
  
  //styleName: Contents: Quantico/Caption/12px: Medium;
  const css = {
    text_title: 'Title-Audiowide48px-Regular-LinearGradient',
    text_number: 'Contents-QuanticoBody16px-Medium-White_90',
    text_option: 'Contents-QuanticoCaption12px-Medium-Alpha_80 link',
    }
  
  //styleName: Title: Audiowide/38px: Regular;
  //styleName: Contents: Quantico/Body/16px: Medium;
  return (
    <div className={scss.layout}>
      <Img imgName={imgs.siteV2.home.bg} className={scss.bg}></Img>
      {/* for background-image */}
      {/* <div className={scss.bg}></div> */}
      <div className={scss.frame}>
        <div className={scss.frame_text}>
          <Text center className={css.text_title}>{texts.title}</Text>
          <Text center className={css.text_number}>
            {words.number(output["peptide_entries"], output["food_borne_proteins"])}</Text>
        </div>
        
        <div className={scss.frame_search}>
          <div className={scss.search_input}>
            <div className={scss.input_center}>
              <Tabs value={tabValue} onChange={handleChangeTab} TabIndicatorProps={{ hidden: true }}
                className={scss.tabs}>
                <StyledTab value={texts.tabs[0]} className={scss.tab_left} />
                <StyledTab value={texts.tabs[1]} className={scss.tab_right} />
              </Tabs>
              <div className={scss.center_type}>
                <TextList option={typeOption} index={typeIndex} value={typeValue} className={scss.search_text}
                  onChange={handleChangeType}></TextList>
              </div>
              <div className={scss.center_word}>
                {bExampleOnValue
                ? (<NoneTextField
                    type="search"
                    inputRef={searchRef}
                    value={searchValue} placeholder={texts.search}
                    onChange={handleChangeKeyword}
                    onKeyDown={handleKeyDown}
                  />)
                : (<NoneTextField
                    type="search"
                    inputRef={searchRef}
                    placeholder={searchValue}
                    onChange={handleChangeKeyword}
                    onKeyDown={handleKeyDown}
                  />)
                }
                <IconButton aria-label="search" disabled={searchDisabled}
                  className={scss.button_search} onClick={handleClickSearch}>
                  <SearchIcon />
                </IconButton>
              </div>
            </div>
          </div>
          {/* Peptide */}
          <TabDiv value={tabValue} index={texts.tabs[0]}>
            <div className={scss.option_multi}>
              <TextLink className={css.text_option} link={links.site.home.multi_search}
                onClick={
                  () => {
                    cookie.setCookie(cookie.keys.peptide.selectedPeptideClsName, "Multifunctional peptides")
                    cookie.setCookie(cookie.keys.peptide.selectedPeptideClsId, multiPeptideObj.classification_id)
                    cookie.setCookie(cookie.keys.peptide.selectedMultiPeptideList, [])
                  }}></TextLink>
            </div>
            <Line />
            <div className={scss.option_multi}>
              <TextLink className={css.text_option} link={links.site.home.advanced_search}
                onClick={() => {
                  cookie.setCookie(cookie.keys.peptide.selectedPeptideClsName, "")
                }}></TextLink>
            </div>
          </TabDiv>
          {/* Protein */}
          <TabDiv value={tabValue} index={texts.tabs[1]}>
            {
              Object.entries(proteinClsObj).length !== 0 &&
              Object.entries(proteinClsObj).map((item, index) => {
                // console.log("item:",item)
                return (
                  <React.Fragment key={index}>
                    <div className={index === 0 ? scss.option_milk : scss.option_plant}>
                      <TextLink key={item[1]} className={css.text_option} onClick={() => {
                        const token = cookie.getCookie(cookie.keys.auth.token)

                        cookie.setCookie(cookie.keys.protein.classGraphSelectedClsName, item[0])
                        cookie.setCookie(cookie.keys.protein.classGraphSelectedClsID, item[1])

                        if (token === "" || token === undefined) {
                          navigate(`${paths.auth.login}?redirect=${paths.spp.protein.class_search()}`)
                        } else {
                          navigate(paths.spp.protein.class_search())
                        }
                      }}>
                        <Text>{item[0]}</Text>
                      </TextLink>
                    </div>

                    {index !== Object.entries(proteinClsObj).length - 1 && <Line />}
                  </React.Fragment>)
              })}
          </TabDiv>
        </div>
        
        <div>
          <TotalV2 output={output}>
          </TotalV2>
        </div>
      </div>
    </div>
  );
};
