import React, { useState } from 'react';
import { Fab, Menu, MenuItem, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledMenu } from 'layouts/NavbarV2';

//-----------------------------------------------------
//  SVG Button style
const LogoButton = styled(Fab)(({ theme }) => ({
  position: "fixed",
  top: "89vh",
  left: "94vw",
  width: "45px",
  height: "45px",
  padding: "8px",
  backgroundColor: "var(--white-white)",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
  "&:focus": {
    outline: "none",
  },
  [theme.breakpoints.up('xl')]: {
    left: `calc((100vw - 1440px) / 2 + 1350px)`,
  },
}));

// SVG Logo 
const LogoSvg = () => (
<svg width="45" height="50" viewBox="0 0 45 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.2848 0.494723L33.5197 5.83773L42.7545 11.1807C43.8759 11.8404 44.5355 12.9617 44.5355 14.281V35.719C44.5355 37.0383 43.8759 38.1596 42.7545 38.8193L33.5197 44.1623L24.2848 49.5053C23.1635 50.1649 21.8442 50.1649 20.7229 49.5053L14.5883 45.9433V34.9274L16.4352 35.9828L21.5804 38.9512C22.1741 39.281 22.8997 39.281 23.5593 38.9512L28.7044 35.9828L33.8495 33.0145C34.4432 32.6847 34.839 32.0251 34.839 31.2995V19.558C34.839 18.8324 34.5091 18.2388 33.8495 17.843L28.7044 14.8747L23.5593 11.9063C22.9656 11.5765 22.24 11.5765 21.5804 11.9063L16.4352 14.8747L11.2902 17.843C10.6965 18.1728 10.3007 18.8324 10.3007 19.558V43.4366L2.18725 38.7533C1.06588 38.0937 0.40625 36.9723 0.40625 35.653V14.281C0.40625 12.9617 1.06588 11.8404 2.18725 11.1807L11.4221 5.83773L20.7229 0.494723C21.8442 -0.164908 23.1635 -0.164908 24.2848 0.494723ZM23.1635 16.7216C22.7677 16.4578 22.306 16.4578 21.9102 16.7216L18.612 18.6345L15.3139 20.5475C14.9181 20.8113 14.6543 21.2071 14.6543 21.6688V29.2546C14.6543 29.7163 14.8522 30.1121 15.3139 30.376L18.612 32.2889L21.9102 34.2019C22.306 34.4657 22.7677 34.4657 23.1635 34.2019L26.4616 32.2889L29.7598 30.376C30.1556 30.1121 30.4195 29.7163 30.4195 29.2546V21.6688C30.4195 21.2071 30.1556 20.8113 29.7598 20.5475L26.4616 18.6345L23.1635 16.7216Z" fill="url(#paint0_linear_4006_1760)"/>
<defs>
<linearGradient id="paint0_linear_4006_1760" x1="0.406251" y1="12.5" x2="44.1562" y2="38.6719" gradientUnits="userSpaceOnUse">
<stop stop-color="#64FFDA"/>
<stop offset="0.5" stop-color="#4590E6"/>
<stop offset="1" stop-color="#8D13F3"/>
</linearGradient>
</defs>
</svg>


);

const FloatingActionMenu = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    document.body.style.paddingRight = '0px';
    document.body.style.overflow = 'auto';
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
<Box >
  
      <LogoButton
        aria-controls={open ? "menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <LogoSvg />
      </LogoButton>

      <StyledMenu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </StyledMenu>
      {/* </div> */}
    </Box>
  );
};

export default FloatingActionMenu;

