// -----official tools & third tools-------------------------------------------------------
import Paper from '@mui/material/Paper';
import { Table, TableContainer } from "@mui/material";

// -----custom tools-------------------------------------------------------
import tsbScss from "components/tool/ToolSingleBlock.module.scss";
import { MuiTableContainer,MuiTableContainerNoneBorder } from "components/table/MuiTableV2";
import scssTable from 'components/table/TableV2.module.scss'


//---------------------------------------------------------------------------
const ToolSingleBlock = (props) => {
  const { stepText, title, classTitle, mainBlockContent, anotherBlockContent, subtitleBlock, isDocking=false, ...other } = props
  // -----variables-------------------------------------------------------
  // console.log('classTitle', classTitle);
  let classNameTitle = classTitle ? `${tsbScss.title} ${classTitle}` : tsbScss.title
  let bgColor = isDocking ? tsbScss.main_purple : tsbScss.main

  // -----functions-------------------------------------------------------

  // -----render-------------------------------------------------------
  return (
    <MuiTableContainerNoneBorder>
      <tbody style={{border:'none'}}>
        <tr >
          <td>
            <div className={bgColor}>
              <div className={tsbScss.left_block}>
                <div className={tsbScss.step_text}>
                  {stepText}
                </div>
                <div className={classNameTitle} style={{ userSelect: "none" }}>
                  {title}
                </div>
                <div className={tsbScss.subtitle} style={{ userSelect: "none" }}>
                  {subtitleBlock}
                </div>
              </div>
              <div className={tsbScss.right_block}>
                <div className={tsbScss.main_block}>{mainBlockContent}</div>
                {anotherBlockContent && <div className={tsbScss.main_block}>{anotherBlockContent}</div>}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </MuiTableContainerNoneBorder >
  )
};

export default ToolSingleBlock;