// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

import scss from 'views/protein/ProteinListV2.module.scss';
import { proteinData } from 'views/protein/ProteinList';
import cookie from 'utils/cookie';
import { linkData } from 'utils/links';
import paths from "utils/network/apiPath";
import Text from 'components/tag/Text';
import { ImgLink } from 'components/tag/Img';
import GradientCard from './GradientCard';


//---------------------------------------------------------------------------
const texts = {
  count: 'Results: ',
}

//---------------------------------------------------------------------------
export default function ImgCard({ cardData, ...props }) {
  const card = (cardData === undefined ? proteinData.init() : cardData)
  // const link = linkData('', `${paths.spp.protein.source_search()}?source=${card.source}`)
  const link = linkData('', paths.spp.protein.source_search())
  return (
    <div className={scss.bar_box}>
      <GradientCard variant="outlined" sx={{ display: 'flex' }} onClick={() => {
        cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceID, cardData.source_id)
        cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceName, cardData.source)

        cookie.setCookie(cookie.keys.protein.classGraphSelectedClsID, "")
        cookie.setCookie(cookie.keys.protein.classGraphSelectedClsName, "")
        cookie.setCookie(cookie.keys.peptide.selectedMultiPeptideList, [])

        cookie.setCookie(cookie.keys.tool.ehpSelectedSourceID, cardData.source_id)

      }}>
        <ImgLink className={scss.box_img} imgName={props.imgName} link={link} />

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Link to={link.url}>
            <CardContent className={scss.box_text}>
              <Text className={'Title-Audiowide12px-Regular-Green'}>{card.source}</Text>
              <Text className={'Contents-QuanticoCaption14px-Medium-White'}>{texts.count + card.counts}</Text>
            </CardContent>
          </Link>
        </Box>
      </GradientCard>
    </div>
  );
}