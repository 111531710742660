import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
// import { useRef } from "react";

import cookie from "utils/cookie";
import Router from "./routes";
import ScrollToTop from "components/ScrollToTop";
import SnackBar, { snackInfo } from "components/SnackBar";
import NavbarV2 from "layouts/NavbarV2";
import FooterV2 from "layouts/FooterV2";

//---------------------------------------------------------------------------
const App = () => {
  // const windowWidth = useRef(window.innerWidth);
  // const windowHeight = useRef(window.innerHeight);
  // console.log('width', windowWidth.current);
  // console.log('height', windowHeight.current);

  const [info, setInfo] = useState(snackInfo.init())
  // const pathname = window.location.pathname
  // console.log(pathname);
  
  return (
    <BrowserRouter>
      <div className="App2">
        <NavbarV2 setInfo={setInfo} />
        <ScrollToTop />
        <Router setInfo={setInfo} />
        <FooterV2 />
        
        <SnackBar info={info} handleClose={() => { setInfo(snackInfo.close(info.type, info.msg)) }} />
      </div>
    </BrowserRouter>
  );
};

export default App;
