import LoadingButton from '@mui/lab/LoadingButton';
import { Box,Grid } from '@mui/material';
import { useRef, useState } from 'react';
import {useLocation, useNavigate } from 'react-router-dom';

// -----custom tools-------------------------------------------------------
import 'assets/scss/commonV2.scss';
import scss from './ToolACEiPP.module.scss';
import scssTools from 'views/tool/ToolsV2.module.scss';
import scssButton from 'components/button/Buttons.module.scss'
import { breadData } from 'components/bread/BreadBarV2';
import Text, { TagText } from 'components/tag/Text';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { RouterLink } from 'components/router/RouterLink';
import StyledTextField,{StyledTextFieldDisable} from 'components/tool/StyledTextField';
import { files } from 'utils/files';
import { spp } from 'utils/network/jsons';
import { checkInvalidSeq, isFASTAformat } from 'utils/general';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import GreenButton from 'components/button/GreenButton';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_aceipp
// path: paths.spp.tool.aceipp,
const texts = {
  bread: breadData.create('Tools', 'ACEiP-Predictor', 'ACEiP-Predictor'),

  intro: 'ACEi-Predictor is an AI-driven tool for predicting, designing, and screening ACE-inhibitory peptides (ACEiPs). Using optimized amino acid descriptors (AADs) and a long short-term memory (LSTM) neural network, it efficiently learns key sequence and structure features of ACEiPs, significantly improving prediction accuracy.',
  
  step: [
    'Manual input',
    'Upload file',
  ],
  title: [
    'Enter one or more peptide sequences',
    'Or upload a file in FASTA format directly from your local disk',
  ],
  
  manual: {
    searchHint: 'Please enter the peptide sequences in FASTA format',
    step: '1.Please enter peptide sequence in FASTA format.\n2.Peptide sequence length should be between 2 and 19.\n3.Please enter less than 200 sequences for each forecast.',
    example: '(Example):',
  },
  
  upload: {
    select: 'Select a *.fasta (or *.txt) file: ',
    step: '1.Please upload a *.fasta (or *.txt) file and the sequence must be in FASTA format\n2.Peptide sequence length should be between 2 and 19.',
    file: '(example.fasta):',
    fileName: 'example.fasta',
  },
  
  exampleText: `>Seq001
IHPF
>Seq002
LKPNM
>Seq003
IWH
>Seq004
NMAINPSKENLCSTFCK`,
}

export function cleanACEiPPState() {
  cookie.setCookie(cookie.keys.tool.aceippManualInput, '')
  cookie.setCookie(cookie.keys.tool.aceippUploadResult, '')
}

//---------------------------------------------------------------------------
const ToolACEiPP = ({ setInfo }) => {
  let manualInit = cookie.getCookie(cookie.keys.tool.aceippManualInput)
  
  //---------------------------------------------------------------------------
  // Manual input
  const jsonsManual = spp.tool.aceipp_anoxpp_manual_result
  const [manualInput, setManualInput] = useState(manualInit)
  const [isInputError, setInputError] = useState(false)
  const [manualInputErrMsg, setManualInputErrMsg] = useState("")
  const handleChangeManual = (value) => {
    // console.log('value', value);
    
    setManualInput(value)
    setInputError(false)
  }
  
  const handleClickManualReset = () => {
    setManualInput('')
    cleanACEiPPState()
  }
  
  const handleClickManualSubmit = () => {
    const objInvalidChar = checkInvalidSeq(manualInput);
    const objFASTAformat = isFASTAformat(manualInput);
    
    if (objInvalidChar.isInvalid) {
      setInputError(true)
      setManualInputErrMsg(objInvalidChar.errMsg)
      setInfo(snackInfo.openError(objInvalidChar.errMsg))
    } else if (objFASTAformat.isInvalid) {
      setInputError(true)
      setManualInputErrMsg(objFASTAformat.errMsg)
      setInfo(snackInfo.openError(objFASTAformat.errMsg))
    } else {
      setInputError(false)
      setManualInputErrMsg("")
      
      if (manualInput !== '') {
        cookie.setCookie(cookie.keys.tool.aceippManualInput, manualInput)
        navigate(paths.spp.tool.aceipp_anoxpp_mr(jsonsManual.dataset.ACEiPs))
      } else {
        setInputError(true)
      }
    }
  }
  
  const handleClickExample = () => {
    setManualInput(texts.exampleText)
  }
  
  //----file upload ↓-----------------------------------------------------------------
  // Upload file
  const jsonsUpload = spp.tool.aceipp_anoxpp_upload_result
  const fileInputRef = useRef(null);
  const handleFileSelect = () => { //Browse
    fileInputRef.current.click();
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      setFileName(file.name);
    }
  };
  
  const handleClickFileReset = () => { // Reset
    setFile(null)
    setFileName('');
  }
  
  const handleClickFileUpload = () => {
    if (file === null) {
      setInfo(snackInfo.openError("Please select a *.fasta (or *.txt) file"))
    } else {
      apiFileUpload()
    }
  }
  
  //------------------------------------------------------------------------------
  const [stateList, setStateList] = useState(axiosState.set(false, false, false, 0))
  const navigate = useNavigate()
  let location = useLocation()
  const apiFileUpload = async () => {
    setStateList(axiosState.loadingState())
    const formData = new FormData();
    formData.append('file', file);
    formData.append('dataset', JSON.stringify(jsonsUpload.dataset.ACEiPs))

    const config = apiConfig.tool.aceipp_anoxpp_upload_result(formData)
    // console.log('config', config);
    axios(config).then((result) => {
        // console.log('result', result);
      if (result.data.result_code !== 200) {
        setStateList(axiosState.error(false, stateList.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateList(axiosState.resultCode200())
        
        let data = result.data
        let dataJson = JSON.stringify(data)
        cookie.setCookie(cookie.keys.tool.aceippUploadResult, dataJson)
        // console.log(data)
        // console.log('dataJson', dataJson);
        
        navigate(paths.spp.tool.aceipp_anoxpp_ur(jsonsUpload.dataset.ACEiPs))
      }
    }).catch(err => {
      setStateList(axiosState.error(axiosCatch.isTimeout(err), stateList.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  };
  //----file upload ↑-----------------------------------------------------------------

  const firstGrid = 4, secondGrid = 4, thirdGrid = 5;
  const btnStyle = { marginRight: '10px', backgroundColor: '#dfdfdf', color: '#2c2c2c', width: '90px' }
  //----render-----------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread} desc={texts.intro}>
      <Box className={scssTools.layout}>
        <div className={scss.frame_intro} style={{}}>
          <TagText className={'Contents-QuanticoBody16px-Regular-White_75'} html={texts.intro}></TagText>
        </div>
        
        {/* Manual input */}
        <ToolSingleBlock stepText={texts.step[0]} title={texts.title[0]} mainBlockContent={
            <Grid container spacing={2}>
              <div className={scss.manual_left}>
                <StyledTextField
                  maxRows={400}
                  className={scss.input_box}
                  placeholder={texts.manual.searchHint}
                  label={texts.manual.searchHint}
                  required
                  rows={10}
                  value={manualInput}
                  onChange={(event) => { handleChangeManual(event.target.value) }}
                  error={isInputError}
                  helperText={manualInputErrMsg}
                />
                <div className={scss.manual_btn_box}>
                  <GreenButton style={{marginRight:'10px'}} onClick={handleClickManualSubmit}>Submit</GreenButton>
                  <GreenButton onClick={handleClickManualReset}>Reset</GreenButton>
                </div>
              </div>
              
              <div className={scss.manual_right}>
                <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>{texts.manual.step}
                  <Text className={'Contents-QuanticoBody16px-SemiBold-GreenAlpha_75 link'} onClick={handleClickExample}>
                    {texts.manual.example}</Text>
                </Text>
                
                <StyledTextFieldDisable maxRows={8}
                  className={scss.example_box}
                  value={texts.exampleText}
                  disabled
                />
              </div>
            </Grid>
          }>
        </ToolSingleBlock>
        
        {/* Upload file */}
        <ToolSingleBlock stepText={texts.step[1]} title={texts.title[1]} mainBlockContent={
          <Grid container spacing={2}>
            <div className={scss.manual_left}>
                {/* Select a *.fasta (or *.txt) file: */}
                <Grid container spacing={1} direction="row" alignItems="center" style={{gap: '8px'}}>
                    <Grid item>
                      <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>{texts.upload.select}</Text>
                    </Grid>
                </Grid>
                
                {/* Browse */}
                <Grid container spacing={1} direction="row" alignItems="center" style={{gap: '8px'}}>
                  <Grid item>
                    <input accept=".fasta, .txt" type="file"
                      hidden
                      className="custom-file-input" data-target="file-uploader" id="file-uploader"
                      ref={fileInputRef}
                      onChange={handleChangeFile}
                    />
                  <LoadingButton
                    sx={{
                      '& .MuiLoadingButton-loadingIndicator': {
                        color: 'white',},
                      '&.Mui-loading': {
                        color: 'white',
                        backgroundColor: 'var(--green-alpha-alpha-8)',
                        '& .MuiButton-startIcon': {
                          color: 'white'
                        }},
                      '&.Mui-disabled': {
                        color: 'grey',
                        opacity: 0.7},
                    }}
                    className={scssButton.button}
                    onClick={handleFileSelect}
                    disabled={stateList.isLoading}
                  >Browse</LoadingButton>
                  </Grid>
                  <Grid item>
                    {fileName !== "" && <span className={'Contents-QuanticoBody16px-SemiBold-GreenAlpha_75'} style={{whiteSpace: 'break-word', wordBreak: 'break-all'}}>
                      {fileName}</span>}
                  </Grid>
                </Grid>
                
                {/* button */}
                <Grid container spacing={1} direction="row" alignItems="center">
                  <Grid item s={thirdGrid}>
                    <Box >
                    <LoadingButton
                      sx={{
                        marginRight:'10px',
                        width:'93px',
                        '& .MuiLoadingButton-loadingIndicator': {
                          color: 'white',
                        },
                        '&.Mui-loading': {
                          backgroundColor: 'var(--green-alpha-alpha-8)',
                          '& .MuiButton-startIcon': {
                            color: 'white'
                          },
                        },
                        '&.Mui-disabled': {
                          color: 'grey',
                          opacity: 0.7
                        }
                      }}
                      className={scssButton.button}
                      onClick={handleClickFileUpload}
                      disabled={stateList.isLoading || file === null}
                      loading={stateList.isLoading}
                    >Upload</LoadingButton>

                      <GreenButton onClick={handleClickFileReset}>Reset</GreenButton>
                    </Box>
                  </Grid>
                </Grid>
            </div>
            
            <div className={scss.manual_right}>
              <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>{texts.upload.step}
                <a href={files.root[texts.upload.fileName]} download={texts.upload.fileName} className={'Contents-QuanticoBody16px-SemiBold-GreenAlpha_75 link'}>
                  {texts.upload.file}</a>
              </Text>
                <StyledTextFieldDisable maxRows={8}
                  className={scss.example_box}
                  value={texts.exampleText}
                  disabled
                />
            </div>
          </Grid>
        }>
        </ToolSingleBlock>
      </Box >
    </LayoutPageV2 >
  )
}
export default ToolACEiPP