// define file src path
//Get file name & path (windows)
//$ dir /b /a:-d /s * > list.txt
//Get file name & path (mac & linux)
//$ ls -R -A | grep -v ^d > list.txt

// ACEiP-Predictor-tool download file
/*
assets\file\
benchmark_negative.txt
benchmark_positive.txt
testACEiPs.txt
test_negative.txt
test_positive.txt
total_datasets_negative.txt
total_datasets_positive.txt
*/

//---------------------------------------------------------------------------
//require.context(directory {String}=讀取檔案的路徑, useSubdirectories {Boolean}=是否遍歷檔案的子目錄, regExp {RegExp}=匹配檔案的正則)
//The arguments passed to require.context must be literals!!!
const files = {
  root: importAll(require.context('assets/file/', false, /\.(te?xt)|.fasta$/)),
  
  /* ToolACEiPP: {
    SeqFeatures: importAll(require.context('assets/file/ToolACEiPP/SeqFeatures', false, /\.(te?xt)$/)),
    PreLibraries: importAll(require.context('assets/file/ToolACEiPP/PreLibraries', false, /\.(te?xt)$/)),
  },
  ToolAnOxPP: {
    Home: importAll(require.context('assets/file/ToolAnOxPP', false, /\.(pdf)$/)),
    SeqFeatures: importAll(require.context('assets/file/ToolAnOxPP/SeqFeatures', false, /\.(te?xt)$/)),
    PreLibraries: importAll(require.context('assets/file/ToolAnOxPP/PreLibraries', false, /\.(te?xt)$/)),
  }, */
}

function importAll(r) {
  let obj = {};
  r.keys().forEach((item, index) => { obj[item.replace('./', '')] = r(item); });
  // console.log(objs);
  return obj;
}

//------------------------------------------------------------------------------
const pathToolACEiPPAnOxPP = '/A9PF4tN3' // random
const filePaths = {
  ToolACEiPP: {
    SeqFeatures: `${pathToolACEiPPAnOxPP}/ToolACEiPP/SeqFeatures/`,
    PreLibraries: `${pathToolACEiPPAnOxPP}/ToolACEiPP/PreLibraries/`,
  },
  ToolAnOxPP: {
    SeqFeatures: `${pathToolACEiPPAnOxPP}/ToolAnOxPP/SeqFeatures/`,
    PreLibraries: `${pathToolACEiPPAnOxPP}/ToolAnOxPP/PreLibraries/`,
  },
}
const fileNames = {
  ToolACEiPP: {
    SeqFeatures: [
      'total_datasets_positive.txt',
      'total_datasets_negative.txt',
      'benchmark_positive.txt',
      'benchmark_negative.txt',
      'test_positive.txt',
      'test_negative.txt',
      'testACEiPs.txt',
    ],
    // PreLibraries: [], //file name list from api
  },
  ToolAnOxPP: {
    PDF: 'AnOxPP.pdf',
    SeqFeatures: [
      'total_datasets_positive.txt',
      'total_datasets_negative.txt',
      'training_AnOxPs.txt',
      'training_non-AnOxPs.txt',
      'test_AnOxPs.txt',
      'test_non-AnOxPs.txt',
      'new_AnOxPs.txt',
    ],
    // PreLibraries: [], //file name list from api
  },
}

//---------------------------------------------------------------------------
const fs = {
  files,
  fileNames,
  filePaths,
}
export {files, fileNames, filePaths}
export default fs
