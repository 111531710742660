import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import scss from 'views/protein/ProteinSelection.module.scss';
import scss from './SearchResultLayout.module.scss';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import { inits, itemNoData, pageNoData, getNewPageNo } from 'layouts/search/tableData';
import cookie from 'utils/cookie';
import axios, { axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import SearchFullResultTable from './SearchFullResultTable';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// http://localhost:3000/protein_peptide_list?id=SPPPR7006&cls=renin_inhibitory_peptides
// http://localhost:3000/tool_aceipp_anoxpp_mm?&dataset=1&keyword=IHPF&site=1
export default function SearchFullResultLayout(props) {
  const { tableBodyCell, setInfo } = props
  let texts = props.texts
  let inputProp = props.inputProp
  let dbItems = props.jsonItems ? props.jsonItems : spp.common.output.items
  // console.log(dbItems);
  // console.log('inputProp', inputProp);

  const [output, setOutput] = useState({})
  const [items, setItems] = useState([])
  const [itemNo, setItemNo] = useState(itemNoData.init()) //item index 1-10
  const [itemsPerPage, setItemsPerPage] = useState(inits.ItemNum) //item number per page
  const eventChangeItemsPerPage = (value) => {
    if (value !== undefined) {
      setItemsPerPage(value)
      setPageNo(pageNoData.init())
      if (keyword !== '') {
        setInput(props.jsonInputKeyword(...inputProp, keyword, value, 1))
      } else {
        setInput(props.jsonInput(...inputProp, value, 1))
      }
      setState(axiosState.init())
    }
  }

  let words = {//No effect
    index: [`${itemNo.start}-${itemNo.end}`, `of ${output.total}`],
    page: ['Previous', '...', 'Next'],
  }
  const [pageNo, setPageNo] = useState(pageNoData.init())//No effect
  const [pages, setPages] = useState([`${pageNo.now}`, ...words.page])//No effect
  const handleClickPage = (value) => {//No effect
    // console.log(value);
    let pageNew = getNewPageNo(pageNo, value)
    if(!pageNew)
      return;
    
    // console.log('pageNew', pageNew)
    setPageNo(pageNoData.create(pageNew, pageNo.last))

    if (keyword !== '')
      setInput(props.jsonInputKeyword(...inputProp, keyword, itemsPerPage, pageNew))
    else
      setInput(props.jsonInput(...inputProp, itemsPerPage, pageNew))
    setState(axiosState.init())
  }
  const [keyword, setKeyword] = useState('')//No effect
  const handleChangeSearch = (value) => {//No effect
    // console.log('value', value);
    setKeyword(value)
  }
  const handleClickSearch = () => {//No effect
    if (keyword !== '') {
      setInput(props.jsonInputKeyword(...inputProp, keyword, itemsPerPage))
    }
    else {
      setInput(props.jsonInput(...inputProp, itemsPerPage))
    }
    setState(axiosState.init())
  }

  const [input, setInput] = useState(props.jsonInput(...inputProp, itemsPerPage))

  function removeEmptyStringKeys(obj) {//No effect
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value !== "" && value !== 0)
    );
  }
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const eventResult = () => {
    let newInput = removeEmptyStringKeys(input)

    // const selectedMultiPeptideList = cookie.getCookie(cookie.keys.peptide.selectedMultiPeptideList)

    // if (selectedMultiPeptideList.length !== 0 && !("classification" in newInput) && !("source" in newInput)) {
    //   newInput["class_list"] = selectedMultiPeptideList
    // }
    const config = props.configSearch(newInput)
  //  console.log('config', config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        setOutput(data)
        // console.log(data)
        let item = result.data[dbItems]
        setItems(item)
        // console.log(item)

        let count = item.length
        let total = data.total
        // console.log('count', count);
        // console.log('pageNo.now', pageNo.now);
        
        let itemNoNow = itemNoData.get(pageNo.now, itemsPerPage, count)
        setItemNo(itemNoNow)
        // console.log(itemNoNow);
        let pagesNew = pageNoData.getPages(pageNo.now, itemsPerPage, total)
        let pageNoNew = pageNoData.get(pageNo.now, itemsPerPage, total)
        setPages(pagesNew)
        setPageNo(pageNoNew)
        // console.log(pagesNew);
        // console.log(pageNoNew);
      }
    }).catch(err => {
      // console.log(err);
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  useEffect(() => {
    if (axiosState.keepRest(state))
      eventResult()
    // eslint-disable-next-line
  }, [input])

  // console.log('props.inputProp', props.inputProp);
  return (
    <LayoutPageV2 bread={texts.bread}
      searchInput={props.inputProp} jsonInput={props.jsonInput}
      searchOutput={output}>
      <div className={scss.layout}>
        <SearchFullResultTable
          tableHeadCell={texts.tableHeadCell}
          tableBodyCell={texts.tableBodyCell}
          isLoading={state.isLoading}
          output={output}
          inputProp={props.inputProp}
          info={props.jsonInfo}
          items={items}
          itemNo={itemNo}
          eventChangeItemsPerPage={eventChangeItemsPerPage}
          texts={texts}
          words={words}
          pages={pages}
          pageNo={pageNo}
          handleClickPage={handleClickPage}
          keyword={keyword}
          handleChangeSearch={handleChangeSearch}
          handleClickSearch={handleClickSearch}
        />
      </div>
    </LayoutPageV2>
  )
}