import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import StyledTextField from 'components/tool/StyledTextField';
import scss from './Dialog.module.scss';
import GreenButton from 'components/button/GreenButton';
import { isJobName, isOverMax } from 'utils/general';

const RenameDialog = ({
  open,
  onClose,
  onConfirm,
  initialName,
  maxSize = 0,
  title = 'Rename'
}) => {
  const [newName, setNewName] = useState(initialName);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const handleNameChange = (value) => {
    const objInvalidChar = isJobName(value)
    const objMax = isOverMax(value, maxSize, "chars")
    
    setNewName(value);
    if (!value.trim()) {
      setError(true);
      setHelperText('Name is required');
    } else if (objInvalidChar.isInvalid) {
      setError(true)
      setHelperText(objInvalidChar.errMsg)
    } else if( objMax.isInvalid && maxSize > 0 ) {
      setError(true);
      setHelperText(objMax.errMsg);
    } else {
      setError(false);
      setHelperText('');
    }
  };

  const handleConfirm = () => {
    const objInvalidChar = isJobName(newName)
    const objMax = isOverMax(newName, maxSize, "chars")
    
    if (!newName.trim()) {
      setError(true);
      setHelperText('Name is required');
      return;
    } else if (objInvalidChar.isInvalid) {
      setError(true)
      setHelperText(objInvalidChar.errMsg)
      return;
    } else if( objMax.isInvalid && maxSize > 0 ) {
      setError(true);
      setHelperText(objMax.errMsg);
      return;
    }
    
    onConfirm(newName);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={scss.dialog_fixed}
    >
      <DialogTitle className={scss.dialog_title}>
        {title}
      </DialogTitle>
      <DialogContent className={scss.dialog_content}>
        <StyledTextField
          fullWidth
          value={newName}
          onChange={(e) => handleNameChange(e.target.value)}
          placeholder="Enter new name"
          required
          error={error}
          helperText={helperText}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
      </DialogContent>
      <DialogActions className={scss.dialog_actions}>
        <GreenButton onClick={onClose}>
          Cancel
        </GreenButton>
        <GreenButton 
          onClick={handleConfirm}
          disabled={error || !newName.trim()}
        >
          Confirm
        </GreenButton>
      </DialogActions>
    </Dialog>
  );
};

export default RenameDialog;