import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { backdropClasses, Box, Button, Card, CardActionArea, CardMedia, Dialog, DialogContent, Grid, IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import { TableHead, TableRow, tableCellClasses, InputBase } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CircularProgress } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Pause } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import ClearIcon from '@mui/icons-material/Clear';

import v2 from "assets/scss/_variablesV2.scss";
import scss from './ToolPPStructurePrediction.module.scss'
import scssACE from './ToolACEiPP.module.scss';
import scssTable from 'components/table/TableV2.module.scss'
import scssPage from 'views/protein/ProteinSelection.module.scss';
import { breadData } from 'components/bread/BreadBarV2';
import { images } from 'utils/imgs';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import LoadingAnime from 'components/LoadingAnime';
import { MuiTableCell, MuiTableCellPurple, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTableV2';
import Text, { TagText } from 'components/tag/Text';
import { appendFromObjectKayValue, getKeyByValue } from 'utils/object';
import { spp } from 'utils/network/jsons';
import StyledTableCell from 'components/table/StyledTableCell';
import { StyledTextFieldPurple, StyledTextFieldStandardDark } from 'components/tool/StyledTextField';
import GreenButton from 'components/button/GreenButton';
import DarkGreenButton from 'components/button/DarkGreenButton';


import MuiLoadingButton from 'components/mui/MuiLoadingButton';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { checkInvalidSeq, getFormatTime, getFullPath, isEmpty, isFASTAformat, isJobName, isLetter, isOverMax, isUnderMin } from 'utils/general';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTextField,{StyledTextFieldDisable}  from 'components/tool/StyledTextField';
import { files } from 'utils/files';
import MuiTooltip from 'components/mui/MuiTooltip';
import { inits, itemNoData, pageNoData, getNewPageNo, tableSource } from 'layouts/search/tableData';
import { MenuButton } from 'layouts/search/SearchResultTable';
import { removeEmptyStringKeys } from 'layouts/search/SearchResultLayout';
import { StyledMenu } from 'layouts/NavbarV2';
import DarkPurpleButton from 'components/button/DarkPurpleButton';
import AlertDialog from 'components/dialog/AlertDialog';
import RenameDialog from 'components/dialog/RenameDialog';
import { linkData } from 'utils/links';
import { setFocus } from 'layouts/home/SearchV2';
import FilterButton, { filterStatus, PurpleFilterButton } from 'components/button/FilterButton';
import { GreenSmallButton, PurpleSmallButton } from 'components/button/SmallButton';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_ppsp

const jsonsList = spp.tool.ppsp_ppd_job_list
const jsonsJob = spp.tool.ppsp_ppd_job
const maxNameSize = 100
const minSequenceSize = 2
const maxSequenceSize = 1000
const maxPeptideSize = 100
export const texts_org = {
  bread:  breadData.create('Tools', 'ProtPeptFold', 'ProtPeptFold'),
  desc: 'ProtPeptFold is a protein and peptide structure prediction tool based on AlphaFold 3. It enables researchers to accurately predict 3D structures of proteins and peptides, facilitating studies on molecular interactions, drug design, and functional analysis. Leveraging deep learning, ProtPeptFold provides high-precision structural models, supporting advancements in computational biology and bioinformatics.',
  
  step: [
    '',
  ],
  title: [
    'Enter prediction job',
  ],
  
  manual: {
    label: 'Sequence',
    labelJobName: 'Name',
    hintInput: 'Please enter your sequence',
    step: `Sequence length should be between ${minSequenceSize} and ${maxSequenceSize}.`,
    example: 'Example',
  },
  
  menu: {
    // open: 'Open results',
    stop: 'Stop',
    download: 'Download',
    // clone: 'Edit',
    rename: 'Rename',
    delete: 'Delete',
  },

  exampleText: `SVNEVPDYHEDIHTYLREMEVKCKPKVGYMKKQPDITNSMRAILVDWLVEVGEEYKLQNETLHLAVNYI`,
  exampleText2: '',
  
  // table
  jsonsList: jsonsList,
  tableHeadCell: jsonsList.items_text,
  tableBodyCell: jsonsList.items,
  // tableBodyCellError: jsons.error_items,
  searchHint: 'Search by Status, Name, User',
}

export function cleanPPSPState() {
  cookie.setCookie(cookie.keys.tool.ppspManualName, '')
  localStorage.setItem(cookie.keys.tool.ppspManualInput, '')
}
export function cleanPPDState() {
  cookie.setCookie(cookie.keys.tool.ppdManualName, '')
  localStorage.setItem(cookie.keys.tool.ppdManualInput, '')
  cookie.setCookie(cookie.keys.tool.ppdManualInput2, '')
}
//------------------------------------------------------------------------------

export function getDefaultJobName() {
  let now = new Date()
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份從 0 開始
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  let jobName = `${year}-${month}-${day}_${hours}:${minutes}`;
  // console.log('jobName', jobName);
  return jobName
}

export const TableHeadAndRow = (props) => (
  <TableHead>
    <TableRow >
      {props.children}
    </TableRow>
  </TableHead>
)

export const MuiTableCell4Head = (props) => {
  const {children, index, cell1stAlignRight, alignLeft, isDocking = false , ...other} = props
  let align = "center"
  
  if( cell1stAlignRight !== undefined && index === 0)
    align = "right"
  if( alignLeft !== undefined )
    align = 'left'
  
  return (
    isDocking ? (
      <MuiTableCellPurple align={align}  {...other}>
      {children}</MuiTableCellPurple>
    ) : (
    <MuiTableCellPurple align={align} isGreen={true} {...other}>
      {children} </MuiTableCellPurple>
      )
      )
}

export const TableCell4HeadTop = (props) => {
  let style = {fontSize: 16,}
  if(props.style !== undefined)
    Object.assign(style, props.style)
  // console.log(style);
  return (
    <MuiTableCell4Head align="center" style={{style}} {...props}>
      {props.children}
    </MuiTableCell4Head>)
}

export function getListCellAlign(index, title = null, json = null) {
  // console.log(index, title, json);
  if(title === 'Name' ||
    title === 'Name' || json === 'job_name' || json === "display_name" ||
    title === 'Sequence' || json === 'job_input'||
    title === 'Date' || json === 'created_at' ||
    title === 'Last modified' || json === 'modified_at' ||
    title === 'Elapsed time' || json === 'run_time' )
    return 'left'
  else if( title === 'Elapsed time' || json === 'run_time' )
    return 'right'
  return 'center'
}
export function getListCellStyle(index, title = null, json = null) {
  let style = {}
  // 連動 spp.tool.ppsp_ppd_job_list.items_text
  switch (title) {
    case 'Status':
      style = { width: 60 }
      break
    case 'Last modified':
    case 'Date':
        style = { width: 200 }
        break
    case 'Elapsed time':
      style = { width: 130 }
      break
    case 'User':
      style = { width: 130 }
      break
  }
  switch (json) {
    case 'view':
    case 'more':
      style = { width: 60 }
      break
  }
  return style
}

export const getStateIcon = (value, isPurple = false) => {
  let valueReturn = value
  let isNG = false
  let statusTip = "";
  // eslint-disable-next-line default-case
  switch (value) {
    case jsonsList.status.processing:
      valueReturn = <CircularProgress size={20} style={{ color: v2.greenNormal }} />
      statusTip =
        <MuiTooltip title="Processing">
          {valueReturn}
        </MuiTooltip>
      break;
    case jsonsList.status.waiting:
      valueReturn = <CircularProgress size={20} style={{ color: v2.greenNormal }} />
      statusTip =
        <MuiTooltip title="Preparing">
          {valueReturn}
        </MuiTooltip>
      break;
    case jsonsList.status.todo:
      valueReturn = <CircularProgress size={20} style={{ color: v2.greenNormal }} />
      statusTip =
        <MuiTooltip title="Initializing">
          {valueReturn}
        </MuiTooltip>
      break;
    case jsonsList.status.stopped:
    case jsonsList.status.stopping:
      valueReturn = <RemoveCircleOutlineIcon style={{ color: v2.red }} />
      statusTip =
        <MuiTooltip isRed={true} title="Stopped">
          {valueReturn}
        </MuiTooltip>
      break
    case jsonsList.status.ng: //"failed"
      isNG = true
      valueReturn = <HighlightOffOutlinedIcon style={{ color: v2.red }} />
      statusTip =
        <MuiTooltip isRed={true} title="Failed">
          {valueReturn}
        </MuiTooltip>
      break
    case jsonsList.status.ok: //"completed"
      valueReturn = <CheckCircleOutlineIcon style={{ color: isPurple ? v2.logoPurpleLink : v2.green75 }} />
      statusTip = isPurple
      ? (<MuiTooltip isDocking title="Completed">{valueReturn}</MuiTooltip>)
      : (<MuiTooltip title="Completed">{valueReturn}</MuiTooltip>)
      break
  }

  return (
    // <MuiTooltip isNG={isNG} title={String(value).to1stUpperCase()}>
    //   {valueReturn}
    // </MuiTooltip>
    <>{statusTip}</>
  )
}

export const scrollToBottom = () => {
  window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
};

//---------------------------------------------------------------------------
export default function ToolPPStructurePrediction({ setInfo, isDocking = false }) {
  let texts = {...texts_org}
  changeDocking()
  useEffect(() => {
      changeDocking()
  }, [isDocking]);
  function changeDocking() {
    if(isDocking) {
      texts.bread = breadData.create('Tools', 'ProtPepDock', 'ProtPepDock')
      texts.desc = 'ProtPepDock is a protein-peptide docking tool based on LocalColabFold, designed to predict and analyze the interactions between peptides and protein targets. By leveraging deep learning and structural modeling, ProtPepDock enables high-precision docking simulations, facilitating research in drug discovery, molecular interactions, and peptide-based therapeutics.'

      texts.title = [
        'Enter docking job',
      ]
      texts.manual = {
        ...texts.manual,
        label: 'Protein sequence',
        label2: 'Peptide sequence',
        hintInput: 'Please enter your protein sequence',
        hintInput2: 'Please enter your peptide sequence',
        step: 'Sequence length should be between 2 and 1000.',
      }
      texts.exampleText =`SVNEVPDYHEDIHTYLREMEVKCKPKVGYMKKQPDITNSMRAILVDWLVEVGEEYKLQNETLHLAVNYIDRFLSSMSVLRGKLQLVGTAAMLLASKFEEIYPPEVAEFVYITDDTYTKKQVLRMEHLVLKVLAFDLAAPTINQFLTQYFLHQQPANCKVESLAMFLGELSLIDADPYLKYLPSVIAAAAFHLALYTVTGQSWPESLVQKTGYTLETLKPCLLDLHQTYLRAPQHAQQSIREKYKNSKYHGVSLLNPPETLNVHHHHHHH`
      texts.exampleText2 = `IHPF`
    }
  }
  
  let location = useLocation()
  let bread = linkData(texts.bread.text3rd, getFullPath(location))
  const json3rd = JSON.stringify(bread)
  // console.log(bread, json3rd);
  
  let initName = cookie.getCookie(isDocking
    ? cookie.keys.tool.ppdManualName
    : cookie.keys.tool.ppspManualName)
  if( initName?.trim() === '' )
    initName = getDefaultJobName()
  // console.log('initName', initName);
  let manualInit = localStorage.getItem(isDocking
    ? cookie.keys.tool.ppdManualInput
    : cookie.keys.tool.ppspManualInput)
  if(!manualInit)
    manualInit = ''
  let manualInit2 = cookie.getCookie(cookie.keys.tool.ppdManualInput2)
  if(!manualInit2)
    manualInit2 = ''

  //Manual input v---------------------------------------------------------------------------
  const jsonsSubmit = spp.tool.ppsp_ppd_submit
  const [jobName, setJobName] = useState(initName)
  const [isJobNameError, setJobNameError] = useState(false)
  const [jobNameErrMsg, setJobNameErrMsg] = useState("")
  const onChangeJobName = (value) => {
    // console.log('value', value);
    
    setJobName(value)
    let isNameOK = checkName(value)
    let isInputOK = checkInput(manualInput, false)
    if(isDocking) {
      let isInputOK2 = checkInput2(manualInput2, false)
      setSubmitDisabled((isNameOK && isInputOK && isInputOK2) === false)
      setResetDisabled(isAllInputDefault(value, manualInput, manualInput2))
    } else {
      setSubmitDisabled((isNameOK && isInputOK) === false)
      setResetDisabled(isAllInputDefault(value, manualInput, manualInput2))
    }
    
    savePPSPname(value)
  }
  
  function checkName(input, showError = true) {
    const objEmpty = isEmpty(input, texts.manual.labelJobName)
    const objInvalidChar = isJobName(input)
    const objMax = isOverMax(input, maxNameSize, "chars")
    let isNameOK = false
    // console.log(objMax);
    
    if (objEmpty.isInvalid) {
      if( showError ) {
      setJobNameError(true)
      setJobNameErrMsg(objEmpty.errMsg)
      }
    } else if (objInvalidChar.isInvalid) {
      if( showError ) {
      setJobNameError(true)
      setJobNameErrMsg(objInvalidChar.errMsg)
      }
    } else if (objMax.isInvalid) {
      if( showError ) {
      setJobNameError(true)
      setJobNameErrMsg(objMax.errMsg)
      }
    } else {
      setJobNameError(false)
      setJobNameErrMsg("")
      
      isNameOK = true
    }
    return isNameOK
  }
  
  // Sequence / Protein A
  const inputRef = React.useRef();
  const inputRefDocking = React.useRef();
  const [manualInput, setManualInput] = useState(manualInit)
  const [isInputError, setInputError] = useState(false)
  // const [manualDisabled, setManualDisabled] = useState(false)
  const [manualInputErrMsg, setManualInputErrMsg] = useState("")
  const onChangeManual = (value) => {
    // console.log('value', value);
    value = value?.toUpperCase()
    setManualInput(value)
    let isInputOK = checkInput(value)
    let isNameOK = checkName(jobName, false)
    if(isDocking) {
      let isInputOK2 = checkInput2(manualInput2, false)
      setSubmitDisabled((isNameOK && isInputOK && isInputOK2) === false)
      setResetDisabled(isAllInputDefault(jobName, value, manualInput2))
    } else {
      setSubmitDisabled((isNameOK && isInputOK) === false)
      setResetDisabled(isAllInputDefault(jobName, value, ''))
    }
    
    savePPSPinput(value)
  }
  
  function checkInput(manualInput, showError = true) {
    const objEmpty = isEmpty(manualInput, texts.manual.label)
    const objInvalidChar = isLetter(manualInput)
    const objInvalidSeq = checkInvalidSeq(manualInput);
    const objMin = isUnderMin(manualInput, minSequenceSize, "", true)
    const objMax = isOverMax(manualInput, maxSequenceSize, "", true)
    let isInputOK = false
    
    if (objEmpty.isInvalid) {
      if( showError ) {
      setInputError(true)
      setManualInputErrMsg(objEmpty.errMsg)
      }
    } else if (objInvalidChar.isInvalid) {
      if( showError ) {
      setInputError(true)
      setManualInputErrMsg(objInvalidChar.errMsg)
      }
    } else if (objInvalidSeq.isInvalid) {
      if( showError ) {
      setInputError(true)
      setManualInputErrMsg(objInvalidSeq.errMsg)
      }
    } else if (objMin.isInvalid) {
      if( showError ) {
      setInputError(true)
      setManualInputErrMsg(objMin.errMsg)
      }
    } else if (objMax.isInvalid) {
      if( showError ) {
      setInputError(true)
      setManualInputErrMsg(objMax.errMsg)
      }
    } else {
      setInputError(false)
      setManualInputErrMsg("")
      
      isInputOK = true
    }
    return isInputOK
  }
  
  //------------------------------------------------------------------------------
  // Peptide B
  const [manualInput2, setManualInput2] = useState(manualInit2)
  const [isInputError2, setInputError2] = useState(false)
  const [manualDisabled2, setManualDisabled2] = useState(false)
  const [manualInputErrMsg2, setManualInputErrMsg2] = useState("")
  const onChangeManual2 = (value) => {
    // console.log('value', value);
    value = value?.toUpperCase()
    setManualInput2(value)
    let isInputOK2 = checkInput2(value)
    let isNameOK = checkName(jobName, false)
    let isInputOK = checkInput(manualInput, false)
    setSubmitDisabled((isNameOK && isInputOK && isInputOK2) === false)
    setResetDisabled(isAllInputDefault(jobName, manualInput, value))
    
    savePPSPinput2(value)
  }
  
  function checkInput2(manualInput2, showError = true) {
    let isOK = false
    if(isDocking) {
      const objEmpty2 = isEmpty(manualInput2, texts.manual.label2)
      const objInvalidChar2 = isLetter(manualInput2);
      const objInvalidSeq2 = checkInvalidSeq(manualInput2);
      const objMin2 = isUnderMin(manualInput2, minSequenceSize, "", true)
      const objMax2 = isOverMax(manualInput2, maxPeptideSize, "", true)
      let isInputOK2 = false
      if (objEmpty2.isInvalid) {
        if( showError ) {
        setInputError2(true)
        setManualInputErrMsg2(objEmpty2.errMsg)
        }
      } else if (objInvalidChar2.isInvalid) {
        if( showError ) {
        setInputError2(true)
        setManualInputErrMsg2(objInvalidChar2.errMsg)
        }
      } else if (objInvalidSeq2.isInvalid) {
        if( showError ) {
        setInputError2(true)
        setManualInputErrMsg2(objInvalidSeq2.errMsg)
        }
      } else if (objMin2.isInvalid) {
        if( showError ) {
        setInputError2(true)
        setManualInputErrMsg2(objMin2.errMsg)
        }
      } else if (objMax2.isInvalid) {
        if( showError ) {
        setInputError2(true)
        setManualInputErrMsg2(objMax2.errMsg)
        }
      } else {
        setInputError2(false)
        setManualInputErrMsg2("")
        
        isInputOK2 = true
      }
      isOK = isInputOK2
    }
    return isOK
  }
  
  // Reset
  const [resetDisabled, setResetDisabled] = useState(isAllInputDefault(jobName, manualInput, manualInput2))
  function isAllInputDefault(jobName, manualInput, manualInput2 = '') {
    // console.log(jobName, manualInput, manualInput2);
    let isInputNullName = (jobName === getDefaultJobName())
    let isInputNull = (manualInput === '')
    
    if(isDocking) {
      let isInputNull2 = (manualInput2 === '')
      return (isInputNullName && isInputNull && isInputNull2)
    } else {
      return (isInputNullName && isInputNull)
    }
  }
  const handleClickManualReset = () => {
    let name = getDefaultJobName()
    setJobName(name)
    savePPSPname('')
    setJobNameError(false)
    setJobNameErrMsg('')
    setManualInput('')
    setInputError(false)
    setManualInputErrMsg('')
    
    if(isDocking) {
      setManualInput2('')
      setInputError2(false)
      setManualInputErrMsg2('')
      cleanPPDState()
    } else {
      cleanPPSPState()
    }
    setSubmitDisabled(true)
    setResetDisabled(true)
  }
  function savePPSPname(text) {
    cookie.setCookie(isDocking
    ? cookie.keys.tool.ppdManualName
    : cookie.keys.tool.ppspManualName, text)
  }
  function savePPSPinput(text) {
    localStorage.setItem(isDocking
    ? cookie.keys.tool.ppdManualInput
    : cookie.keys.tool.ppspManualInput, text)
  }
  function savePPSPinput2(text) {
    cookie.setCookie(cookie.keys.tool.ppdManualInput2, text)
  }
  
  // Submit
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(() => (!checkInputAll(false)))
  const handleClickManualSubmit = () => {
    let isOK = checkInputAll()
    if(isOK) {
      apiSubmit()
    }
  }
  function checkInputAll(showError = true, name = jobName, input = manualInput, input2 = manualInput2) {
    let isNameOK = checkName(name, showError)
    let isInputOK = checkInput(input, showError)
    // console.log(isNameOK, jobName);
    // console.log(isInputOK, manualInput);
    if(isDocking) {
      let isInputOK2 = checkInput2(input2, showError)
      // console.log(isInputOK2, manualInput2);
      return (isNameOK && isInputOK && isInputOK2)
    } else {
      return (isNameOK && isInputOK)
    }
  }
  
  // Example
  const handleClickExample = () => {
    const exampleText = texts.exampleText
    setManualInput(exampleText)
    setInputError(false)
    setManualInputErrMsg('')
    savePPSPinput(exampleText)
    savePPSPname(jobName)
    // console.log('jobName', jobName);
    if(isDocking) {
      const exampleText2 = texts.exampleText2
      setManualInput2(exampleText2)
      setInputError2(false)
      setManualInputErrMsg2('')
      savePPSPinput2(exampleText2)
      
      setResetDisabled(isAllInputDefault(jobName, manualInput, exampleText2))
      setSubmitDisabled(!checkInputAll(false, jobName, exampleText, exampleText2))
    } else {
      setResetDisabled(isAllInputDefault(jobName, exampleText, ''))
      setSubmitDisabled(!checkInputAll(false, jobName, exampleText, ''))
    }
  }
  
  const navigate = useNavigate()
  const apiSubmit = () => {
    setSubmitLoading(true)
    const input = isDocking
    ? jsonsSubmit.input_docking(manualInput?.toUpperCase(), manualInput2?.toUpperCase(), jobName)
    : jsonsSubmit.input(manualInput?.toUpperCase(), jobName)
    const config = apiConfig.tool.ppsp_ppd_submit(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateTable(axiosState.error(false, stateTable.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateTable(axiosState.resultCode200())
        // console.log(result.data);
        
        setSubmitLoading(false)
        setJobName(getDefaultJobName())
        setManualInput('')
        if(isDocking) {
          setManualInput2('')
          setManualDisabled2(false)
          cleanPPDState()
        } else {
          cleanPPSPState()
        }
        
        let data = result.data
        let code = data.result_code
        console.log('result_code', code);
        
        setIsTableRefresh(true)
        scrollToBottom()
        
        setTimeout(() => {
          apiAsk()
        }, 10000)
      }
    }).catch(err => {
      setStateTable(axiosState.error(axiosCatch.isTimeout(err), stateTable.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
      setSubmitLoading(false)
    })
  }
  //Manual input ^------------------------------------------------------------------------------
  
  //result table v------------------------------------------------------------------------------
  // page control
  const configSearch = apiConfig.tool.ppsp_ppd_job_list //110
  const jsonInput = isDocking ? jsonsList.input_docking : jsonsList.input
  const inputProp = []
  const jsonInputKeyword = isDocking ? jsonsList.input_docking_keyword : jsonsList.input_keyword
  
  const [output, setOutput] = useState({})
  const [itemNo, setItemNo] = useState(itemNoData.init()) //item index 1-10
  const [itemsPerPage, setItemsPerPage] = useState(inits.ItemNum) //item number per page
  const eventChangeItemsPerPage = (value) => {
    if (value !== undefined) {
      setItemsPerPage(value)
      setPageNo(pageNoData.init())
      if (keyword !== '')
        setInput(jsonInputKeyword(...inputProp, keyword, value, 1))
      else {
        setInput(jsonInput(...inputProp, value, 1))
      }
      setStateTable(axiosState.init())
    }
  }
  
  let words = {
    index: [`${itemNo.start}-${itemNo.end}`, `of ${output.total}`],
    page: ['Previous', '...', 'Next'],
    goto: 'Go to',
  }
  
  const [pageNo, setPageNo] = useState(pageNoData.init())
  const [pages, setPages] = useState([`${pageNo.now}`, ...words.page])
  const handleClickPage = (value) => {

    const pageNew = getNewPageNo(pageNo, value)
    if (!pageNew)
      return;

    // console.log('pageNew', pageNew)
    setPageNo(pageNoData.create(pageNew, pageNo.last))

    if (keyword !== '') {
      setInput(jsonInputKeyword(...inputProp, keyword, itemsPerPage, pageNew, 'Status', filters))
    } else {
      setInput(jsonInput(...inputProp, itemsPerPage, pageNew, 'Status', filters))
    }
    setStateTable(axiosState.init())
    setGoToValue("");
  }
  const setPage = (value) => {
    const pageNew = getNewPageNo(pageNo, value)
    if (!pageNew)
      return;

    // console.log('pageNew', pageNew)
    setPageNo(pageNoData.create(pageNew, pageNo.last))
    setGoToValue("");
  }
  
  const [keyword, setKeyword] = useState('')
  const onChangeSearch = (value) => {
    // console.log('value', value);
    setKeyword(value)
  }
  const onKeyDownSearch = (event) => {
    if (event.key === "Enter") {
      onClickSearch();
    }
  }
  const onClickSearch = () => {
    // console.log('keyword', keyword);
    if (keyword !== '') {
      setInput(jsonInputKeyword(...inputProp, keyword, itemsPerPage, 1, 'Status', filters))
    } else {
      setInput(jsonInput(...inputProp, itemsPerPage, pageNo.now, 'Status', filters))
    }
    setStateTable(axiosState.init())
  }
  function onClickClearSearch() {
    onChangeSearch('')
    setInput(jsonInput(...inputProp, itemsPerPage, pageNo.now, 'Status', filters))
    setStateTable(axiosState.init())
  }
  
  const [input, setInput] = useState(jsonInput(...inputProp, itemsPerPage))
  
  const [goToValue, setGoToValue] = React.useState("");
  const onInputChange = (event) => {
      // Replace any non-digit characters with an empty string
      let newValue = event.target.value.replace(/\D/g, "");
      let resetPage = 0;
      // Update the input's value
      // event.target.value = newValue;
    if (newValue > lastPage) {
      resetPage = lastPage; 
      setGoToValue(resetPage); 
    } else if (newValue < 1) {
      resetPage = 1; 
      setGoToValue(resetPage);
    } else {
      setGoToValue(newValue);
    }
    event.target.value = "";
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleClickPage(event.target.value);
      event.target.value = "";
      setGoToValue("");
    }
  };
  
  function newPageButton(item, index) {
    const isCurrentPage = (item === String(pageNo.now) || (item === Number(pageNo.now) && !isNaN(item)))
    // const isEllipsis = item === String(words.page[1]);
    let isSpace = false
    if (item === "　　　　") {
      return (
      <MenuButton key={index} isSpace={true}> {item} </MenuButton>)
    }
    if (isCurrentPage) {
      return (
      <MenuButton key={index} isDefault={true}  isSelected={isCurrentPage}> {item} </MenuButton>)
    }

    return (
      <MenuButton
        key={index}
        isSelected={isCurrentPage}
        onClick={() => handleClickPage(item)}
      >
        {item}
      </MenuButton>
    );
  }
  //------------------------------------------------------------------------------
  const [filters, setFilters] = useState(filterStatus.map((filter) => filter.label));
  function onClickFilter(filters) {
    setPage(1)
    let input = jsonInput(...inputProp, itemsPerPage, 1, 'Status', filters)
    if (keyword !== '')
      input = jsonInputKeyword(...inputProp, keyword, itemsPerPage, 1, 'Status', filters)
    // console.log(filters);
    // console.log(input);
    setInput(input)
  }
  
  function onClickRefresh() {
    setIsTableRefresh(true)
  }
  
  //------------------------------------------------------------------------------
  const [isTableRefresh, setIsTableRefresh] = useState(false)
  const [tableItems, setTableItems] = useState([])
  const [stateTable, setStateTable] = useState(axiosState.init())
  const [lastPage, setLastPage] = useState(0)

  const apiTableList = () => {
    setStateTable(axiosState.loadingState())
    
    let newInput = removeEmptyStringKeys(input)
    const config = apiConfig.tool.ppsp_ppd_job_list(newInput)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateTable(axiosState.error(false, stateTable.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateTable(axiosState.resultCode200())
        // console.log(result.data);
        let data = result.data
        setOutput(data)
        // console.log(data)
        
        let items = data[spp.common.output.items]
        if(items !== undefined) {
          setTableItems(items)
          let count = items.length
          let total = data.total
          // console.log('count', count);
          // console.log('total', total);
          
          let itemNoNow = itemNoData.get(pageNo.now, itemsPerPage, count)
          setItemNo(itemNoNow)
          // console.log(itemNoNow);
          let pagesNew = pageNoData.getPages(pageNo.now, itemsPerPage, total)
          let pageNoNew = pageNoData.get(pageNo.now, itemsPerPage, total)
          setPages(pagesNew)

          setPageNo(pageNoNew)
          // console.log(pagesNew);
          setLastPage(pageNoNew.last)
        }
        // console.log('items', items);
      }
    }).catch(err => {
      setStateTable(axiosState.error(axiosCatch.isTimeout(err), stateTable.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  const TableBodyCell = (props) => {
    const { tableBodyCell, children, index,  ...other } = props
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    // console.log('index', index);
    let status = children['status']
    let isOK = (status === jsonsList.status.ok)
    const isProcessing = (status === jsonsList.status.waiting || status === jsonsList.status.todo || status === jsonsList.status.processing )
    let value, text, align, rowSpan = 1, key
    const colorOK = isDocking ? v2.logoPurpleLink : v2.green75
    let displayName = children['display_name']
    let id = children['job_id']
    let input = children['job_input']
    let lv1arr = input ? input[jsonsJob.output_job.lv1arr] : ''
    let lv1arr0 = lv1arr ? lv1arr[0] : ''
    let lv2obj = lv1arr0 ? lv1arr0[jsonsJob.output_job.lv2obj] : ''
    let lv3seq = lv2obj ? lv2obj[jsonsJob.output_job.lv3] : ''
    let sequence = lv3seq.toUpperCase()
    let sequence2 = ''
    if(isDocking) {
      let lv1arr1 = lv1arr ? lv1arr[1] : ''
      let lv2obj2 = lv1arr1 ? lv1arr1[jsonsJob.output_job.lv2obj] : ''
      let lv3seq2 = lv2obj2 ? lv2obj2[jsonsJob.output_job.lv3] : ''
      sequence2 = lv3seq2.toUpperCase()
    }
    return (
      tableBodyCell.map((cell, indexX) => 
      {
        key = index+''+indexX+''+cell
        value = children[cell]
        text = value
        if(text === undefined)
          text = '-'
        // console.log('cell', cell, 'value', value);
        align = getListCellAlign(indexX, null, cell)
        if(cell === 'status' ) {
          // console.log('isDocking', isDocking);
          text = getStateIcon(value, isDocking)
        } else if (cell === 'view') {
          if(isProcessing) {
            text = null
          } else {
            text = (
              <IconButton key={'btn'+cell+indexX}
                sx={{ color: colorOK }}
                onClick={(e) => {onClickList(e, children, index)}}
              >
                <VisibilityIcon size={20} />
              </IconButton>)
          }
        } else if (cell === 'display_name'){
          // eslint-disable-next-line default-case
          switch (status) {
            case "failed":
              let textError = children['error_message']
              text = (
                <MuiTooltip isRed={true} title={textError}>
                  <span onClick={() => onClickClone(index, sequence, sequence2, displayName)}
                    style={{ cursor: "pointer" }}>{value}</span>
                </MuiTooltip>
              )
              break
            case "stopped":
              text = (
                <MuiTooltip isRed={true} title="The job has been stopped by user request">
                  <span onClick={() => onClickClone(index, sequence, sequence2, displayName)}
                    style={{ cursor: "pointer" }}>{value}</span>
                </MuiTooltip>
              );
              break;
            default:
              text = (
              <span onClick={() => onClickClone(index, sequence, sequence2, displayName)}
                style={{ cursor: "pointer" }}>{value}</span>
              )
          }
        } else if (cell === 'run_time') {
          if (!value)
            text = ""
          else (text = getFormatTime(value))
        } else if( cell === 'more') {
          //move to rowChildren()
          return null
        }
        
        return (
        <MuiTableCellPurple isGreen={!isDocking} key={index + '' + indexX + '' + cell} align={align} rowSpan={rowSpan}
          style={{
            paddingTop: '2px', paddingBottom: '2px', height: '32px',
            fontFamily: 'Quantico',
            color: (cell === 'display_name') ? colorOK : v2.white,
          }}>
          {text}
        </MuiTableCellPurple>)
      }))
  }
  
  const rowChildren = (item, index) => {
    // console.log(item);
    // console.log(item.status);
    const status = item['status']
    const isNG = (status === jsonsList.status.ng)
    const isOK = (status === jsonsList.status.ok)
    const isStop = (status === jsonsList.status.stopped)
    const isProcessing = (status === jsonsList.status.waiting || status === jsonsList.status.todo || status === jsonsList.status.processing )

    const colorOK = isDocking ? v2.logoPurpleLink : v2.green75
    let jobName = item['job_name']
    let displayName = item['display_name']
    let id = item['job_id']
    let input = item['job_input']
    let lv1arr = input ? input[jsonsJob.output_job.lv1arr] : ''
    let lv1arr0 = lv1arr ? lv1arr[0] : ''
    let lv2obj = lv1arr0 ? lv1arr0[jsonsJob.output_job.lv2obj] : ''
    let lv3seq = lv2obj ? lv2obj[jsonsJob.output_job.lv3] : ''
    let sequence = lv3seq.toUpperCase()
    let sequence2 = ''
    if(isDocking) {
      let lv1arr1 = lv1arr ? lv1arr[1] : ''
      let lv2obj2 = lv1arr1 ? lv1arr1[jsonsJob.output_job.lv2obj] : ''
      let lv3seq2 = lv2obj2 ? lv2obj2[jsonsJob.output_job.lv3] : ''
      sequence2 = lv3seq2.toUpperCase()
    }
    
    let rowSpan = 1
    return (
      <TableRow2Body key={index} className={scssTable.body_row_interactive}>
        <TableBodyCell key={index} index={index}
          tableBodyCell={texts.tableBodyCell}>
          {item}
        </TableBodyCell>
        
        {/* more ------------------------------------------------------------------------------ */}
        <MuiTableCellPurple isGreen={!isDocking} key={index+'more'}
          align={getListCellAlign(null, null, 'more')} rowSpan={rowSpan}
          style={{
            paddingTop: '2px', paddingBottom: '2px', height: '32px',
          }}>
          <IconButton key={'btn'+index}
            onClick={(e) => onClickMore(e, index)}
            sx={{
              "&:hover": {
                boxShadow: isDocking
                ? "0px 4px 32px 0px rgba(150, 136, 206, 0.6)" 
                : "0px 4px 32px 0px rgba(100, 255, 218, 0.6)",
              },
            }}
          >
            <MoreVertIcon key={'icon'+index} sx={{ color: colorOK }}/>
          </IconButton>
        
          <StyledMenu
            id={'menu' + index}
            key={'menu' + index}
            anchorEl={menuAnchors[index]}
            open={Boolean(menuAnchors[index])}
            onClose={() => onCloseMenu(index)}
            keepMounted
            disableAutoFocusItem
          >
            {(!isProcessing) ?
              (<MenuItem
                sx={{
                  color: 'gray',
                  pointerEvents: 'none',
                  cursor: 'not-allowed',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }} >{texts.menu.stop}</MenuItem>) : (<MenuItem onClick={() => onClickStop(index, id, displayName)}>{texts.menu.stop}</MenuItem>)
            }
            {(!isOK) ?
              (<MenuItem
                sx={{
                  color: 'gray',
                  pointerEvents: 'none',
                  cursor: 'not-allowed',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }} >{texts.menu.download}</MenuItem>) : (<MenuItem onClick={() => onClickDownload(index, jobName, displayName)}>{texts.menu.download}</MenuItem>)
            }
            <MenuItem onClick={() => onClickRename(index, id, displayName)}>{texts.menu.rename}</MenuItem>
            <MenuItem onClick={() => onClickDelete(index, id, displayName)}>{texts.menu.delete}</MenuItem>
          </StyledMenu>
        </MuiTableCellPurple>
      </TableRow2Body>)
  }
  
  function onClickList(e, item, index) {
    const isClickMenu = Boolean(menuAnchors[index])
    if(!isClickMenu) {
      cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
      cookie.setCookie(cookie.keys.protein.bread4th, '')
      cookie.setCookie(cookie.keys.protein.bread5th, '')
      let id = item['job_id']
      navigate(paths.spp.tool.pp_structure_prediction_result(id))
    }
  }
  //------------------------------------------------------------------------------
  const [menuAnchors, setMenuAnchors] = useState({});
  function onClickMore(event, rowIndex) {
    event.preventDefault()
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation()
    
    // console.log('currentTarget', event.currentTarget);
    setMenuAnchors((prev) => ({
      ...prev,
      [rowIndex]: event.currentTarget,
    }));
  };
  function onCloseMenu(rowIndex) {
    setMenuAnchors((prev) => ({
      ...prev,
      [rowIndex]: null,
    }));
  };
  
  function onClickOpen(rowIndex, id) {
    onCloseMenu(rowIndex)
    if(!id)
      return ;
    cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
    cookie.setCookie(cookie.keys.protein.bread4th, '')
    cookie.setCookie(cookie.keys.protein.bread5th, '')
    navigate(paths.spp.tool.pp_structure_prediction_result(id))
  }
  function onClickDownload(rowIndex, jobName, displayName) {
    onCloseMenu(rowIndex)
    if(!jobName)
      return ;
    // const cifFile = `/smartpp-af3/output/${jobName}/${jobName}_model_0.cif` // fold_eyklvvvgac_model.cif
    // const downloadUrl = `https://smartpp-dev.new-app.site/smartpp-af3/output/${jobName}/${jobName}_model.cif`
    const downloadUrl = `${process.env.PUBLIC_URL}/smartpp-af3/output/${jobName}/${jobName}_model.cif`
    const downloadName = displayName.replaceAll(':' , '_') + '.cif'
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = downloadName;
    link.style.display = 'none';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    console.log('downloadUrl', downloadUrl);
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function onClickClone(rowIndex, sequence, sequence2, displayName) {
    // console.log(rowIndex, 'sequence', sequence);
    onCloseMenu(rowIndex)
    if(!sequence)
      return ;
    
    setJobName(displayName)
    savePPSPname(displayName)
    setManualInput(sequence)
    savePPSPinput(sequence)
    if(isDocking) {
      setSubmitDisabled(false)
      setResetDisabled(false)
      setManualInput2(sequence2)
      savePPSPinput2(sequence2)
      setFocus(inputRefDocking, true)
    } else {
      setSubmitDisabled(false)
      setResetDisabled(false)
      setFocus(inputRef, true)
    }
  }
  function onClickRename(rowIndex, id, displayName) {
    onCloseMenu(rowIndex)
    if(!id || !displayName)
      return ;
    onOpenRename(id, displayName)
  }
  function onClickDelete(rowIndex, id, displayName) {
    // console.log(rowIndex, 'id', id);
    onCloseMenu(rowIndex)
    if(!id)
      return ;
    onOpenDelete(id, displayName)
  }
  
  function onClickStop(rowIndex, id, displayName) {
    // console.log(rowIndex, 'id', id);
    onCloseMenu(rowIndex)
    if(!id)
      return ;
    onOpenStop(id, displayName)
  }
  
  const [jobID, setJobID] = useState()
  const [displayName, setDisplayName] = useState('')
  const [openStop, setOpenStop] = React.useState(false);
  const onOpenStop = (id, displayName) => {
    setJobID(id);
    setDisplayName(displayName)
    setOpenStop(true);
  };
  const onCloseStop = () => {
    setOpenStop(false);
    setJobID();
    setDisplayName('')
  };
  const DialogStop = (props) => {
    const { jobID, displayName } = props;
    // console.log(jobID, displayName);
    return (
      <AlertDialog
        open={openStop}
        onClose={() => onCloseStop()}
        onConfirm={() => {
          onCloseStop();
          apiJobStop(jobID);
        }}
        title="Confirm Stop"
        // content={`Are you sure you want to stop ${displayName}?`}
        content={
          <div>
            Are you sure you want to stop <span style={{color: 'var(--green-alpha-alpha-90)'}}>{displayName}</span>?
            </div>
        }

        confirmText="Stop"
      />
    );
  };
  
  const [openRename, setOpenRename] = React.useState(false);
  const onOpenRename = (id, displayName) => {
    setJobID(id);
    setDisplayName(displayName)
    setOpenRename(true);
  };
  const onCloseRename = () => {
    setJobID();
    setOpenRename(false);
  };
  const DialogRename = (props) => {
    const { jobID, displayName } = props;
    // console.log(jobID, displayName);
    return (
      <RenameDialog
        open={openRename}
        onClose={() => onCloseRename()}
        onConfirm={(nameNew) => {
          onCloseRename();
          apiJobRename(jobID, nameNew);
        }}
        initialName={displayName}
        maxSize={maxNameSize}
      />
    );
  };
  
  const [openDelete, setOpenDelete] = React.useState(false);
  const onOpenDelete = (id, displayName) => {
    setJobID(id);
    setDisplayName(displayName)
    setOpenDelete(true);
  };
  const onCloseDelete = () => {
    setOpenDelete(false);
    setJobID();
  };
  const DialogDelete = (props) => {
    const { jobID, displayName } = props;
    // console.log(jobID, displayName);
    return (
      <AlertDialog
        open={openDelete}
        onClose={() => onCloseDelete()}
        onConfirm={() => {
          onCloseDelete();
          apiJobDelete(jobID);
        }}
        title="Confirm Delete"
        // content={`Are you sure you want to delete ${displayName}?`}
        content={
          <div>
            Are you sure you want to delete <span style={{color: 'var(--green-alpha-alpha-90)'}}>{displayName}</span>?
          </div>
        }
        confirmText="Delete"
      />
    );
  };
  
  //------------------------------------------------------------------------------
  // API
  const [stateRename, setStateRename] = useState(axiosState.init())
  const apiJobRename = (id, nameNew) => {
    setStateRename(axiosState.loadingState())
    
    const jsonsRename = spp.tool.ppsp_ppd_job_rename
    const input = jsonsRename.input(id, nameNew)
    const config = apiConfig.tool.ppsp_ppd_job_rename(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateRename(axiosState.error(false, stateRename.numResultError + 1))
        setInfo(snackInfo.openError(axiosCatch.getMsg(result.data)))
      } else {
        setStateDelete(axiosState.resultCode200())
        // let data = result.data
        // console.log(data)
        setIsTableRefresh(true)
      }
    }).catch(err => {
      setStateRename(axiosState.error(axiosCatch.isTimeout(err), stateRename.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
    })
  }
  
  const [stateStop, setStateStop] = useState(axiosState.init())
  const apiJobStop = (id) => {
    setStateStop(axiosState.loadingState())
    
    const jsonsStop = spp.tool.ppsp_ppd_job_stop
    const input = jsonsStop.input(id)
    const config = apiConfig.tool.ppsp_ppd_job_stop(input)
    console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateStop(axiosState.error(false, stateStop.numResultError + 1))
        setInfo(snackInfo.openError(axiosCatch.getMsg(result.data)))
      } else {
        setStateDelete(axiosState.resultCode200())
        // console.log(result.data);
        // let data = result.data
        // console.log(data)
        setIsTableRefresh(true)
      }
    }).catch(err => {
      setStateStop(axiosState.error(axiosCatch.isTimeout(err), stateStop.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
    })
  }
  
  const [stateDelete, setStateDelete] = useState(axiosState.init())
  const apiJobDelete = (id) => {
    setStateDelete(axiosState.loadingState())
    
    const jsonsDelete = spp.tool.ppsp_ppd_job_delete
    const input = jsonsDelete.input(id)
    const config = apiConfig.tool.ppsp_ppd_job_delete(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateDelete(axiosState.error(false, stateDelete.numResultError + 1))
        setInfo(snackInfo.openError(axiosCatch.getMsg(result.data)))
      } else {
        setStateDelete(axiosState.resultCode200())
        // console.log(result.data);
        // let data = result.data
        // console.log(data)
        setIsTableRefresh(true)
      }
    }).catch(err => {
      setStateDelete(axiosState.error(axiosCatch.isTimeout(err), stateDelete.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
    })
  }
  
  //------------------------------------------------------------------------------
  const [stateAsk, setStateAsk] = useState(axiosState.init())
  const apiAsk = () => {
    const config = apiConfig.tool.ppsp_ppd_ask() //112
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateAsk(axiosState.error(false, stateAsk.numResultError + 1))
        setInfo(snackInfo.openError(axiosCatch.getMsg(result.data)))
      } else {
        setStateAsk(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        let code = data.result_code
        console.log('ask result_code', code);
      }
    }).catch(err => {
      // console.log('err', err);
      setStateAsk(axiosState.error(axiosCatch.isTimeout(err), stateAsk.numResultError + 1))
      console.log('ask err.message', err.message);
      console.log('ask err.code', err.code);
      if( !(err.message === "Network Error" && err.code === "ERR_NETWORK") ) {
        setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      }
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //------------------------------------------------------------------------------
  useEffect(() => {
    apiTableList()
    var idInterval = window.setInterval(() => apiTableList(), 5*60*1000); //5min
    return () => clearInterval(idInterval)
  }, [input, pageNo.now])
  
  useEffect(() => {
    if (!isTableRefresh) return;
    apiTableList()
    setIsTableRefresh(false)
  }, [isTableRefresh])
  
  const thirdGrid = 5;
  const tableCol = texts.tableHeadCell.length
  const css = {
    // text_menu: "Contents-QuanticoBody16px-Regular-White_50-Btn",
    text_user: "Contents-QuanticoBody16px-Regular-White_50-Btn",
    text_menu: "Contents-QuanticoBody16px-Regular-White_50"
  };
  
  //------------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread} desc={texts.desc}>
      <div className={scss.layout}>
        {openStop ? (
          <DialogStop jobID={jobID} displayName={displayName} />
        ) : null}
        {openRename ? (
          <DialogRename jobID={jobID} displayName={displayName} />
        ) : null}
        {openDelete ? (
          <DialogDelete jobID={jobID} displayName={displayName} />
        ) : null}

        {/* Manual input */}
        <ToolSingleBlock
          isDocking={isDocking}
          stepText={texts.step[0]}
          title={texts.title[0]}
          subtitleBlock={
            <Text className={"Contents-QuanticoBody16px-Regular-White_75"}>
              {texts.manual.step}
            </Text>
          }
          mainBlockContent={
            <Grid container spacing={2}>
              <div className={scss.manual_rows}>
                <div className={scss.example_bar}>
                  {isDocking ? (
                    <DarkPurpleButton onClick={handleClickExample}>
                      {texts.manual.example}
                    </DarkPurpleButton>
                  ) : (
                    <DarkGreenButton onClick={handleClickExample}>
                      {texts.manual.example}
                    </DarkGreenButton>
                  )}
                </div>
                {isDocking ? (
                  <>
                    <StyledTextFieldPurple
                      multiline={false}
                      className={scss.input_job}
                      placeholder={texts.manual.labelJobName}
                      label={texts.manual.labelJobName}
                      required
                      value={jobName}
                      onChange={(event) => {
                        onChangeJobName(event.target.value);
                      }}
                      error={isJobNameError}
                      helperText={jobNameErrMsg}
                      inputRef={inputRefDocking}
                    />
                    <StyledTextFieldPurple
                      fullWidth
                      multiline={true}
                      rows={4}
                      placeholder={texts.manual.hintInput}
                      label={texts.manual.label}
                      required
                      value={manualInput}
                      onChange={(event) => {
                        onChangeManual(event.target.value);
                      }}
                      error={isInputError}
                      helperText={manualInputErrMsg}
                    />
                    <StyledTextFieldPurple
                      fullWidth
                      multiline={false}
                      rows={1}
                      className={scss.input_box}
                      placeholder={texts.manual.hintInput2}
                      label={texts.manual.label2}
                      required
                      value={manualInput2}
                      onChange={(event) => {
                        onChangeManual2(event.target.value);
                      }}
                      error={isInputError2}
                      helperText={manualInputErrMsg2}
                    />
                  </>
                ) : (
                  <>
                    <StyledTextField
                      multiline={false}
                      className={scss.input_job}
                      placeholder={texts.manual.labelJobName}
                      label={texts.manual.labelJobName}
                      required
                      value={jobName}
                      onChange={(event) => {
                        onChangeJobName(event.target.value);
                      }}
                      error={isJobNameError}
                      helperText={jobNameErrMsg}
                      inputRef={inputRef}
                    />
                    <StyledTextField
                      fullWidth
                      multiline={true}
                      rows={4}
                      placeholder={texts.manual.hintInput}
                      label={texts.manual.label}
                      required
                      value={manualInput}
                      onChange={(event) => {
                        onChangeManual(event.target.value);
                      }}
                      error={isInputError}
                      helperText={manualInputErrMsg}
                    />
                  </>
                )}
                <div className={scss.button_bar}>
                  <GreenButton
                    onClick={handleClickManualSubmit}
                    disabled={submitDisabled}
                  >
                    {submitLoading ? <LoadingAnime /> : "Submit"}
                  </GreenButton>
                  <GreenButton
                    onClick={handleClickManualReset}
                    disabled={resetDisabled}
                  >
                    Reset
                  </GreenButton>
                </div>
              </div>
            </Grid>
          }
        ></ToolSingleBlock>

        <div className={scssPage.fixed_table}>
          <div className={scss.middle}>
            {isDocking ? (
              <PurpleFilterButton
                selectedFilter={filters}
                setSelectedFilter={setFilters}
                onClick={onClickFilter}
              />
            ) : (
              <FilterButton
                selectedFilter={filters}
                setSelectedFilter={setFilters}
                onClick={onClickFilter}
              />
            )}

            {/* <div className={scss.search_text}> */}
            <div
              className={scss.search_text}
              style={{
                "--search-border-color": isDocking
                  ? "rgba(121, 107, 175, 0.24)"
                  : "rgba(100, 255, 218, 0.16)",
                "--search-background-color": isDocking
                  ? "linear-gradient(180deg, rgba(121, 107, 175, 0.12) 0%, rgba(121, 107, 175, 0.04) 50%, rgba(121, 107, 175, 0.07) 100%)"
                  : "transparent",
                "--search-hover-border-color": isDocking
                  ? "rgba(121, 107, 175, 0.5)"
                  : "rgba(100, 255, 218, 0.5)",
                "--search-hover-background-color": isDocking
                  ? "linear-gradient(180deg, rgba(121, 107, 175, 0.3) 0%, rgba(121, 107, 175, 0.15) 50%, rgba(121, 107, 175, 0.2) 100%)"
                  : "linear-gradient(180deg, rgba(43, 255, 255, 0.12) 0%, rgba(43, 255, 255, 0.04) 50%, rgba(43, 255, 255, 0.07) 100%)",
              }}
            >
              <InputBase
                sx={{
                  m: 0,
                  flex: 1,
                  width: "732px",
                  height: "29px",
                  fontFamily: "Quantico",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "28.8px",
                  textAlign: "left",
                  textUnderlinePosition: "from-font",
                  textDecorationSkipInk: "none",
                  color: "rgba(255, 255, 255, 1)",
                  "&:hover": {
                    color: "rgba(255, 255, 255, 1)",
                  },
                }}
                placeholder={texts.searchHint}
                value={keyword}
                onChange={(e) => onChangeSearch(e.target.value)}
                onKeyDown={onKeyDownSearch}
              />
              {keyword && <IconButton
                type="button"
                aria-label="clear search"
                className={scss.search_bar_btn}
                onClick={onClickClearSearch}
              >
                <ClearIcon />
              </IconButton>}
              
              <IconButton
                type="button"
                aria-label="search"
                className={scss.search_bar_btn}
                onClick={onClickSearch}
              >
                <SearchIcon />
              </IconButton>
            </div>


            {isDocking ? (
              <PurpleSmallButton onClick={() => onClickRefresh()}>
                <CachedOutlinedIcon />
              </PurpleSmallButton>
            ) : (
              <GreenSmallButton onClick={() => onClickRefresh()}>
                <CachedOutlinedIcon />
              </GreenSmallButton>
            )}
          </div>

          <MuiTableContainer isPurple={isDocking}>
            <TableHeadRow isPurple={isDocking}>
              {" "}
              {/* title */}
              {texts.tableHeadCell.map((title, index) => (
                <MuiTableCell4Head
                  isDocking={isDocking}
                  key={index}
                  index={index}
                  align={getListCellAlign(index, title)}
                  style={getListCellStyle(
                    index,
                    title,
                    texts.tableBodyCell[index]
                  )}
                >
                  {title}
                </MuiTableCell4Head>
              ))}
            </TableHeadRow>
            <TableBody>
              {/* value */}
              {!stateTable.isLoading && (
                <>
                  {tableItems.length === 0 ? (
                    <MuiTableCellPurple
                      isGreen={!isDocking}
                      colSpan={tableCol}
                      align={"center"}
                    >
                      <Text className={css.text_menu}>
                        No matching data found
                      </Text>
                    </MuiTableCellPurple>
                  ) : (
                    tableItems.map((item, index) => {
                      return rowChildren(item, index);
                    })
                  )}
                </>
              )}
            </TableBody>
          </MuiTableContainer>
        </div>

        <div className={scssPage.frame_bottom}>
          <div className={scssPage.bottom_bar}>
            {pages.map((item, index) => newPageButton(item, index))}
          </div>

          <div className={scssPage.divider} />

          <div className={scssPage.go_to_page_block}>
            <input
              className={scssPage.go_to_page_input}
              id="go-to-page-input"
              type="number"
              placeholder="#"
              onChange={onInputChange}
              onKeyDown={handleKeyPress}
              value={goToValue}
            />
          </div>

          <MenuButton onClick={() => handleClickPage(goToValue)}>
            <Text className={css.text_menu}>{words.goto}</Text>
          </MenuButton>
        </div>
      </div>
    </LayoutPageV2>
  );
}
