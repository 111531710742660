import React from "react";
import { Button } from "@mui/material";
import scss from "./Buttons.module.scss";

//---------------------------------------------------------------------------

//GreenSmallButton
export const GreenSmallButton = (props) => {
  const { text, handleClick, children, className, ...rest } = props;

  const adjustedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type.muiName === "SvgIcon") {
      return React.cloneElement(child, { fontSize: "1.2rem" });
    }
    return child;
  });

  return (
    <Button
      className={`${scss.green_small_button} ${className || ""}`}
      onClick={handleClick}
      {...rest}
    >
      {text || adjustedChildren}
    </Button>
  );
};

//PurpleSmallButton
export const PurpleSmallButton = (props) => {
  const { text, handleClick, children, className, ...rest } = props;

  const adjustedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type.muiName === "SvgIcon") {
      return React.cloneElement(child, { fontSize: "1.2rem" });
    }
    return child;
  });

  return (
    <Button
      className={`${scss.purple_small_button} ${className || ""}`}
      onClick={handleClick}
      {...rest}
    >
      {text || adjustedChildren}
    </Button>
  );
};