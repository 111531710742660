import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CategoryScale } from 'chart.js';
import Chart from "chart.js/auto";

import 'assets/scss/common.scss';
import 'assets/scss/index.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
import SnackBar, { emptyResult, snackInfo, snackType } from 'components/SnackBar';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import { breadData } from 'components/bread/BreadBarV2';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import cookie from 'utils/cookie';
import { determineHasEmptyListInTwoLevelObj } from 'utils/general';
import paths from 'utils/network/apiPath';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_mca_ps_ncc?aa=Q
// Go in: 'Tools' page -> 'MultiPepNetwork' button -> 'Total Peptide Sequences 4000' button
// -> 'N-'/'C-terminal' table -> ant 'Peptide' -> this page
// path: paths.spp.tool.peptide_statistical_nc_chart(),
const jsons = spp.tool.statistical_nc_chart

Chart.register(CategoryScale);
Chart.defaults.font.size = 16;
//---------------------------------------------------------------------------
const PeptideStatisticalAnalysisNC = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const nameAminoAcid = searchParams.get(paths.params.amino_acid)
  const input = jsons.input(nameAminoAcid)
  // console.log(input);
  
  const texts = {
    bread: breadData.create('Tools', `Statistical analysis of terminal composition encoding different bioactive peptides`, '', '', ''),
  }
  
  const [threeLetter, setThreeLetter] = useState('')
  const [numKinds, setNumKinds] = useState(0)
  const words = {
    ncTerminalCoding: {
      title: `The percentage of terminal composition of ${threeLetter} encoding ${numKinds} kinds of bioactive peptides`,
      text: '',
      x: '',
      y: 'percentage(%)',
    },
  }
  
  //---------------------------------------------------------------------------
  // const [output, setOutput] = useState({}) //all of output
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const config = apiConfig.tool.statistical_nc_chart(input) //75
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let output = result.data
        // setOutput(output)
        // console.log('output', output);
        
        let threeLetter = output[jsons.output[1]]
        setThreeLetter(threeLetter)
        
        let features = output[jsons.output[2]] //"features"
        // console.log('features', features);
        let featuresAry = getArray(features)
        setNcTerminalCodingLabels(featuresAry.display_name)
        setNcTerminalCoding_N_Value(featuresAry.n_terminal)
        setNcTerminalCoding_C_Value(featuresAry.c_terminal)
        
        setNumKinds(featuresAry.display_name.length)

        // Check result is empty
        if (determineHasEmptyListInTwoLevelObj(output)) {
          setInfo(snackInfo.openInfo(emptyResult))
        }
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  function getArray(features) {
    if(Object.isObject(features)) {
      // console.log('features', features);
      let display_name = []
      let n_terminal = []
      let c_terminal = []
      for (let [key, value] of Object.entries(features)) {
        // console.log(key, value);
        let name = value[jsons.peptides[0]] //"display_name"
        display_name.push(name)
        
        let n = value[jsons.peptides[1]]
        n_terminal.push(n)
        
        let c = value[jsons.peptides[2]]
        c_terminal.push(c)
      }
      let obj = {}
      obj.display_name = display_name
      obj.n_terminal = n_terminal
      obj.c_terminal = c_terminal
      return (obj)
    }
    return {}
  }

  useEffect(() => {
    if (axiosState.keepRest(state))
      apiResult()
  }, [])

  // const [selectedClsName, setSelectedClsName] = useState("")
  // useEffect(() => {
  //   setSelectedClsName(cookie.getCookie(cookie.keys.peptide.selectedPeptideClsName))
  // }, [])
  
  //---------------------------------------------------------------------------
  // BarChart
  const [ncTerminalCodingLabels, setNcTerminalCodingLabels] = useState([]);
  const [ncTerminalCoding_N_Value, setNcTerminalCoding_N_Value] = useState([]);
  const [ncTerminalCoding_C_Value, setNcTerminalCoding_C_Value] = useState([]);

  const ncTerminalCodingOptions = chartProp.options.axisTitleXY(words.ncTerminalCoding.x, words.ncTerminalCoding.y)
  const ncTerminalCodingData = chartProp.data.carateMulti(
    "",
    "",
    ncTerminalCodingLabels,
    [chartProp.data.singleDataset(
      "N-Terminal", ncTerminalCoding_N_Value, v.green, v.chartHoverGreen
    ), chartProp.data.singleDataset(
      "C-Terminal", ncTerminalCoding_C_Value, v.purple, v.hoverPurple
    )])
  
  //---------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread}>

        <ChartSection
          chartBlockTitle={words.ncTerminalCoding.title}
          chartBlockSubTitle={words.ncTerminalCoding.text}
          mainChart={
            <BarChart
              data={ncTerminalCodingData}
              options={ncTerminalCodingOptions}
              showTableLabel={true}
              yAxisLabel={words.ncTerminalCoding.y}
            />
          } />
      
    </LayoutPageV2>
  )
}
export default PeptideStatisticalAnalysisNC