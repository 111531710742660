import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TableCell, TableHead, TableRow } from '@mui/material';

// import 'assets/scss/indexV2.scss';
import scss from './SearchFullResultTable.module.scss'
import scssTable from 'components/table/TableV2.module.scss'

import LoadingAnime from 'components/LoadingAnime';
import { MuiTableBody, MuiTableCell, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTableV2';
import scssSelect from 'views/protein/ProteinSelection.module.scss';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import { spp } from 'utils/network/jsons';
import { RouterLink } from 'components/router/RouterLink';
import { getKeyByValue } from 'utils/object';
import { cellMutation, cellMutationText, cellPeptide } from 'views/tool/ToolACEiPPAnOxPPManualResult';
import { useEffect } from 'react';
import { tableSource } from './tableData';
import { BorderRight } from '@mui/icons-material';

//---------------------------------------------------------------------------
let texts = {
  menus: [
    'Show up to',
  ],
  aceipp_anoxpp_mm: {
    title: 'Peptide Mutation',
    head_key: 'head',
    head: [
      'Amino acids',
      'Site',
    ],
  },
}

const XYTableCell = (props) => {
  const {children, className, ...other} = props
  let cls = scss.table_body_cell
  if( className !== undefined )
    cls += ` ${className}`
  return (
    <TableCell align="center" size="small" className={cls} {...other} style={{border:'none'}}>
      {children}
    </TableCell>)
}

const XYTableCell1st = (props) => {
  const {children, ...other} = props
  return (
    <XYTableCell align="right" component="th" scope="row" className={scss.table_body_cell_1st} {...other}>
      {children}
    </XYTableCell>
    )
}

//---------------------------------------------------------------------------
const SearchFullResultTable = (props) => {
  const { tableBodyCell, inputProp } = props
  const source = new tableSource(tableBodyCell)
  // console.log('source', source);
  
  // console.log('inputProp', inputProp);
  let dataset, datasetName = '', datasetNameNon = ''
  let keyword = '', arrKeyword, site
  if(source.isACEiPPAnOxPPMutation) {
    dataset = inputProp[0]
    datasetName = getKeyByValue(spp.tool.aceipp_anoxpp_manual_mutation.dataset, dataset)
    datasetNameNon = 'non-' + datasetName
    
    keyword = inputProp[1]
    let arr = keyword.split('')
    arrKeyword = [texts.aceipp_anoxpp_mm.head_key].concat(arr)
    if(!arrKeyword)
      arrKeyword = []
    
    site = inputProp[2] ? inputProp[2] : 1
    // console.log('dataset', dataset);
    // console.log('keyword=', keyword, 'arrKeyword=', arrKeyword);
    // console.log('site=', site);
  }
  
  const itemsPerPageList = [10, 50, 100,]
  itemsPerPageList.forEach((number) => (texts.menus.push(`${number} items`)))
  // console.log(texts.menus);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      props.handleClickSearch();
    }
  }
  
  //------------------------------------------------------------------------------
  const navigate = useNavigate()
  const TableBodyCell = (props) => {
    // console.log('props', props);
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    const { tableBodyCell, children, inputProp, index } = props

    const [searchParams] = useSearchParams()
    let peptideClass = searchParams.get(paths.params.class)

    let link = '', key = '', array = []
    const generateSppIdList = (index, peptideClass) => {
      // console.log('tableBodyCell[index]', tableBodyCell[index]);
      // console.log(children[tableBodyCell[index]]);
      key = tableBodyCell[index]
      if( key !== '' )
        array = children[key]
      if(array) { //NOT undefined, null
        if(peptideClass !== 'multifunctionalpeptides'){
          return (<>
            {array.map((element, index) => <div key={index}>
              <RouterLink to={paths.spp.peptide.id_result(element, peptideClass)}>
                {element}
              </RouterLink></div>)}
          </>)
        }else{
          return (<>
            {array.map((element, index) => <div key={index}>
              <RouterLink to={paths.spp.peptide.id_multi_result(element, peptideClass)}>
                {element}
              </RouterLink></div>)}
          </>)
        }
      }
    }
    
    if(source.isPeptideList) {
      // link = paths.spp.peptide.hotspot_result()
      // props.inputProp[1]
      const key0 = tableBodyCell[0]
      const key3 = tableBodyCell[3] //Report entries (SPP ID)
      const val3 = children[key3] || []
      const isNullValue3 = (val3 === null)
      const key4 = tableBodyCell[4] //Other entries (SPP ID)
      const val4 = children[key4] || []
      const allPeptideId = [...val3,...val4]
      // console.log('key0=', key0, 'key3=', key3, 'val3=', val3, 'key4=', key4, 'val4=', val4);
      
      let peptideID = val3[0]
      if( peptideID === undefined )
        peptideID = val4[0]

      return (
        tableBodyCell.map((cell, index) => {
          // const peptideClass = cookie.getCookie(cookie.keys.peptide.selectedPeptideClsTableName)
          // return <></>
          return <MuiTableCell key={index}>
            {(index === 5) && !isNullValue3 //PepHotMap
              ? <RouterLink to={paths.spp.peptide.hotspot_result(props.inputProp[0],
                  peptideID, 
                  children[key0], 
                  allPeptideId
                  )}>{`GO >>`}</RouterLink>
              : (index === 4) ?
                generateSppIdList(4, peptideClass)
                : (index === 3)
                  ? generateSppIdList(3, peptideClass)
                  : <div dangerouslySetInnerHTML={{ __html: children[cell] }} />}
          </MuiTableCell>
        }))
    }
    if(source.isACEiPPAnOxPPMutation) {
      return (
        tableBodyCell.map((cell, indexX) => {
          let value = children[cell]
          let text = value
          if(cell === "pre_activity") {
            text = datasetNameNon
            if( value )
              text = datasetName
            return (
              <MuiTableCell key={indexX} className={getCellClassName(index)} align={getCellAlign(cell, indexX)}>
                {text}
              </MuiTableCell>)
          } else if(cell === "mutation" || cell === "peptide") {
            let value = children["sequence"]
            if(index === 0)
              return <MuiTableCell key={indexX} />
            return (
              <MuiTableCell key={indexX} align={getCellAlign(cell, indexX)}>
                {cell === "mutation"
                ? cellMutation(navigate, value, dataset)
                : cellPeptide(navigate, value, dataset)}
              </MuiTableCell>)
          }
          return (
            <MuiTableCell key={indexX} className={getCellClassName(index)} align={getCellAlign(cell, indexX)}>
              {text}
            </MuiTableCell>)
      }))
    }
  }
  
  function getCellClassName(index) {
    if(source.isACEiPPAnOxPPMutation && index === 0)
      return 'color-red'
    return null
  }
  function getCellAlign(cell, index) {
    if(source.isACEiPPAnOxPPMutation) {
      if( cell==='Pre-Activity' || cell==="pre_activity"
        || cell==="pi" || cell==='pI')
        return 'right'
      if(cell==='Mutation' || cell==="mutation")
        return 'center'
      if(cell==='Peptide' || cell==="peptide")
        return 'center'
    }
    return 'left'
  }

  let tableHeadCell = props.tableHeadCell
  // console.log('tableHeadCell', tableHeadCell)

  let items = props.items
  // console.log('items', items)
  
  //------------------------------------------------------------------------------
  const xyTableRowCell4Body = (arr) => {
    let text, value = keyword
    return (
      <> 
        <TableRow>
          {arr.map((item, index) => { //'Amino acids' row
            if( item === texts.aceipp_anoxpp_mm.head_key ) {
            text = texts.aceipp_anoxpp_mm.head[0]
            return (
              <XYTableCell1st key={index}>{text}</XYTableCell1st>) //'Amino acids'
          } else {
            text = item
            return (
              <XYTableCell key={index}>{text}</XYTableCell>)
          }})}
        </TableRow>
        <TableRow>
          {arr.map((item, index) => { //'Site' row
            if (item === texts.aceipp_anoxpp_mm.head_key) {
              text = texts.aceipp_anoxpp_mm.head[1]
              return (
                <XYTableCell1st key={index} sx={{ border: 0, }}>{text}</XYTableCell1st>) //'Site'
            } else {
              text = index
              return (
                <XYTableCell key={index} sx={{ border: 0, }}>
                  {Number(text) !== Number(site)
                    ? cellMutationText(navigate, value, dataset, text)
                    : text}
                </XYTableCell>)
            }
          })}
        </TableRow>
    </>)
  }
  
  //------------------------------------------------------------------------------
  return (
    <>
        { source.isACEiPPAnOxPPMutation
        ? <div className={scss.frame_muation}>
            <h3 className='Title-Audiowide20px-Regular-White_75'>{texts.aceipp_anoxpp_mm.title}</h3>
            <div className={scss.mutation_table}>
              <MuiTableContainer>
                <Table aria-label="table">
                  <MuiTableBody>
                    {xyTableRowCell4Body(arrKeyword)}
                  </MuiTableBody>
                </Table>
              </MuiTableContainer>
            </div>
          </div>
        : <div className={scssSelect.frame_search}>
            <h4 className={scssSelect["color-white"]}>{props.texts.searchHint}</h4>
          </div>
        }

      <div className={scssSelect['frame_table']}>
        <TableContainer variant="outlined" className={scssTable.table_container} >
          {props.isLoading ? <LoadingAnime />
          : items.length === 0
            ? <Table sx={{ display: "flex", justifyContent: "center", pt: 2, pb: 2, color:'var(--white-white-75)'}}>No matching data found</Table>
            : <Table aria-label="a dense table" >
                <TableHeadRow >
                  {tableHeadCell.map((cell, index) => (
                    <MuiTableCell key={index} align={getCellAlign(cell, index)}>{cell}</MuiTableCell>
                  ))}
                </TableHeadRow>
                <MuiTableBody>
                  {items.map((item, index) => (
                    <TableRow2Body key={index}>
                      <MuiTableCell component="th" scope="row">{index + props.itemNo.start}</MuiTableCell>
                      <TableBodyCell tableBodyCell={props.tableBodyCell} inputProp={props.inputProp} index={index}>
                        {item}</TableBodyCell>
                    </TableRow2Body>
                  ))}
                </MuiTableBody>
              </Table>}
        </TableContainer>
      </div>
    </>
  )
}
export default SearchFullResultTable