import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid, Input, MenuItem, Select, TableCell, TableRow } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

// -----custom tools-------------------------------------------------------
import 'assets/scss/common.scss';
import 'assets/scss/temp.scss';
import scss from './ToolACEiPP.module.scss';
import scssPre from './ToolACEiPPPreLibraries.module.scss';
import scssTools from 'views/tool/ToolsV2.module.scss';
import { breadData } from 'components/bread/BreadBarV2';
import Text, { TagText } from 'components/tag/Text';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { RouterLink } from 'components/router/RouterLink';
import { MuiTableCell, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTableV2';
import { MuiTableCell4Head } from './ToolACEiPPSeqFeatures';
import fs, { fileNames, files } from 'utils/files';
import { spp } from 'utils/network/jsons';
import chartProp from 'components/chart/chartProp';
import LoadingAnime from 'components/LoadingAnime';
import ChartSection from 'components/chart/ChartSection';
import BarChart from 'components/chart/BarChart';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import GreenButton from 'components/button/GreenButton';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_aceipp_pl
// path: paths.spp.tool.aceipp_pl,
const texts = {
  bread: breadData.create('Tools', 'ACEiP-Predictor Pre-Libraries', 'ACEiP-Predictor Pre-Libraries'),

  intro: "Here, we constructed two kinds of theoretical peptide libraries:"+
"<br>(i) <span style='color:#1A8074'>ACE-inhibitory peptide (ACEiP) theoretical libraries</span>: Six typical protein hydrolases, including Chymotrypsin (EC 3.4.21.1), Papain (EC 3.4.22.2), Proteinase K (EC 3.4.21.64), Thermolysin (EC 3.4.24.27), Pepsin (EC 3.4.23.1), and trypsin (EC 3.4.21.4) were used to generate a large number of peptides. "+
"First, we used these different enzymes (or combination enzymes) to simulate the hydrolysis of 21,249 proteins from SmartPP and removed illegal sequences. These peptides were then searched in SmartPP to screen for sequences that did not report ACE-inhibitory activity. Finally, ACEiP-Predictor was used to predict whether these unreported peptides have ACE inhibitory activity. As shown in the table below, ten ACEiP theoretical libraries were constructed."+
"<br>(ii) <span style='color:#1A8074'>Multifunctional peptide (MBP) theoretical libraries</span>: Total 30 types of food-derived BPs (2995 unique sequences) other than ACEiPs from SmartPP were collected. Excluding 649 peptides with ACE-inhibitory activity reported in literature, 2346 peptides were obtained. We then used ACEiP-Predictor to predict the 2346 peptides to determine whether they were ACEiPs. As a result, a total of 1358 peptides exhibited potential ACE-inhibitory activity."
  ,
}

const jsonsTable = spp.tool.aceipp_anoxpp_pre_pool_table
export const tables = {
  tableHeadCell: jsonsTable.items_all_txt,
  tableBodyCell: jsonsTable.items_all,
  enzymeValueHide: [
    'Combine and filter the fragments from nine hydrolysis methods',
    'none',
  ],
  enzymeNo: [
    'Combined and filtered the fragments obtained from the following nine hydrolysis methods',
    'Hydrolysis 1:',
    'Hydrolysis 2:',
    'Hydrolysis 3:',
    'Hydrolysis 4:',
    'Hydrolysis 5:',
    'Hydrolysis 6:',
    'Hydrolysis 7:',
    'Hydrolysis 8:',
    'Hydrolysis 9:',
    'Predicted multifunctional peptides',
  ],
}

export const charts = {
  chartTitle: 'Fragment statistics obtained by different hydrolysis methods',
  chartLabelX: 'Enzyme',
  chartLabelY: 'Count',
  
  /* method: {
    Reported: 1,
    NoReported: 2,
    ProLss050: 3, //Less
    ProGeq050: 4, //Greater or equal
    ProGeq090: 5,
    ProGeq095: 6,
  }, */
  methodJsonValue: [ //from api "probability" json
    "Reported by SmartPP",
    "No prediction",
    "< 0.50",
    "≥ 0.50",
    "≥ 0.90",
    "≥ 0.95",
  ],
  methodBtnList: [
    'Reported',
    'No reported',
    'Pro < 0.50',
    'Pro ≥ 0.50',
    'Pro ≥ 0.90',
    'Pro ≥ 0.95',
  ],
  title: [
    'ACEiPs reported by SmartPP database',
    'Not reported fragments searched by SmartPP database',
    'Number of pre-non-ACEiPs with predicted probability < 0.50',
    'Number of pre-ACEiPs with predicted probability ≥ 0.50',
    'Number of pre-ACEiPs with predicted probability ≥ 0.90',
    'Number of pre-ACEiPs with predicted probability ≥ 0.95',
  ],
  
  getArrDatasets: function(arrayH) {
    let arrDatasets = []
    this.methodJsonValue.forEach((value) => {
      if(Array.isArray(arrayH)) {
        let arrP = arrayH.filter((item) => item["probability"] === value)
        let arrCount = arrP.map((item) => item["count"])
        // console.log(value, 'arrP', arrP);
        // console.log(value, 'arrCount', arrCount);
        arrDatasets.push(arrCount)
      } else {
        arrDatasets.push([])
      }
    })
    return arrDatasets
  },
}

export const tableHead = (title, index) => {
  // console.log(title);
  if( title !== '' )
    return (
      <MuiTableCell4Head key={index} index={index} colSpan={index===0 ? 2 : 1}>
        {title}</MuiTableCell4Head>)
}

//------------------------------------------------------------------------------
export const TableBodyCellPreLib = (props) => {
  const { tableBodyCell, children, index, filesList, filePath, dataset } = props
  // console.log('tableBodyCell', props.tableBodyCell);
  // console.log('children', props.children);
  const rowSpanFull = charts.methodJsonValue.length
  let isDownload = false
  let isPools = false
  let isAliceLeft = false
  let enzyme = ''
  let value, text, align, rowSpan, colSpan, file
  return (
    tableBodyCell.map((cell, indexX) => {
      value = children[cell]
      text = value
      
      align = "left"
      if(cell === 'count')
        align = 'right'
      if(cell === 'enzyme_no') {
        enzyme = children["enzyme"]
      }
      if(cell === 'probability' && value === 'No prediction') {
        text = 'No reported'
      }
      if(cell === 'pool_name') {
        // text = fileNames.ToolACEiPP.PreLibraries[index]
        isPools = true
      }
      if(cell === 'download') { //Download
        align = 'center'
        isDownload = true
        value = children["pool_name"]
        // file = filesList[value]
        file = `${process.env.PUBLIC_URL}${filePath}${value}`
      }
        
      rowSpan = 1
      colSpan = 1
      if(indexX === 0) { //Enzyme(s)
        // console.log('enzyme', enzyme);
        let no = Math.floor(index / rowSpanFull)
        text = tables.enzymeNo[no]
        // console.log('no', no, text);
        
        rowSpan = rowSpanFull
        let mod = (index % rowSpanFull)
        if(mod !== 0)
          return null
      }
      if(indexX === 1) { //uncomment when correct "enzyme" value 
        let no = Math.floor(index / rowSpanFull)
        rowSpan = rowSpanFull
        let mod = (index % rowSpanFull)
        if(mod !== 0)
          return null
      }
      if(tables.enzymeValueHide.includes(enzyme)) {            
        if(indexX === 0)
          colSpan = 2 //enzyme_no+enzyme width
        if(indexX === 1)
          return null
      }
            
      return (
        <MuiTableCell key={index+''+indexX+''+cell} align={align}
          rowSpan={rowSpan} colSpan={colSpan}
          style = {{paddingTop: '2px', paddingBottom: '2px', height: '32px'}}>
          {isDownload
          ? cellDownload(value, file)
          : isPools
            ? cellPools(index, text, dataset)
            : text}
        </MuiTableCell>)
    }))
}

const cellDownload = (name, file) => {
  // let file = files.ToolACEiPP.PreLibraries[name]
  // console.log(name);
  // console.log(file);
  if( file !== undefined ) {
    return (
      <a href={file} download={name} >
          <CloudDownloadOutlinedIcon />
      </a>)
  } else
    return null
}
  
const cellPools = (index, text, dataset) => {
  return (
    <RouterLink to={paths.spp.tool.aceipp_anoxpp_pp(dataset, 1+index)} >
      {text}
    </RouterLink>)
}

//---------------------------------------------------------------------------
const ToolACEiPPPreLibraries = ({ setInfo }) => {
  
  //---------------------------------------------------------------------------
  // chart
  const [arrDatasets, setArrDatasets] = useState([])
  const [chartLabels, setLabels] = useState([])
  const [chartDatasets, setDatasets] = useState([])
  
  // chart setting
  const chartOptions = chartProp.options.axisTitleXY(charts.chartLabelX, charts.chartLabelY)
  const chartData = chartProp.data.carate(
    "",
    "",
    [''],
    chartLabels, //"x_axis"
    chartDatasets,
  )
  
  //---------------------------------------------------------------------------
  // const chartMethod = charts.method
  const [selectChartIndex, setSelectChartIndex] = useState()
  // const [selectChartMethod, setSelectChartMethod] = useState()
  const [selectBlockTitle, setSelectBlockTitle] = useState(charts.title[0])
  
  function handleClickMethod(index) {
    // let method = index+1
    setSelectChartIndex(index)
    // setSelectChartMethod(method)
    // console.log('index', index);
    // console.log('method', method);
    
    let datasets = arrDatasets[index] ?? []
    // console.log(arrDatasets, datasets);
    setDatasets(datasets)
    setSelectBlockTitle(charts.title[index] ?? '')
  }
  
  //------------------------------------------------------------------------------
  // table
  const [tableItems, setTableItems] = useState([])
  const [stateTable, setStateTable] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiTableResult = () => {
    const input = jsonsTable.input(jsonsTable.dataset.ACEiPs)
    const config = apiConfig.tool.aceipp_anoxpp_pre_pool_table(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateTable(axiosState.error(false, stateTable.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateTable(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        // console.log(data)
        let items = data[spp.common.output.items]
        setTableItems(items)
        // console.log('items', items);
        
        //---------------------------------------------------------------------------
        // chart of Hydrolysis
        const arrH = items.filter((item) => tables.enzymeValueHide.includes(item["enzyme"]) === false)
        let arrDatasets = charts.getArrDatasets(arrH)
        setArrDatasets(arrDatasets)
        // console.log('arrH', arrH);
        // console.log('arrDatasets', arrDatasets);
        
        let arrPro = arrH.filter((item) => item["probability"] === charts.methodJsonValue[0])
        let labels = arrPro.map(item => String(item["enzyme"]).removeAll('\n'))
        setLabels(labels)
        // console.log(arrRep);
        // console.log('labels', labels);
      }
    }).catch(err => {
      setStateTable(axiosState.error(axiosCatch.isTimeout(err), stateTable.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  useEffect(() => {
    apiTableResult()
  }, [])
  
  useEffect(() => {
    //setup init chart data with arrDatasets[0]
    handleClickMethod(0)
  }, [chartLabels])
  
  //----render-----------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread} desc={texts.intro}>
      <Box className={scssTools.layout}>
        <div className={scss.frame_intro} style={{}}>
          <TagText className={'Contents-QuanticoBody16px-Medium-White_75'} html={texts.intro}></TagText>
        </div>
        
        <div className={scssPre.chart_type_btns}>
          {charts.methodBtnList.map((name, index) => {
            return (
              <GreenButton
                className={selectChartIndex === index ? scssPre.selected_chart_type : ""}
                key={index}
                index={index}
                onClick={() => { handleClickMethod(index) }} >{name}</GreenButton>
            )
            })
          }
        </div>
        
        {stateTable.isLoading ? <LoadingAnime />
        : <ChartSection
            sectionTitle={charts.chartTitle}
            chartBlockTitle={selectBlockTitle}
            mainChart={
              <BarChart
                data={chartData}
                options={chartOptions}
                showTableLabel={false}
                yAxisLabel={charts.chartLabelY}
                showAvgLine={true}
              />
            } />
        }
        
        <MuiTableContainer>
          <TableHeadRow> 
            {tables.tableHeadCell.map((title, index) => (
              tableHead(title, index)
            ))}
          </TableHeadRow>
          <TableBody>
            {tableItems.map((item, index) => (
              <TableRow2Body key={index}>
                <TableBodyCellPreLib key={index} index={index}
                  dataset={jsonsTable.dataset.ACEiPs}
                  // filesList={files.ToolACEiPP.PreLibraries}
                  filePath={fs.filePaths.ToolACEiPP.PreLibraries} 
                  tableBodyCell={tables.tableBodyCell}>
                  {item}
                </TableBodyCellPreLib>
              </TableRow2Body>
            ))}
          </TableBody>
        </MuiTableContainer>
      </Box >
    </LayoutPageV2 >
  )
}
export default ToolACEiPPPreLibraries