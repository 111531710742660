import { Button } from "@mui/material";

import scss from "./Buttons.module.scss";

//---------------------------------------------------------------------------
const DarkGreenButton = ({ children, ...props }) => {
  return (
    <Button
      className={scss.dark_purple_btn}
      {...props}
    >
      {children}
    </Button>
  );
};

export default DarkGreenButton;