import { TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
// import Chart from "chart.js/auto";

import 'assets/scss/commonV2.scss';
import scssPeptide from 'views/peptide/PeptideDFBPIDResult.module.scss';
import scssFull from 'views/peptide/PeptideDFBPIDFull.module.scss';

import { breadData } from 'components/bread/BreadBarV2';
import LoadingAnime from 'components/LoadingAnime';
import { TagText, Code } from 'components/tag/Text';
import { TableRow2Body } from 'components/table/MuiTableV2';
import { A } from 'components/tag/A';
import NoneButton from 'components/button/NoneButton';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTab from 'components/mui/StyledTab';
import StyledTabs from 'components/mui/StyledTabs';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import links from 'utils/links';
import Text from 'components/tag/Text';
import MuiTabPanel, { scrollTab } from 'components/mui/MuiTabPanel';
import { RouterLink } from 'components/router/RouterLink';
import { getClassName, getClassNameTitle, getScssNameCell } from 'components/peptide/sppID';
import { setEHPToolState } from './ToolEHPToolSearch';
import { handleScroll } from 'components/peptide/sppLayout';
import LayoutPage2V2 from 'layouts/LayoutPage2V2';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_hs_rp?peptideID=SPPANPE0002&proteinID=SPPPR1622&fragment=LEYSI
// Go in: 
// path: paths.spp.tool.hotspot_result_precursor(),
const jsons = spp.tool.hotspot_result_precursor //66
const texts = {
  tabs: ['',],
}

const tabs = {
  _00card: {
    head: [],
    json: [],
  },

  _0overview: {
    title: texts.tabs[0],
    head: jsons.output_text,
    json: [
      ...jsons.output.slice(0, 11 + 1),
    ],
    no_activity: 'No activity reported',
    ehp_tool: 'SPP---ProteinPeptize',
  },
}

const MuiTableCell = (props) => (
  <StyledTableCell style={{ whiteSpace: 'pre-line' }} {...props}>
    {props.children}
  </StyledTableCell>
)

const MuiTableCell1st = (props) => (
  <MuiTableCell component="th" scope="row"
    {...props}>
    {props.children}
  </MuiTableCell>
)

const MuiTableHeadCell1st = (props) => (
  <StyledTableCell style={{ width: 190 }} align='right'
    component="th" scope="row" className="bgc-grey50" {...props}>
    {props.children}
  </StyledTableCell>
)

const TableBodyCell = ({ navigate, ...props }) => {
  // console.log('props', props);
  // console.log('tableBodyCell', props.tableBodyCell);
  // classification: "ace_inhibitory_peptides", cumulative_fragments: 255, unique_sequences: 134
  // console.log('children', props.children);
  // let link = paths.spp.protein.peptide_list()
  const [searchParams] = useSearchParams()
  const indexOfDFBPID = searchParams.get(paths.params.id)

  return (
    props.tableBodyCell.map((cell, index) => {
      // to={paths.spp.tool.aasdResourse[index]}
      return <MuiTableCell key={index}>
        {index === 0 && props.children["unique_sequences"] !== 0 ? (
          <u onClick={() => {
            cookie.setCookie(cookie.keys.peptide.selectedPeptideClsTableName, props.children["classification"])

            // navigate(`${link}?id=${indexOfDFBPID}&class=${props.children["display_name"]}`)
            navigate(paths.spp.protein.peptide_list(indexOfDFBPID, props.children["display_name"]))
          }}>
            {props.children["display_name"]} peptides
          </u>
        ) : (
          index === 0 ? `${props.children[cell]} peptides` : props.children[cell]
        )
        }
      </MuiTableCell>
    }))
}

const rowChildren3 = (props, className = null) => {
  return (
    <TableRow2Body className={className}>
      {props.map((item, index) => (
        index === 0
          ? <MuiTableCell1st colSpan={2}>{item}</MuiTableCell1st>
          : <MuiTableCell align={'left'}>{item}</MuiTableCell>
      ))}
    </TableRow2Body>
  )
}

const rowChildren = (row, index, type) => {
  return (
    <TableRow key={row.name}>
      <MuiTableHeadCell1st>{row.name}</MuiTableHeadCell1st>

      <MuiTableCell> {type === "uni_port" ?
        links.outside.UniprotKB(row.value)
        : type === "ehp_link"
          ? <RouterLink to={paths.spp.tool.ehp_tool_search()}>
              <TagText html={row.value} /></RouterLink>
          : <TagText html={row.value} />
      }</MuiTableCell>
    </TableRow>
  )
}

//---------------------------------------------------------------------------
const ToolHotSpotResultPrecursor = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const peptideID = searchParams.get(paths.params.peptideID)
  const proteinID = searchParams.get(paths.params.proteinID)
  const fragment = searchParams.get(paths.params.fragment)
  const input = jsons.input(peptideID, proteinID, fragment) //66
  // console.log(input);
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  const words = {
    bread: breadData.create('Peptide', 'PepHotMap result data output', bread3rd, 'Result'),
  }
  
  //---------------------------------------------------------------------------
  const [output, setOutput] = useState({}) //all of output
  const [outputObj, setOutputObj] = useState({}) //object
  const [card, setCard] = useState([])

  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const config = apiConfig.tool.hotspot_highlight(input) //66
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        setOutput(output)
        // console.log('output', output)

        let card = []
        setCard(card)
        // console.log(card);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)

      let obj = {}
      obj.the_reported = {}
      setOutputObj(obj)
    })
  }

  useEffect(() => {
    ref.current = ref.current.slice(0, texts.tabs.length)
    if (axiosState.keepRest(state))
      apiResult()
  }, [])
  
  //---------------------------------------------------------------------------
  // TabPanel cell
  const TabCell = (props) => { //show table key&value
    const { tab, output, outputObj } = props
    let indexRef = 0 //bug D number always being from 1
    return (
      <>
        <Text className={'h3-darkGreen'}>{tab.title}</Text>
        {tab.head.map((item, index) => {
          // console.log(index, item)
          const json = tab.json[index]
          let isHide = false
          let link = ""
          let isFullCell = (json === "Protein sequence")

          return (isHide
            ? null
            : <div key={index} className={scssFull[getScssNameCell(json, tab, index, item)]}>
              {isFullCell
                ? getValue(output, outputObj, tab.json, index, indexRef)
                : <>
                  <div key={`div-${item}`} className={scssFull.cell_label}>
                    <Text key={`text-${item}`} className={getClassNameTitle(item)}>
                      {link !== ""
                        ? <A href={link} className={getClassNameTitle(item)} >
                          <TagText html={item} />
                        </A>
                        : <TagText html={item} />}
                    </Text>
                  </div>
                  <div key={`div-${index}`} className={scssFull.cell_value}>
                    <Text key={`text-${index}`} className={getClassName(json, output)}>
                      {getValue(output, outputObj, tab.json, index, indexRef)}
                    </Text>
                  </div>
                </>}
            </div>)
        })}
      </>
    )
  }
  
  function getValue(output, outputObj, json, index, indexRef) {
    // console.log("output:", output)
    // console.log('json:', json)
    // console.log('index:', index)
    // console.log('index', index)
    let idx = index
    const name = json[idx] //db name
    // console.log('name', name)
    let valueReturn = name
    if (name in output) {
      const value = output[name] //db value
      // console.log('name=', name, 'value=', value)
      valueReturn = value

      // console.log(name);
      switch (name) { //spp.tool.hot_spot_result.output
        case "Protein sequence":
          return (<Code>{value.toCode()}</Code>)
        case 'Database references':
          return links.outside.UniprotKB(value)
        case 'Peptide_SPP_ID':
          let cls = output["Peptide activity"]
          return (
            <RouterLink to={paths.spp.peptide.id_result(value, cls)}>
              <TagText html={value} />
            </RouterLink>)
        case 'Protein_SPP_ID':
          return (
            <RouterLink to={paths.spp.protein.id_result(value)}>
              <TagText html={value} />
            </RouterLink>)
        default:
          break
      }
    } else {
      // console.log(name);
      switch (name) {
        case "Enzymatic Hydrolysis":
          return (
            <RouterLink to={paths.spp.tool.ehp_tool_search()}
              onClick={() => handleClickEHPtool(output)}>
              <TagText html={tabs._0overview.ehp_tool} />
            </RouterLink>)
        default:
          valueReturn = name
      }
    }
    return (<TagText html={valueReturn} />)
  }
  
  function handleClickEHPtool(output) {
    let f = output["Fragments"]
    setEHPToolState(f)
    // console.log('Fragments', f);
  }
  
  //---------------------------------------------------------------------------
  // TabPanel
  const isShownAllTabs = true //false=multi tabs
  const ref = useRef([])
  const [tabValue, setTabValue] = React.useState(0);
  
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll(ref, setTabValue));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/

  const ContentObjList = [
    <TabCell tab={tabs._0overview} output={output} outputObj={outputObj}></TabCell>,
  ]
  
  return (
    <LayoutPage2V2 bread={words.bread} card={card} output={output}>
      <div className={scssPeptide.layout}>
        {isShownAllTabs && state.isLoading ? <LoadingAnime /> :
          <div className={scssFull.frame_right}>
            {ContentObjList.map((item, index) => (
              <MuiTabPanel key={index} index={index} value={tabValue} tabRef={ref}
                isShownAllTabs={isShownAllTabs}>
                {!isShownAllTabs && state.isLoading ? <LoadingAnime /> : item}
              </MuiTabPanel>
            ))}
          </div>
        }
      </div>
    </LayoutPage2V2>
  )
}

export default ToolHotSpotResultPrecursor;