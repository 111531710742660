// -----official tools & third tools-------------------------------------------------------
import { Button, Paper, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { breadData } from 'components/bread/BreadBarV2';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// -----custom tools-------------------------------------------------------
import v2 from 'assets/scss/_variablesV2.scss';
import scss from './StatisticProtein.module.scss';
import SnackBar, { emptyResult, snackInfo, snackType } from "components/SnackBar";
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { Space, determineHasEmptyListInTwoLevelObj, isEmptyObjOrArray } from 'utils/general';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from "utils/network/jsons";
import LayoutPageV2 from 'layouts/LayoutPageV2';
import LoadingAnime from 'components/LoadingAnime';
import StatCard from 'components/statistic/StatCard';
import { chartsStat, wordsStat } from './StatisticProtein';
import { initSorts } from 'views/protein/ProteinList';

// ---------------------------------------------------------------------------
// http://localhost:3000/stat_protein_lv2?cls=110000

const jsonProtein = spp.statistic.portein
const jsonChart = spp.statistic.portein_chart

export default function StatisticProteinLv2({ setInfo }) {
  const [searchParams] = useSearchParams()
  const queryClass = searchParams.get(paths.params.class)
  
  // console.log(initSorts);
  const source = initSorts.find(obj => obj.id === Number(queryClass))
  const sourceName = source ? source.name : '';
  // -----variables-------------------------------------------------------
  const title = `Sequence coding analysis of ${sourceName} food-derived bioactive peptides according`;
  const texts = {
    bread: breadData.create('Statistics', title, 'Protein '),
  }
  
  const [totalProteins, setTotalProteins] = useState(0)
  const [totalPeptides, setTotalPeptides] = useState(0)
  let words = wordsStat
  words.source.text = `Total proteins: ${totalProteins} results`;
  words.classification.text = `Total peptides: ${totalPeptides} results`;
  
  const sourceClass = Number(queryClass)
  let total = 0
  switch( sourceClass ) {
    case 220000: //Plant
      total = 42
      break
    case 440000: //Marine
      total = 22
      break
    case 110000: //Milk
    case 330000: //Animal
      total = 10
      break
    case 550000: //Microorganism
    default:
      total = 4
  }
  let tableY = Math.floor(total / 5) || 1 //min=x1
  let tableSP = (tableY > 1) ? (tableY - 1) : 0
  let style = {minHeight: `calc(${tableY} * 497px + ${tableSP} * 12px)`} //x1=497, x2=1006, x5=2548
  //------------------------------------------------------------------------------
  const charts = chartsStat
  
  //------------------------------------------------------------------------------
  // const [tableData, setTableData] = useState({})
  const [sourceLabels, setSourceLabels] = useState([]);
  const [sourceValue, setSourceValue] = useState([]);
  const [chartColors, setChartColors] = useState([v2.greenNormal])
  const sourceOptions = chartProp.options.axisTitleXY(words.source.x, words.source.y)
  const sourceData = chartProp.data.carate(
    '',
    words.source.text,
    [''],
    sourceLabels,
    sourceValue,
    chartColors,
  )
  
  const [classificationValue, setClassificationValue] = useState([]);
  const classificationOptions = chartProp.options.axisTitleXY(words.classification.x, words.classification.y)
  const classificationData = chartProp.data.carate(
    '',
    words.classification.text,
    [''],
    sourceLabels,
    classificationValue,
    chartColors,
  )
  
  const [fragmentValue, setFragmentValue] = useState([]);
  const fragmentOptions = chartProp.options.axisTitleXY(words.fragment.x, words.fragment.y)
  const fragmentData = chartProp.data.carate(
    '',
    words.fragment.text,
    [''],
    sourceLabels,
    fragmentValue,
    chartColors,
  )
  
  const [averageValue, setAverageValue] = useState([]);
  const averageOptions = chartProp.options.axisTitleXY(words.average.x, words.average.y)
  const averageData = chartProp.data.carate(
    '',
    words.average.text,
    [''],
    sourceLabels,
    averageValue,
    chartColors,
  )
  
  //------------------------------------------------------------------------------
  const navigate = useNavigate()
  let location = useLocation()
  const [tableItems, setTableItems] = useState([])
  const [stateTable, setStateTable] = useState(axiosState.init())
  const apiTable = () => {
    const input = jsonProtein.input(queryClass)
    const config = apiConfig.statistic.portein(input)
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateTable(axiosState.error(false, stateTable.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateTable(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        let items = data[spp.common.output.items]
        let color = []
        let items_color = items.map((item, index) => {
          let type = item['code'].charAt(1)
          let idx = type > 0 ? type - 1 : 0
          color = charts.colors[idx]
          // console.log(type, idx);
          return {color: color, ...item}})
        setTableItems(items_color)
        // console.log('items', items);
        // console.log('items_color', items_color);
        
        setChartColors(color)
        // console.log('color', color);
        
        let totalProteins = data['total_proteins']
        setTotalProteins(totalProteins)
        let totalPeptides = data['total_peptides']
        setTotalPeptides(totalPeptides)
        
        let arr = items.map(item => String(item["name"]))
        setSourceLabels(arr)
        arr = items.map(item => (item["source_total"]))
        setSourceValue(arr)
        arr = items.map(item => (item["peptide_total"]))
        setClassificationValue(arr)
        arr = items.map(item => (item["frag_total"]))
        setFragmentValue(arr)
        arr = items.map(item => (item["avg_count"]))
        setAverageValue(arr)
      }
    }).catch(err => {
      setStateTable(axiosState.error(axiosCatch.isTimeout(err), stateTable.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  useEffect(() => {
    apiTable()
  }, []);

  // -----render-------------------------------------------------------
  
  return (
    <LayoutPageV2 bread={texts.bread}>
      <div className={scss.layout_card} style={style}>
          {!stateTable.isLoading &&
            tableItems.map((item, index) => (
            <div className={scss.card}>
              <StatCard item={item}></StatCard>
            </div>
            ))}
      </div>
          
        <Space />
        
        <div className={scss.layout_seq}>
          <div className={scss.seq_chart}>
            <ChartSection
              chartBlockTitle={words.source.title}
              // chartBlockSubTitle={words.source.text}
              mainChart={
                <BarChart
                  data={sourceData}
                  options={sourceOptions}
                  yAxisLabel={words.source.y} />
              } />
          </div>
          
          <div className={scss.seq_chart}>
            <ChartSection
              chartBlockTitle={words.classification.title}
              // chartBlockSubTitle={words.classification.text}
              mainChart={
                <BarChart
                  data={classificationData}
                  options={classificationOptions}
                  yAxisLabel={words.classification.y} />
              } />
          </div>
            
          <div className={scss.seq_chart}>
            <ChartSection
              chartBlockTitle={words.fragment.title}
              // chartBlockSubTitle={words.fragment.text}
              mainChart={
                <BarChart
                  data={fragmentData}
                  options={fragmentOptions}
                  yAxisLabel={words.fragment.y} />
              } />
          </div>
          
          <div className={scss.seq_chart}>
            <ChartSection
              chartBlockTitle={words.average.title}
              // chartBlockSubTitle={words.average.text}
              mainChart={
                <BarChart
                  data={averageData}
                  options={averageOptions}
                  yAxisLabel={words.average.y} />
              } />
          </div>
        </div>
    </LayoutPageV2>)
};