import { TextField } from '@mui/material';
import styled from 'styled-components';

// import v from "assets/scss/_variables.scss"
import v2 from "assets/scss/_variablesV2.scss"
import 'assets/scss/commonV2.scss'

//---------------------------------------------------------------------------
export const StyledTextField = styled((props) => 
  <TextField
    variant="outlined"
    fullWidth
    multiline maxRows={4}
    
    // InputProps={{
    //   // styleName: Contents: Quantico/Body/16px: Regular;
    //   className: 'Contents-QuanticoBody16px-Regular',
    // }}
  {...props} />)({
  //for variant='standard' StyledTextField in http://localhost:3000/tool_ehp_s
  '& .MuiInput-underline:after': {
    // borderBottomColor: '#f0f !important',
    // Green Alpha/Alpha_16
    borderBottom: '1px solid var(--green-alpha-alpha-16)',
  },
  //for variant='filled'
  "& .MuiFilledInput-underline:after": {
    // borderBottomColor: '#0ff !important',
    // Green Alpha/Alpha_16
    borderBottom: '1px solid var(--green-alpha-alpha-16)',
  },
  //for variant='outlined'(default) StyledTextField in http://localhost:3000/tool_hs_s
  '& label.Mui-focused': {
    // color: '#ff0 !important',
    // color: v.darkGreen,
    // White/White_50
    color: 'var(--white-white-50)',
  },
  '& .MuiInputLabel-root': {
    color: 'var(--white-white-50)'
  },
  
  '& .MuiOutlinedInput-root': {
    // background: '#f0f',
    // White/White_75
    color: 'var(--white-white-75)',
    
    '& fieldset': { //select
      // borderColor: '#f00 !important',
      // Green Alpha/Alpha_16
      border: '1px solid var(--green-alpha-alpha-16)',
    },
    '&:hover fieldset': { //hover
      // borderColor: '#0f0 !important',
      // Green Alpha/Alpha_8
      background: 'var(--green-alpha-alpha-8)',
      border: '1px solid var(--green-alpha-alpha-16)',
    },
    '&.Mui-focused fieldset': { //focused
      // borderColor: '#00f !important',
      // Green Alpha/Alpha_8
      background: 'var(--green-alpha-alpha-8)',
      border: '1px solid var(--green-alpha-alpha-16)',
      color: 'rgba(255, 255, 255, 1)',
    },
    '&.Mui-disabled fieldset': {
      // borderColor: '#0ff !important',
      color: 'rgba(255, 255, 255, 0.5)',
      border: '1px solid var(--green-alpha-alpha-16)',
    },
  },
  
  '& .MuiInput-input': {
    color: 'var(--white-white-75)',
    
    '& fieldset': { //select
      border: '1px solid var(--green-alpha-alpha-16)',
    },
    '&:hover fieldset': { //hover
      background: 'var(--green-alpha-alpha-8)',
      border: '1px solid var(--green-alpha-alpha-16)',
    },
    '&.Mui-focused fieldset': { //focused
      background: 'var(--green-alpha-alpha-8)',
      border: '1px solid var(--green-alpha-alpha-16)',
      color: 'rgba(255, 255, 255, 1)',
    },
    '&.Mui-disabled fieldset': {
      color: 'rgba(255, 255, 255, 0.5)',
      border: '1px solid var(--green-alpha-alpha-16)',
    },
  },
});
export default StyledTextField;
export const StyledTextFieldPurple = styled((props) => 
  <TextField
    variant="outlined"
    fullWidth
    multiline maxRows={4}
  {...props} />)({
  //for variant='standard' StyledTextField in http://localhost:3000/tool_ehp_s
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid var(--logo-colors-logo-purple-16)',
  },
  //for variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottom: '1px solid var(--logo-colors-logo-purple-16)',
  },
  //for variant='outlined'(default) StyledTextField in http://localhost:3000/tool_hs_s
  '& label.Mui-focused': {
    color: 'var(--white-white-50)',
  },
  '& .MuiInputLabel-root': {
    color: 'var(--white-white-50)'
  },
  
  '& .MuiOutlinedInput-root': {
    color: 'var(--white-white-75)',
    
    '& fieldset': { //select
      border: '1px solid var(--logo-colors-logo-purple-16)',
    },
    '&:hover fieldset': { //hover
      background: 'var(--logo-colors-logo-purple-8)',
      border: '1px solid var(--logo-colors-logo-purple-16)',
    },
    '&.Mui-focused fieldset': { //focused
      background: 'var(--logo-colors-logo-purple-8)',
      border: '1px solid var(--logo-colors-logo-purple-16)',
      color: 'rgba(255, 255, 255, 1)',
    },
    '&.Mui-disabled fieldset': {
      color: 'rgba(255, 255, 255, 0.5)',
      border: '1px solid var(--logo-colors-logo-purple-16)',
    },
  },
  
  '& .MuiInput-input': {
    color: 'var(--white-white-75)',
    
    '& fieldset': { //select
      border: '1px solid var(--logo-colors-logo-purple-16)',
    },
    '&:hover fieldset': { //hover
      background: 'var(--logo-colors-logo-purple-8)',
      border: '1px solid var(--logo-colors-logo-purple-16)',
    },
    '&.Mui-focused fieldset': { //focused
      background: 'var(--logo-colors-logo-purple-8)',
      border: '1px solid var(--logo-colors-logo-purple-16)',
      color: 'rgba(255, 255, 255, 1)',
    },
    '&.Mui-disabled fieldset': {
      color: 'rgba(255, 255, 255, 0.5)',
      border: '1px solid var(--logo-colors-logo-purple-16)',
    },
  },
});
export const StyledTextFieldStandard = styled((props) => 
  <TextField
    variant="standard"
    fullWidth
    multiline maxRows={4}
  {...props} />)({
    margin: 0, flex: 1, width: '97%',
  '& .MuiInputBase-input': {
    padding: '0 10px',
    color: 'white',
    fontFamily:'Quantico',
  },
  '& .MuiInput-underline:before': { 
    borderBottom: '1px solid var(--white-white-16)',
    left: 0,
    right: 0
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid var(--green-alpha-alpha-40)',
    left: 0,
    right: 0 
  }
});

export const StyledTextFieldStandardDark = styled((props) => 
  <TextField
    variant="standard"
    fullWidth
    multiline maxRows={1}
  {...props} />)({
    margin: 0, flex: 1, width: '97%',
  '& .MuiInputBase-input': {
    padding: '0 10px',
    color: 'var(--green-darker)',
    fontFamily:'Quantico',
  },
  '& .MuiInput-underline:before': { 
    borderBottom: '1px solid var(--green-darker)',
    left: 0,
    right: 0
  },
  '& .MuiInput-underline:after': {
    borderBottom: '1px solid var(--green-dark-hover)',
    left: 0,
    right: 0 
  }
});

export const StyledTextFieldDisable = styled((props) => 
  <TextField
    variant="standard"
    fullWidth
    multiline maxRows={4}
  {...props} />)({

    '& .MuiInputBase-input.Mui-disabled': {
      color: 'var(--white-white-60)',
      WebkitTextFillColor: 'var(--white-white-60)',
      paddingLeft: '20px',
      fontFamily: 'Quantico'
    },
    '&:hover': {
      '& .MuiInput-root': {
        backgroundColor: 'var(--green-alpha-alpha-8)',  // hover 時的背景色
        transition: 'background-color 0.3s'  // 平滑過渡效果
      }
    }
});
export const StyledTextFieldDisablePurple = styled((props) => 
  <TextField
    variant="standard"
    fullWidth
    multiline maxRows={4}
  {...props} />)({

    '& .MuiInputBase-input.Mui-disabled': {
      color: 'var(--white-white-60)',
      WebkitTextFillColor: 'var(--white-white-60)',
      paddingLeft: '20px',
      fontFamily: 'Quantico'
    },
    '&:hover': {
      '& .MuiInput-root': {
        backgroundColor: 'var(--logo-colors-logo-purple-8)', 
        transition: 'background-color 0.3s', 
        borderRadius: '4px'
      }
    }
});