import { IconButton, Paper, Checkbox, Table, TableBody, TableCell, TableContainer, TableRow, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
// import Chart from "chart.js/auto";
import SearchIcon from '@mui/icons-material/Search';

import 'assets/scss/commonV2.scss';
import './ToolEHPToolResult.scss';
import scss from './ToolEHPToolResult.module.scss';
import scssPeptide from 'views/peptide/PeptideDFBPIDResult.module.scss';
import { breadData } from 'components/bread/BreadBarV2';
import { cardData } from 'components/bread/BreadCard';
import LoadingAnime from 'components/LoadingAnime';
import Text, { TagText, TagTextWithScrollBar, Code } from 'components/tag/Text';
import { A } from 'components/tag/A';
import NoneButton from 'components/button/NoneButton';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import StyledTab from 'components/mui/StyledTab';
import StyledTabs from 'components/mui/StyledTabs';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { Space, isOnlySpacesOrNewlines } from 'utils/general';
import paths from 'utils/network/apiPath';
import { MuiTableContainer,MuiTableCell, TableHeadRow, TableRow2Body } from 'components/table/MuiTableV2';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import MuiTabPanel, { handleChangeTab, scrollTab } from 'components/mui/MuiTabPanel';
import { RouterLink } from 'components/router/RouterLink';
import { getClassName, getClassNameTitle, getScssNameCell } from 'components/peptide/sppID';
import { handleScroll } from 'components/peptide/sppLayout';
import MuiCheckbox from 'components/mui/MuiCheckbox';
import { StyledTextFieldStandard } from 'components/tool/StyledTextField';
import LayoutPage2V2 from 'layouts/LayoutPage2V2';
import { setFocus } from 'layouts/home/SearchV2';
import ResultSidebar from 'components/protein/ResultSidebar';
import GreenButton from 'components/button/GreenButton';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_ehp_r?enzyme=SPPENZY0047,SPPENZY0027,SPPENZY0059&keyword=aa
// Go in: homepage -> 'Tools' tab -> 'ProteinPeptize' button -> select 'Classification', 'Source', 'Protein name' -> click 'Perform' -> this page
// -> select 'Classification', 'Source', 'Protein name' to none -> type text in 'Manual input' -> click 'Perform' -> this page
// path: paths.spp.tool.ehp_tool_result(),
const jsons = spp.tool.ehp_tool_result //64
const jsonsPeptide = spp.tool.ehp_tool_result_peptide
const texts = {
  tabs: [
    'Introduction',
    'Protein / peptide attribute information',
    'The enzyme(s)',
    'Hydrolyzed fragments of all available enzymes',
  ],
  
  buttons: [
    'Check All',
    'Uncheck All',
    'Reverse Check',
    'Refine',
  ],
}

const tabs = {
  _00card: {
    head: [],
    json: [],
  },

  _0results: {
    title: texts.tabs[0],
    head: [
      ...jsons.output_text.slice(0, 2+1),
      jsonsPeptide.output_text[0],
    ],
    json: [
      ...jsons.output.slice(0, 2+1),
      jsonsPeptide.output[0],
    ],
    // result: ' results',
    result: '',
  },
  
  
  _1information: {
    title: texts.tabs[1],
    head: jsons.output_text.slice(4, 7+1),
    json: jsons.output.slice(4, 7+1),
  },
  
  _2enzyme: {
    title: texts.tabs[2],
    head: jsons.output_text.slice(8, 9+1),
    json: jsons.output.slice(8, 9+1),
    title_result: function(number) {
      return (` ${number} result(s)`)},// 3 result(s):
    head_result: function(number) {
      return (`${number} result(s)`)}, //(0 result(s))
    no: 'No available enzyme',
    button: 'Details',
    available: {
      head: jsons.available_text,
      cell: jsons.available,
    },
    unavailable: {
      head: jsons.unavailable_text,
      cell: jsons.unavailable,
    }
  },
  
  _3hydrolyzed: {
    title: texts.tabs[3],
    head: jsons.output_text.slice(10, 12+1),
    json: jsons.output.slice(10, 12+1),
    head_result: function(number) {
      return (`(${number})`)},
    step1: {
      title: 'Step 01: Biofunction filter',
    },
    step2: {
      title: 'Step 02: Length filter',
      text: 'Please enter a valid length range:',
      searchHint: 'Method 1: 2-10 or Method 2: 1,2,3,5,12...',
    },
  },
  
  _4peptide: {
    head: jsonsPeptide.peptide_text.slice(0, 5+1),
    json: jsonsPeptide.peptide.slice(0, 5+1),
    head_result: function(number) {
      return (` ${number} result(s)`)},
    body1st_text: 'No.',
  },
}

function indexData(start, end) {
    return { start, end }
}

class keywordErrorData {
  static init() {
    return {
      isError: false,
      msg: '',
    }
  }
  static error( msg ) {
    return {
      isError: true,
      msg: msg,
    }
  }
}

const MuiButton = (props) => {
  return (
    <NoneButton className={scssPeptide.cell_button} variant="contained" {...props}>
      <Text className='b2strong-darkGreen' style={{textAlign: 'center'}}>{props.children}</Text>
    </NoneButton>
  )
}

const MuiTableHeadCell = (props) => (
  <TableCell style={{border:'none', fontFamily:'Quantico', color:'var(--white-white-50)'}}{...props}>
    {props.children}
  </TableCell>
)

const MuiTableCell1st = (props) => (
  <MuiTableCell component="th" scope="row" {...props}>
    {props.children}
  </MuiTableCell>
)

const MuiTableHeadCell1st = (props) => (
  <StyledTableCell style={{ width: 190 }} align='right'
    component="th" scope="row"  {...props}>
    {props.children}
  </StyledTableCell>
)

//---------------------------------------------------------------------------
const ToolEHPToolResult = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const proteinId = searchParams.get(paths.params.id)
  const key = searchParams.get(paths.params.keyword)
  const enzyme = searchParams.get(paths.params.enzyme)
  const enzymeAry = enzyme.split(',')
  const isKeyword = (key === '' || !key ? false : true)
  let input = {}
  if(isKeyword)
    input = jsons.input_keyword(key, enzymeAry)
  else
    input = jsons.input(proteinId, enzymeAry)
  // console.log(input);
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  const words = {
    bread: breadData.create('Tools', 'ProteinPeptize results', bread3rd, 'Result'),
  }
  
  //---------------------------------------------------------------------------
  const columnSize = 4
  const [peptideFilter, setPeptideFilter] = useState([])
  const [peptideFilterIndex, setPeptideFilterIndex] = useState([])
  const [statePeptideList, setStatePeptideList] = useState(axiosState.init())
  const apiList = () => {
    const config = apiConfig.peptide.list()
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStatePeptideList(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStatePeptideList(axiosState.resultCode200())
        // console.log(result.data);
        
        let items = result.data[spp.common.output.items]
        items.sort(compareProperty)
        let list = items.map((item, index) => {
          return {
            no: index+1,
            id: item["classification_id"], //spp.peptide.list.items
            tableName: item["classification_col"],
            displayName: (item["classification"]).trimClassifyName(),
            isCheck: false,
        }})
        setPeptideFilter(list)
        setPeptideIndex(list.length)
        // console.log('items', items);
        // console.log('list', list);
      }
    }).catch(err => {
      setStatePeptideList(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  function compareProperty(a, b) { //for object
    return a.classification.localeCompare(b.classification)
  }
  function setPeptideIndex(length) {
    if (length > 0) {
      let index = []
      for (const i of Array(Math.ceil(length / columnSize)).keys())
        index.push(indexData(i * columnSize, (i * columnSize) + columnSize - 1))
      setPeptideFilterIndex(index)
      // console.log('index', index);
    }
  }
  
  //---------------------------------------------------------------------------
  const [output, setOutput] = useState({}) //all of output
  const [outputObj, setOutputObj] = useState({}) //object
  const [card, setCard] = useState([])
  const [available, setAvailable] = useState([])
  const [unavailable, setUnavailable] = useState([])

  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const config = apiConfig.tool.ehp_tool_result(input) //64
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        setOutput(output)
        // console.log('output', output)
        
        let array = []
        array = result.data[jsons.output[8]] //"available",
        setAvailable(array)
        array = result.data[jsons.output[9]] //"unavailable",
        setUnavailable(array)

        // let obj = {} //object in output
        // setOutputObj(obj)
        // console.log(obj)

        // let card = []
        // setCard(card)
        // console.log(card);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
      
      // let obj = {}
      // setOutputObj(obj)
    })
  }
  
  //---------------------------------------------------------------------------
  const [outputPeptide, setOutputPeptide] = useState({}) //all of output
  const [peptide, setPeptide] = useState([])
  const [statePeptide, setStatePeptide] = useState(axiosState.init())
  const apiPeptideResult = () => {
    const config = apiConfig.tool.ehp_tool_result_peptide(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStatePeptide(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStatePeptide(axiosState.resultCode200())
        // console.log(result.data);
        
        let output = result.data
        setOutputPeptide(output)
        // console.log(output);
        
        // let count = output[jsonsPeptide.output[0]]
        // console.log(count);
        
        let table = output[jsonsPeptide.output[1]] //"peptide_result",
        if( !Array.isArray(table) )
          table = []
        setPeptide(table)
        // console.log('peptide_result', table);
      }
    }).catch(err => {
      setStatePeptide(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
      
      setPeptide([])
    })
  }
  
  //---------------------------------------------------------------------------
  // TabPanel cell
  const TabCell = (props) => { //show table key&value
    const { tab, output, outputObj } = props
    let indexRef = 0 //bug D number always being from 1
    let title = tab.title
    if( title === texts.tabs[2] )
      title += tabs._2enzyme.title_result(available.length + unavailable.length)
    return (
      <>
        <Text className={'Title-Audiowide28px-Regular-Title'}>{title}</Text>
        {tab.head.map((item, index) => {
          // console.log(index, item)
          const json = tab.json[index]
          let isHide = false
          let isFullCell = (!isKeyword && json === "sequence")
          let isActiveFragment = (json === "active_fragment_count") //68
          let isCellTable = (json === "available") || (json === "unavailable")
          let isFilter = (item === 'Filter')
          return (isHide
            ? null
            : isCellTable
              ? <CellTableFromArray item={item} json={json} index={index} outputObj={outputObj} />
              : isFilter
                ? <CellTableFilter item={item} json={json} index={index} />
                : <div key={index} className={scssPeptide[getScssNameCellEx(json, tab, index)]}>
                  {isFullCell
                    ? getValue(output, outputObj, tab.json, index, indexRef)
                    : <>
                      <div key={`div-${item}`} className={scssPeptide.cell_label}>
                        <Text key={`text-${item}`} className={getClassNameTitle(item)}>
                          {getTitle(item, index)}
                        </Text>
                      </div>
                      <div key={`div-${index}`} className={scssPeptide.cell_value}>
                        <Text key={`text-${index}`} className={getClassName(json, output)}>
                          {isActiveFragment && statePeptide.isLoading
                          ? <LoadingAnime />
                          : getValue(output, outputObj, tab.json, index, indexRef, item)}
                        </Text>
                      </div>
                    </>}
                  </div>
          )
        })}
      </>
    )
  }

  function getScssNameCellEx(json, tab, index) {
    // console.log(json);
    if (!isKeyword && json === "sequence")
      return 'right_cell_code'
    return getScssNameCell(json, tab, index)
  }
  function getTitle(item, index) {
    let valueReturn = item
    /* let link = ''
    if( link !== "" ) {
      return (
        <A href={link} className={getClassNameTitle(item)} >
          <TagText html={item} />
        </A>)
    } else
    {*/
      return (<TagText html={valueReturn} />)
    // }
  }
  function getValue(output, outputObj, json, index, indexRef, item, sequenceSize) {
    // console.log('index', index)
    let idx = index
    const name = json[idx] //db name
    // console.log('name', name)
    let valueReturn = name
    if (name in output) {
      const value = output[name] //db value
      // console.log('name=', name, 'value=', value)
      valueReturn = value
      
      // console.log(name);
      switch (name) { //spp.tool.ehp_tool_result.output
        case "sequence":
          if( !isKeyword ) {
            return (<Code>{value.toCode()}</Code>)
          }
          break
        case "cleavage_map":
          return (<TagTextWithScrollBar html={value} />)
        default:
      }
    } else {
      // console.log(name);
      switch(item) {
        case 'Protein/Peptide length':
          if( !isKeyword ) {
            let item = output["sequence"]
            if(item !== undefined && item !== null )
              valueReturn = item.length
            else
              valueReturn = 0
          } else
            valueReturn = key.length
          break
        case 'Active fragment(s)':
          let json = jsonsPeptide.output[0]
          let value = outputPeptide[json]
          value += tabs._0results.result
          valueReturn = value
          break
        default:
          valueReturn = name
      }
    }
    return (<TagText html={valueReturn} />)
  }
  
  //---------------------------------------------------------------------------
  // TabPanel cell from array
  const CellTableFromArray = (props) => {
    const { item, json, index } = props
    let tableHeadCell, tableBodyCell, value, items
    let isNull = false
    if (json === "available") {
      tableHeadCell = tabs._2enzyme.available.head.slice(0,3+1)
      tableBodyCell = tabs._2enzyme.available.cell
      items = available
      if( available.length === 0 ) {
        isNull = true
        value = tabs._2enzyme.no
      } else
        value = tabs._2enzyme.head_result(available.length)
    } else if (json === "unavailable") {
      tableHeadCell = tabs._2enzyme.unavailable.head.slice(0,2+1)
      tableBodyCell = tabs._2enzyme.unavailable.cell
      items = unavailable
      if( unavailable.length === 0 ) {
        isNull = true
        value = tabs._2enzyme.no
      } else
        value = tabs._2enzyme.head_result(unavailable.length)
    }
    
    return (
      <>
        <div key={`div1-${index}${json}`} className={scssPeptide.right_cell_last}>
          <div key={`div11-${index}${json}`} className={scssPeptide.cell_label}>
            <Text key={`text-${index}`} className={getClassNameTitle(item)}>{item}</Text>
          </div>
          <div key={`div12-${index}${json}`} className={scssPeptide.cell_value}>
            <Text key={`text-${index}`} className={getClassName(key)}>{value}</Text>
          </div>
        </div>
        {isNull
        ? null
        : <div key={`div2-${index}${json}`} className={scssPeptide.right_cell_last}>
            <TableFromArray items={items}
              tableHeadCell={tableHeadCell}
              tableBodyCell={tableBodyCell} />
          </div>}
      </>
    )
  }
  const TableFromArray = (props) => {
    const { tableHeadCell, tableBodyCell, items } = props
    // console.log('items', items);
    return (
      <MuiTableContainer>
        <Table size="small">
          <TableHeadRow>
            {tableHeadCell.map((cell, index) => (
              <MuiTableHeadCell key={index}>{cell}</MuiTableHeadCell>
            ))}
          </TableHeadRow>
          <TableBody>
            {items.map((value, key) => {
              return (
                <TableRow2Body key={key}>
                  <TableBodyCell tableBodyCell={tableBodyCell} no={key}>{value}</TableBodyCell>
                </TableRow2Body>
              )})}
          </TableBody>
        </Table>
      </MuiTableContainer>
    )
  }
  const TableBodyCell = (props) => {
    const {tableBodyCell, children, no} = props
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    const isEnzyme = (tableBodyCell === jsons.available || tableBodyCell === jsons.unavailable)
    let name = ''
    if( isEnzyme )
      name = children[tableBodyCell[0]] //"name",
    return (
      tableBodyCell.map((cell, index) => {
        let value = children[cell]
        let text = value
        let id = ''
        // console.log(index, 'cell=', cell, 'text=', text);
        if( index === 0 ) {
          if(isEnzyme) {
            text = `(${no+1}) ${value}`
            id = children["DFBPID_enzyme"]
          } else
            text = tabs._4peptide.body1st_text + (no+1)
        }
        if( isEnzyme && cell === '' ) {
          id = children["DFBPID_enzyme"]
        }
        
        if( cell !== "DFBPID_enzyme" ) {
          return (
            <MuiTableCell key={index}>
              {isEnzyme && cell === "name"
              ? <RouterLink 
                  to={paths.spp.tool.ehp_tool_enzyme_page(id)}>
                  {text}</RouterLink>
              : isEnzyme && cell === ''
                ? <GreenButton onClick={() => eventClickDetail(id)}>
                  {tabs._2enzyme.button}</GreenButton>
                : <TagText html={text} />
              }
            </MuiTableCell>)
        }
        return null
    }))
  }
  function eventClickDetail(id) {
    if(isKeyword)
      navigate(paths.spp.tool.ehp_tool_result(id, {keyword: key}))
    else
      navigate(paths.spp.tool.ehp_tool_result(id, {id: proteinId}))
    window.location.reload()
  }
  
  //---------------------------------------------------------------------------
  // TabPanel cell for filter //FIXME:
  const CellTableFilter = (props) => {
    const { item, json, index } = props
    return (
      <>
        <div key={`div1-${index}`} className={scssPeptide.right_cell_last}>
          <div key={`div11-${index}`} className={scssPeptide.cell_label}>
            <Text key={`text-${index}`} className={"Title-Audiowide20px-Regular-White_75"}>{item}</Text>
          </div>
          <div key={`div12-${index}`} className={scssPeptide.cell_value}>
          </div>
        </div>
        
        {/* Step 01 */}
        <div key={`div2-${index}`} className={scssPeptide.right_cell_last}>
          <MuiTableContainer size='small'>
            <MuiTableHeadCell style={{color:'var(--green-alpha-alpha-50)',border:'none'}}>{tabs._3hydrolyzed.step1.title}</MuiTableHeadCell>
            <TableBody>
              <TableRow2Body>
                {peptideFilterIndex.map((value, count) => {
                  return (
                    <TableRow2Body key={`body-${count}`} style={{ border: 0 }}>
                      {statePeptideList.isLoading ? <LoadingAnime /> :
                        peptideFilter.slice(value.start, value.end + 1).map((item, index) => {
                          // console.log(item);
                          let idx = (value.start + index)
                          return (
                            <StyledTableCell key={`cell-${count}-${index}`} sx={index === 0 ? { padding: 0, paddingLeft: 2 } : { padding: 0 }}>
                              <FormControlLabel
                                className={scss.checkbox_text}
                                control={
                                  <MuiCheckbox //x γ-Glutamyl, Blood-brain barrier
                                    key={item.id}
                                    name={item}
                                    value={idx}
                                    checked={item.isCheck}
                                    onChange={() => {handleChangeCheckbox(item.id)}}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />}
                                label={<TagText html={`(${item.no}) ${item.displayName}`} />}/>
                            </StyledTableCell>
                          )
                        })}
                    </TableRow2Body>
                  )
                })}
              </TableRow2Body>
              <TableRow2Body>
                <div className={scss.select_block}>
                  {texts.buttons.slice(0, 2+1).map((item, index) => (
                    selectOption(item, index)
                  ))}
                </div>
              </TableRow2Body>
            </TableBody>
          </MuiTableContainer>
        </div>
        
        {/* Step 02 */}
        <div key={`div3-${index}`} className={scssPeptide.right_cell_last}>
          <MuiTableContainer size='small'>
            <MuiTableHeadCell style={{color:'var(--green-alpha-alpha-50)',border:'none'}}>{tabs._3hydrolyzed.step2.title}</MuiTableHeadCell>
            <TableBody>
              <TableRow2Body>
                <div className={scss.search_block} style={{color:'var(--white-white-50)'}}>
                  {tabs._3hydrolyzed.step2.text}
                  <div className={scss.search_bar}>
                    {
                    <StyledTextFieldStandard
                      placeholder={tabs._3hydrolyzed.step2.searchHint}
                      value={keyword}
                      onChange={(event) => handleChangeSearch(event.target.value) }
                      onKeyDown={(e) => handleKeyDownSearch(e)}
                      fullWidth
                      variant="standard"
                      error={keywordError.isError}
                      helperText={keywordError.msg}
                      inputRef={searchRef}
                    />}
                    <GreenButton aria-label="search" onClick={handleClickSearch}>Search</GreenButton>
                  </div>
                </div>
              </TableRow2Body>
            </TableBody>
          </MuiTableContainer>
        </div>
        
        {statePeptide.isLoading
        ? <LoadingAnime />
        : peptide.length === 0
          ? null
          : <div key={`div4-${index}`} className={scssPeptide.right_cell_last}>
              <TableContainer size='small' variant="outlined" className={"table_container"}
                style={{width: '100%',
                  maxHeight: '500px',
                  overflowY: 'auto'}}>
              <Table aria-label="table" style={{backgroundColor:'var(--green-alpha-alpha-20)'}}>
                {tabs._4peptide.head.map((item, index) => (
                  <MuiTableHeadCell key={index}>
                    {index === 0
                    ? item + tabs._4peptide.head_result(peptide.length)
                    : item}
                  </MuiTableHeadCell>
                ))}
                <TableBody>
                  {peptide.map((item, index) => (
                    <TableRow2Body key={index}>
                      <PeptideTableBodyCell tableBodyCell={tabs._4peptide.json} no={index}>{item}</PeptideTableBodyCell>
                    </TableRow2Body>
                  ))}
                </TableBody>
              </Table>
              </TableContainer>
            </div>}
      </>
    )
  }
  const PeptideTableBodyCell = (props) => {
    const {tableBodyCell, children, no} = props
    // console.log('props', props);
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    return (
      tableBodyCell.map((cell, index) => {
        let value = children[cell]
        let text = value
        // console.log(index, 'cell=', cell, 'text=', text);
        
        if( index === 0 ) {
          text = tabs._4peptide.body1st_text + (no+1)
        }
        
        let isShowBio = (filterBiofunction === '')
        let tableName = children["classification"] //spp.tool.ehp_tool_result_peptide.peptide arr
        tableName.forEach((item) => {
          isShowBio = isShowBio || (filterBiofunction.includes(item))
        })
        // console.log(isShowBio, 'filter=', filterBiofunction, 'tableName=', tableName);
        let length = children["length"] //spp.tool.ehp_tool_result_peptide.peptide
        let regText = convertToRegExp(filterLength)
        let regexp = new RegExp(regText)
        let isShowLength = (filterLength === '')
          || (Number(filterLength) === Number(length)) //1
          || (filterLength.includes(',') && filterLength.includes(Number(length))) //1,2,3
          || (filterLength.includes('-') && regexp.test(length)) //1-3
        // console.log(isShowLength, 'keyword=', filterLength, 'length=', length)
        let isShow = isShowBio && isShowLength
        // console.log(isShow, `=isShowBio(${isShowBio}) & isShowLength(${isShowLength})`);
        
        if( isShow ) {
          if( cell === "activity" ) {
            if( Array.isArray(value) ) {
              return (
                <MuiTableCell key={index}>
                  {value.map((item, idx) => {
                    // console.log(tableName[idx]);
                    const cls = tableName[idx]
                    let url = paths.spp.peptide.id_result(item, cls)
                    if( cls === 'multifunctionalpeptides' )
                      url = paths.spp.peptide.id_multi_result(item, cls)
                    return (
                      item !== 'N.D'
                      ? <RouterLink to={url}>
                          <TagText html={item} key={index}/><br />
                        </RouterLink>
                      : <span><TagText html={item} key={index}/><br /></span>
                      )
                  })}
                </MuiTableCell>
              )
            }
          } else {
            return (
            <MuiTableCell key={index}>
              <TagText html={text} key={index}/>
            </MuiTableCell>)
          }
        }
        return null
    }))
  }
  
  //---------------------------------------------------------------------------
  // Step 01
  const handleChangeCheckbox = (id) => {
    setPeptideFilter(prevItems => prevItems.map((item) => {
        if (item.id === id)
          return { ...item, isCheck: !item.isCheck };
        return item
      })
    )
  }
  
  const selectOption = (textButton, index) => {
    // console.log(textButton);
    return (
      <GreenButton key={index} style={{width: 150}}
        onClick={() => handleClickOption(textButton)}>{textButton}
      </GreenButton>
  )}
  const handleClickOption = (value) => {
    switch(value) {
      case texts.buttons[0]: //'Check All',
        setPeptideFilter(prevItems => prevItems.map((item) => ({ ...item, isCheck: true })))
        break
      case texts.buttons[1]: //'Uncheck All',
        setPeptideFilter(prevItems => prevItems.map((item) => ({ ...item, isCheck: false })))
        break
      case texts.buttons[2]: //'Reverse Check',
        setPeptideFilter(prevItems => prevItems.map((item) => ({ ...item, isCheck: !item.isCheck })))
        break
      default:
    }
  }
  
  //---------------------------------------------------------------------------
  // Step 02
  const [keywordError, setKeywordError] = useState(keywordErrorData.init())
  const [keyword, setKeyword] = useState('')
  const searchRef = useRef(null)
  const handleChangeSearch = (value) => {
    // console.log('value', value);
    setKeyword(value)
    setKeywordError(keywordErrorData.init())
    
    const onlySpaceNewlines = isOnlySpacesOrNewlines(value)
    if( onlySpaceNewlines.isInvalid )
      setKeywordError(keywordErrorData.error(onlySpaceNewlines.errMsg))
    if( !value.match(/^\d+(-\d+)?(,\d+(-\d+)?)*$/g) )
      setKeywordError(keywordErrorData.error('invalid input'))
    if( value === '' )
      setKeywordError(keywordErrorData.init())
    
    setTextFocus(searchRef, value)
  }
  function setTextFocus(ref, value) {
    setTimeout(() => {
      ref && ref.current.focus()
      const length = value.length;
      ref.current.setSelectionRange(length, length)
    }, 100);
  }
  const handleKeyDownSearch = (event) => {
    // console.log('handleKeyDownSearch', event);
    if (event.key === 'Enter' && !keywordError.isError)
      handleClickSearch()
  }
  const [filterBiofunction, setFilterBiofunction] = useState('')
  const [filterLength, setFilterLength] = useState('')
  const handleClickSearch = () => {
    if(!keywordError.isError) {
      let list = (peptideFilter.filter(item => item.isCheck))
      // console.log('peptide list', list);
      if( list.length > 0 ) {
        let listName = list.map(item => item.tableName)
        let listNameStr = listName.toString()
        // console.log('listNameStr', listNameStr);
        setFilterBiofunction(listNameStr)
      } else {
        setFilterBiofunction('')
      }
      setFilterLength(keyword)
    }
  }
  
  function convertToRegExp(input) {
    // 將輸入字符串轉換為合法的正則表達式
    const ranges = input.split(',').map(part => {
      part = part.trim(); // 去除前后空格
      if (/^\d+-\d+$/.test(part)) { // 匹配範圍數字（例如：4-8）
        const [start, end] = part.split('-').map(Number);
        if (start > end)
          return ''
        return `(${Array.from({ length: end - start + 1 }, (_, i) => start + i).join('|')})`;
      } else if (/^\d+$/.test(part)) { // 匹配單一數字
        return part;
      } else {
        return ''
      }
    });

    const pattern = `^(${ranges.join('|')})$`;
    return pattern;
  }
  
  
  //---------------------------------------------------------------------------
  // TabPanel
  const isShownAllTabs = true //false=multi tabs
  const ref = useRef([])
  const [tabValue, setTabValue] = React.useState(0);
  
  useEffect(() => {
    ref.current = ref.current.slice(0, texts.tabs.length)
    if (axiosState.keepRest(statePeptideList))
      apiList()
    if (axiosState.keepRest(state))
      apiResult()
    if(axiosState.keepRest(statePeptide))
      apiPeptideResult()
    // eslint-disable-next-line
  }, [])
  
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll(ref, setTabValue));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
  
  const ContentObjList = [
    <TabCell tab={tabs._0results} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._1information} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._2enzyme} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._3hydrolyzed} output={output} outputObj={outputObj}></TabCell>,
  ]

  //---------------------------------------------------------------------------
  return (
    <LayoutPage2V2 bread={words.bread} card={card}>
      <div className={scssPeptide.layout}>
        <ResultSidebar className={scssPeptide.frame_left}>
          <StyledTabs value={tabValue} style={{padding:'20px'}}
            onChange={(e, value) => state.isLoading ? null : handleChangeTab(e, value, setTabValue, isShownAllTabs, ref) }>
            {texts.tabs.map((item, index) => (
              <StyledTab key={index} label={item} index={index} />
            ))}
          </StyledTabs>
        </ResultSidebar>
        {isShownAllTabs && state.isLoading ? <LoadingAnime /> :
          <div className={scssPeptide.frame_right}>
            {ContentObjList.map((item, index) => (
              <MuiTabPanel key={index} index={index} value={tabValue} tabRef={ref}
                isShownAllTabs={isShownAllTabs}>
                {!isShownAllTabs && state.isLoading ? <LoadingAnime /> : item}
              </MuiTabPanel>
            ))}
          </div>
        }
        </div>
    </LayoutPage2V2>
  )
}

export default ToolEHPToolResult;