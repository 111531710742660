import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import v2 from 'assets/scss/_variablesV2.scss';
import scss2 from './BreadBarV2.module.scss';
import paths from 'utils/network/apiPath';
import Text, { TextLink } from 'components/tag/Text';
import jsons, { spp } from 'utils/network/jsons';
import BreadcrumbV2 from './BreadcrumbV2';
import BreadTextV2 from './BreadTextV2';
import BreadResultV2 from './BreadResultV2';
import BreadButtonV2 from './BreadButtonV2';

//---------------------------------------------------------------------------

export class breadData {
  static create(text, title, text3rd = '', text4st = '', text5st = '') {
    return { text, title, text3rd, text4st, text5st }
  }
}

const proteinResultBoxPath = [
  paths.spp.protein.class_search(),
  paths.spp.protein.class_count(),
  paths.spp.protein.source_search(),
  paths.spp.protein.quick_search(),
  paths.spp.protein.fragment_count(),
]
const peptideResultBoxPath = [
  paths.spp.peptide.multi_search(),
  paths.spp.peptide.class_search(),
  // paths.spp.peptide.fragment_count, //?
]
const pathPeptideQuickResultBox = [
  paths.spp.peptide.quick_search(),
]
const toolDescBoxPath = [
  paths.spp.tool.hotspot_search(),
  paths.spp.tool.aasd_tool(),
  paths.spp.tool.peptide_calculator(),
  paths.spp.peptide.multi_search(),
  paths.spp.tool.multi_cross_analysis(),
  paths.spp.tool.ehp_tool_search(),
  paths.spp.tool.bpp_tool(),
  paths.spp.tool.pp_structure_prediction(),
  paths.spp.tool.pp_docking(),
  paths.spp.tool.hotspot_css_tool(),
  paths.spp.tool.pdcaas(),
]
const pathToolACEiPP = [
  paths.spp.tool.aceipp(),
  paths.spp.tool.aceipp_sf(),
  paths.spp.tool.aceipp_pl(),
]
const pathToolAnOxPP = [
  paths.spp.tool.anoxpp(),
  paths.spp.tool.anoxpp_sf(),
  paths.spp.tool.anoxpp_pl(),
]
const pathToolACEiPPAnOxPP = [ //ACEiP-Predictor and AnOxP-Predictor share together
  paths.spp.tool.aceipp_anoxpp_pp(),
]

const MuiTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="top" arrow classes={{ popper: className }} {...props} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: v2.green90,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: v2.green90,
    color: v2.bgGreen,
    fontSize: '18px',
  },
}));

//---------------------------------------------------------------------------
export default function BreadBarV2(props) {
  const { bread } = props
  //console.log('BreadBar', props);

  const [showBreadResult, setShowBreadResult] = useState(false);
  const [showBreadButton, setShowBreadButton] = useState(false)
  const [showToolsDesc, setShowToolsDes] = useState(false);
  
  const [isProteinSearch, setIsProteinSearch] = useState(false);
  const [isPeptideMultiSearch, setIsPeptideMultiSearch] = useState(false);
  const [isPeptideQuickSearch, setIsPeptideQuickSearch] = useState(false);
  const [isToolACEiPP, setIsToolACEiPP] = useState(false)
  const [isToolAnOxPP, setIsToolAnOxPP] = useState(false)

  const [searchParams] = useSearchParams()
  const dataset = searchParams.get(paths.params.dataset)
  // console.log('dataset', dataset);
  
  let location = useLocation();
  useEffect(() => {
    let pathname = location.pathname
    if ( proteinResultBoxPath.includes(location.pathname) 
      || peptideResultBoxPath.includes(location.pathname)
      || pathPeptideQuickResultBox.includes(location.pathname) ) {
      setShowBreadResult(true)
      proteinResultBoxPath.includes(location.pathname) ? setIsProteinSearch(true) : setIsProteinSearch(false)
      peptideResultBoxPath[0].includes(location.pathname) ? setIsPeptideMultiSearch(true) : setIsPeptideMultiSearch(false)
      pathPeptideQuickResultBox.includes(location.pathname) ? setIsPeptideQuickSearch(true) : setIsPeptideQuickSearch(false)
    } else {
      setShowBreadResult(false)
    }
    
    if( pathToolACEiPP.includes(pathname) ) {
      setShowBreadButton(true)
      setIsToolACEiPP(true)
    }
    if( pathToolAnOxPP.includes(pathname) ) {
      setShowBreadButton(true)
      setIsToolAnOxPP(true)
    }
    if( pathToolACEiPPAnOxPP.includes(pathname) ) {
      setShowBreadButton(true)
      const jsonsTable = spp.tool.aceipp_anoxpp_pre_pool_table
      const datasetList = jsonsTable.dataset
      const datasetNum = Number(dataset)
      if( datasetNum === datasetList.ACEiPs )
        setIsToolACEiPP(true)
      else if( datasetNum === datasetList.AnOxPs )
        setIsToolAnOxPP(true)
    }
    
    if ( toolDescBoxPath.includes(location.pathname) ) {
      setShowToolsDes(true)
    } else {
      setShowToolsDes(false)
    }

    return () => {
      // cleanup
    };
  }, [location]);

  return (
    <div className={scss2.layout}>
      <div className={scss2.frame}>
      <BreadcrumbV2 bread={bread} />
      <BreadTextV2 bread={bread} />
      
      {/* http://localhost:3000/protein_quick */}
      {showBreadResult
        ? <BreadResultV2
          searchInput={props.searchInput} jsonInput={props.jsonInput}
          searchOutput={props.searchOutput}
          isProteinSearch={isProteinSearch}
          isPeptideMultiSearch={isPeptideMultiSearch}
          isPeptideQuickSearch={isPeptideQuickSearch}
          />
        : null}
        
      {/* http://localhost:3000/tool_aceipp */}
      { showBreadButton
        ? <BreadButtonV2
            isToolACEiPP={isToolACEiPP}
            isToolAnOxPP={isToolAnOxPP}
          />
        : null}
        
      {/* http://localhost:3000/tool_aasd */}
      { showToolsDesc
        ? <div className={scss2.top_desc}>
            <Text className='Contents-QuanticoBody16px-Medium-White_75' >
              {/* <MuiTooltip title={props.tooltipOfDesc !== undefined ? props.tooltipOfDesc : props.desc} placement="top"
                className='Contents-QuanticoCaption12px-Bold'
              > */}
                {props.desc}
                {/* </MuiTooltip> */}
            </Text>
          </div>
        : null}
    </div>
    </div>
  )
}