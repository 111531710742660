import { Box, TableHead, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import scssBPP from './ToolPeptideBitternessPredict.module.scss';

import { breadData } from 'components/bread/BreadBarV2';
import { TagText } from 'components/tag/Text';
import { TableHeadRow, TableRow2Body, MuiTableContainer } from 'components/table/MuiTableV2';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import StyledTextField from 'components/tool/StyledTextField';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { checkInvalidSeq, getFullPath, isOnlySpacesOrNewlines } from 'utils/general';
import paths from "utils/network/apiPath";
import { linkData } from 'utils/links';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import GreenButton from 'components/button/GreenButton';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_pc
// Go in: homepage -> 'Tools' tab -> 'PepPropCalc' button -> this page
// path: paths.spp.tool.peptide_calculator(),
const texts = {
  bread: breadData.create('Tools', 'PepPropCalc', 'PepPropCalc'),
  desc: "PepPropCalc is a peptide sequence physicochemical property calculation tool that analyzes various characteristics of bioactive peptides, including isoelectric point, molecular weight, hydrophobic residue ratio, net charge, and more. This tool enables researchers to efficiently evaluate peptide properties for biochemical research, molecular modeling, and bioinformatics applications.",

  title: 'PepPropCalc',

  input: 'Sequences input',
  searchHint: 'Please enter single letter sequences',
  required: 'Required',

  table: {
    text: 'Amino acid list',
    title: [
      'Amino acid', 'Three-letter', 'Single letter', 'Molecular weight', 'Isoelectric point', 'Hydrophilicity',
    ]
  }
}

class itemData {
  static create(amino, three, single, molecular, isoelectric, hydrophilicity) {
    return { amino, three, single, molecular, isoelectric, hydrophilicity }
  }
  static output = ['amino', 'three', 'single', 'molecular', 'isoelectric', 'hydrophilicity']
}

const items = [
  itemData.create('Alanine', 'Ala', 'A', '89.09', '6.02', '1.8'),
  itemData.create('Arginine', 'Arg', 'R', '174.20', '10.76', '-4.5'),
  itemData.create('Asparagine', 'Asn', 'N', '132.12', '5.41', '-3.5'),
  itemData.create('Aspartic acid', 'Asp', 'D', '133.10', '2.97', '-3.5'),
  itemData.create('Cysteine', 'Cys', 'C', '121.16', '5.02', '2.5'),
  itemData.create('Glutamine', 'Gln', 'Q', '146.15', '5.65', '-3.5'),
  itemData.create('Glutamic acid', 'Glu', 'E', '147.13', '3.22', '-3.5'),
  itemData.create('Glycine', 'Gly', 'G', '75.07', '5.97', '-0.4'),
  itemData.create('Histidine', 'His', 'H', '155.16', '7.59', '-3.2'),
  itemData.create('Isoleucine', 'Ile', 'I', '131.17', '6.02', '4.5'),
  itemData.create('Leucine', 'Leu', 'L', '131.17', '5.98', '3.8'),
  itemData.create('Lysine', 'Lys', 'K', '146.19', '9.74', '-3.9'),
  itemData.create('Methionine', 'Met', 'M', '149.21', '5.75', '1.9'),
  itemData.create('Phenylalanine', 'Phe', 'F', '165.19', '5.48', '2.8'),
  itemData.create('Proline', 'Pro', 'P', '115.13', '6.30', '-1.6'),
  itemData.create('Serine', 'Ser', 'S', '105.09', '5.68', '-0.8'),
  itemData.create('Threonine', 'Thr', 'T', '119.12', '6.53', '-0.7'),
  itemData.create('Tryptophan', 'Trp', 'W', '204.23', '5.89', '-0.9'),
  itemData.create('Tyrosine', 'Tyr', 'Y', '181.19', '5.66', '-1.3'),
  itemData.create('Valine', 'Val', 'V', '117.15', '5.97', '4.2'),
]

const MuiTableHead = (props) => (
  <TableHead className="bgc-dark-green">
    {props.children}
  </TableHead>
)

const MuiTableHeadCell = (props) => (
  <MuiTableHead>
    <TableRow>
      <StyledTableCell style={{ color: 'var(--chart-colors-red)' }} {...props}>
        {props.children}
      </StyledTableCell>
    </TableRow>
  </MuiTableHead>
)

const rowChildren = (item, index) => {
  return (
    <TableRow2Body key={index}>
      {itemData.output.map((output, index2) => {
        let align = "center"
        if( index2 === 0 )
          align = 'left'
        // if( index2 >= 3 )
        //   align = 'right'
        return <StyledTableCell key={index2} align={align}><TagText html={item[output]} /></StyledTableCell>
      })}
    </TableRow2Body>
  )
}

//---------------------------------------------------------------------------
const ToolPeptideCalculator = ({ setInfo }) => {
  let location = useLocation()
  let bread = linkData('PepPropCalc', getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log('bread3rd', bread);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  
  const navigate = useNavigate()

  const [isSearchError, setSearchError] = useState(false)
  const [keyword, setKeyword] = useState('')

  const [isSeqInputError, setIsSeqInputError] = useState(false)
  const [seqInputErrMsg, setSeqInputErrMsg] = useState("")

  const handleChangeSearch = (value) => {
    setKeyword(value)

    cookie.setCookie(cookie.keys.peptide.calPeptideSearchSeq, value)

    if (value === "") {
      setIsSeqInputError(false)
      setSeqInputErrMsg("")
    }
    setSearchError(false)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter')
      handleClickSearch()
  }
  const handleClickSearch = () => {
    const isInvalidCharObj = checkInvalidSeq(keyword);
    const onlySpaceNewlines = isOnlySpacesOrNewlines(keyword)

    if (isInvalidCharObj.isInvalid) {
      setIsSeqInputError(true)
      setSeqInputErrMsg(isInvalidCharObj.errMsg)
      setInfo(snackInfo.openError(isInvalidCharObj.errMsg))
    } else if (onlySpaceNewlines.isInvalid) {
      setIsSeqInputError(true)
      setSeqInputErrMsg(onlySpaceNewlines.errMsg)
      setInfo(snackInfo.openError(onlySpaceNewlines.errMsg))
    } else {
      setIsSeqInputError(false)
      setSeqInputErrMsg("")

      if (keyword !== '') {
        navigate(paths.spp.tool.peptide_calculator_result(keyword))
      }
      else {
        setSearchError(true)
      }
    }
  }

  useEffect(() => {
    const calPeptideSearchSeq = cookie.getCookie(cookie.keys.peptide.calPeptideSearchSeq)

    if (calPeptideSearchSeq !== undefined) {
      setKeyword(calPeptideSearchSeq)
    }
    return () => {
    };
  }, []);

  return (
    <LayoutPageV2 bread={texts.bread} desc={texts.desc}>
      
        <ToolSingleBlock title={texts.input} mainBlockContent={
          <div style={{ width: '100%' }}>
            <div className={scssBPP.search_block}>
              <StyledTextField
                // sx={{ m: 0, flex: 1, width: '97%' }}
                placeholder={texts.searchHint}
                // label={texts.required}
                label={texts.searchHint}
                required
                value={keyword}
                onChange={(event) => { handleChangeSearch(event.target.value) }}
                onKeyDown={handleKeyDown}
                error={isSeqInputError}
                helperText={seqInputErrMsg}
              />
              <GreenButton aria-label="search" onClick={handleClickSearch}> Search</GreenButton>
            </div>
          </div>} />

        <ToolSingleBlock title={texts.table.text} mainBlockContent={
          <MuiTableContainer size="small">
            <TableHeadRow>
              {texts.table.title.map((title, index) => (
                <StyledTableCell key={index}
                  align={(index === 0) ? 'left' : 'center'}
                  style={{color: (index === 2) ? 'var(--green-alpha-alpha-50)' : 'var(--white-white-75)'}}
                >
                  {title}</StyledTableCell>
              ))}
            </TableHeadRow>
            <TableBody style={{border:'none'}}>
              {items.map((item, index) => (
                rowChildren(item, index)
              ))}
            </TableBody>
          </MuiTableContainer>} />
          
    </LayoutPageV2>
  )
}
export default ToolPeptideCalculator