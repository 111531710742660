import React, { useEffect, useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import v2 from 'assets/scss/_variablesV2.scss';
import scss from './AboutUs.module.scss'
import { breadData } from 'components/bread/BreadBarV2';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import ChangelogCard from 'components/card/ChangelogCard';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { snackInfo } from 'components/SnackBar';
import jsons, { spp } from 'utils/network/jsons';
import cookie from 'utils/cookie';

//---------------------------------------------------------------------------
const jsonsList = jsons.site.timeline_list
const texts = {
  bread:  breadData.create('About us', 'Project Timeline'),
}

/* 
const getFakeTimelineData = (number) => {
  const data = {
    // 2023 page
      31: { time: "2023.02", content: "Timeline 2311", variant: "purple" },
      32: { time: "2023.05", content: "Timeline 2310", variant: "green" },
      33: { time: "2023.08", content: "Timeline 2309", variant: "purple" },
      34: { time: "2023.09", content: "Timeline 2308", variant: "green" },
      35: { time: "2023.10", content: "Timeline 2307", variant: "green" },
      36: { time: "2023.12", content: "Timeline 2306", variant: "purple" },

      // 2024 page
      41: { time: "2024.05", content: "Timeline 2405\nTimeline 2405\nTimeline 2405", variant: "purple" },
      42: { time: "2024.06", content: "Timeline 2406", variant: "purple" },
      43: { time: "2024.07", content: "Timeline 2407", variant: "green" },
      44: { time: "2024.10", content: "Timeline 2410", variant: "green" },
      45: { time: "2024.11", content: "Timeline 2411", variant: "green" },
      46: { time: "2024.12", content: "Timeline 2412", variant: "purple" },
  };
  return data[number] || { time: "", content: "", variant: "purple" }; 
};
 */

function cardItem(update_time = '', update_year = 2023, update_month = 1, update_item = [] ) {
  return {
    update_time,
    update_year,
    update_month,
    update_item, //content obj (front-end customization key)
}}
const TimeBox = ({ cardData, number, yaer }) => {
  const index = (yaer == 2024 ? number-41+6 : number-31)
  // console.log(number, yaer, index);
  // console.log(cardData);
  
  // const timelineData = getFakeTimelineData(number);
  let timelineData = cardData ? cardData[index] : cardItem()
  if( !timelineData )
    timelineData = cardItem()
  // console.log(timelineData);
  const className = `area${number}`;
  const isLargeCard = number === 46; //2024.12 LargeCard
  
  return (
    <div className={scss[className]}>
      <ChangelogCard 
        time={timelineData.update_time} 
        content={timelineData.update_item}
        size={isLargeCard ? 'large' : 'normal'}
      />
    </div>
  );
};

const activeName = {
  first: 'first', //2023
  second: 'second', //2024
}

//---------------------------------------------------------------------------
export default function AboutUs(props) {
  const { setInfo } = props
  
  const [backDisabled, setBackDisabled] = useState(true) 
  const [nextDisabled, setNextDisabled] = useState(false) 
  const colorBack = getColor(backDisabled)
  const colorNext = getColor(nextDisabled)
  const [currentPage, setCurrentPage] = useState(1) 
  const [activeImage, setActiveImage] = useState(activeName.second) 

  
  function getColor(disable) {
    const color = disable ? v2.white50 : v2.greenLightActive
    return color
  }
  
  // const totalBoxes = 8;
  const [hoverIndex, setHoverIndex] = useState(-1);

  const handleLeftClick = () => {
    setActiveImage('first')
    setCurrentPage((prev) => prev - 1)
  }
  
  const handleRightClick = () => {
    setActiveImage('second')
    setCurrentPage((prev) => prev + 1)
  }

  useEffect(() => {
    setBackDisabled(currentPage === 0) 
    setNextDisabled(currentPage === 1) 
  }, [currentPage])

  const ArrowRightIcon = () => {
    return (
      <KeyboardDoubleArrowRightIcon 
          style={{ 
            color: colorNext, 
            fontSize: '48px', 
            transition: 'color 0.2s ease',
            cursor: nextDisabled ? 'not-allowed' : 'pointer'
          }} 
        />
    )
  }

  /* 
  useEffect(() => {
    let timer = null;
    
    for (let i = 0; i < totalBoxes; i++) {
      timer = setTimeout(() => {
        setHoverIndex(i);
      }, i * 500); //0.5s
    }
    
    return () => clearTimeout(timer);
  }, []); */
  
  //api v------------------------------------------------------------------------------
  const [cardData, setCardData] = useState([cardItem()]) //object array
  function getCardData(items) {
    let result = []
    if(!Array.isArray(items))
      return []
    
    result = Object.values(
      items.reduce((acc, item) => {
        const { update_time, update_year, update_month, ...rest } = item;
        
        if (!acc[update_time]) {
          acc[update_time] = cardItem(update_time, update_year, update_month)
        }
        
        acc[update_time].update_item.push(rest);
        // console.log(acc);
        return acc;
      }, {})
    );
    // console.log(result);
    return result
  }
  
  // const [listItems, setListItems] = useState([])
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiList = () => {
    const input = jsonsList.input()
    const config = apiConfig.site.timeline_list(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let items = result.data[spp.common.output.items]
        // setListItems(items)
        
        let data = getCardData(items)
        setCardData(data)
        // console.log(data);
      }
    }).catch(err => {
      // console.log('err', err);
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  useEffect(() => {
    apiList()
  }, []);
  //api ^------------------------------------------------------------------------------
  
  //------------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread}>
    <div className={scss.layout}>

      {/* 2023 (bgFirst) */}
      <div 
        className={`${scss.center} ${scss.bgFirst}`}
        style={{
          transform: activeImage === 'second' ? 'translateX(-100%)' : 'translateX(0)',
          transition: 'transform 0.5s ease',
          left: 0 
        }}
      >
        <div className={scss.fg}>
          {state.isLoading
            ? null
            : Array.from({ length: 6 }, (_, i) => (
            <TimeBox key={i} number={i+31} isHovered={hoverIndex === i}
              yaer={2023}
              cardData={cardData}/>
          ))}
        </div>
      </div>

      {/* 2024 (bgSecond) */}
      <div 
        className={`${scss.center} ${scss.bgSecond}`}
        style={{
          transform: activeImage === 'second' ? 'translateX(0)' : 'translateX(100%)', 
          transition: 'transform 0.5s ease',
          left: 0 
        }}
      >
        <div className={scss.fg}>
          {state.isLoading
            ? null
            : Array.from({ length: 6 }, (_, i) => (
            <TimeBox key={i} number={i+41} isHovered={hoverIndex === i}
              yaer={2024}
              cardData={cardData}/>
          ))}
        </div>
      </div>

      <button
        className={`${scss.iconButton} ${scss.left}`}
        onClick={handleLeftClick}
        disabled={backDisabled}
      >
        <KeyboardDoubleArrowLeftIcon 
          style={{ 
            color: colorBack, 
            fontSize: '48px', 
            transition: 'color 0.2s ease',
            cursor: backDisabled ? 'not-allowed' : 'pointer'
          }} 
        />
      </button>

      <button
        className={`${scss.iconButton} ${scss.right}`}
        onClick={handleRightClick}
        disabled={nextDisabled}
      >
        <ArrowRightIcon />
        {/* <KeyboardDoubleArrowRightIcon 
          style={{ 
            color: colorNext, 
            fontSize: '48px', 
            transition: 'color 0.2s ease',
            cursor: nextDisabled ? 'not-allowed' : 'pointer'
          }} 
        /> */}
      </button>
    </div>
  </LayoutPageV2>
  )
}