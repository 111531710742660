import React, {useState} from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';

import DarkPurpleButton from './DarkPurpleButton';
import DarkGreenButton from "./DarkGreenButton";
import { spp } from "utils/network/jsons";

//------------------------------------------------------------------------------
const jsonsList = spp.tool.ppsp_ppd_job_list
const textProcessing = String(jsonsList.status.processing).to1stUpperCase()
const textStop = String(jsonsList.status.stopped).to1stUpperCase()
export const filterStatus = [
  { label: String(jsonsList.status.ng).to1stUpperCase(), icon: <HighlightOffOutlinedIcon /> },
  { label: textStop, icon: <RemoveCircleOutlineIcon /> },
  { label: String(jsonsList.status.ok).to1stUpperCase(), icon: <CheckCircleOutlineIcon /> },
  { label: textProcessing, icon: <DonutLargeOutlinedIcon /> },
];

//------------------------------------------------------------------------------
const FilterButton = ({selectedFilter, setSelectedFilter, onClick, isDocking}) => {
  /* const [selectedFilter, setSelectedFilter] = useState(
    filters.map((filter) => filter.label)
  ); */

  const handleFilterClick = (filterLabel) => {
    let filter = []
    if (selectedFilter.includes(filterLabel)) {
      filter = selectedFilter.filter((f) => f !== filterLabel)
      if( !filter.includes(textProcessing) ) {
        filter = filter.filter((f) => (f !== jsonsList.status.waiting && f !== jsonsList.status.todo ))
      }
      if( !filter.includes(textStop) ) {
        filter = filter.filter((f) => (f !== jsonsList.status.stopping ))
      }
    } else {
      filter = [...selectedFilter, filterLabel]
    }
    // console.log(`filter`, filter);
    if( filter.includes(textProcessing) ) {
      filter.push(jsonsList.status.waiting)
      filter.push(jsonsList.status.todo)
    }
    if( filter.includes(textStop)) {
      filter.push(jsonsList.status.stopping)
    }
    // console.log(`filter'`, filter);
    setSelectedFilter(filter);
    onClick(filter)
  };


  return (
    // <ButtonGroup variant="outlined" >
    <div>
      {filterStatus.map((filter) =>
        <DarkGreenButton
          key={filter.label}
          onClick={() => handleFilterClick(filter.label)}
          style={{
            backgroundColor: selectedFilter.includes(filter.label)
              ? "var(--green-dark-active2)"
              : null,
            color: selectedFilter.includes(filter.label)
              ? "var(--green-normal)"
              : null,
          }}
          sx={{ marginRight: "15px", outline: "none" }}
          size="small"
        >
          <>
            {React.cloneElement(filter.icon, {
              style: { marginRight: "8px" },
            })}
            {filter.label}
          </>
        </DarkGreenButton>
      )}
    </div>
    // </ButtonGroup>
  );
}

export default FilterButton;

export const PurpleFilterButton = ({selectedFilter, setSelectedFilter, onClick}) => {

  const handleFilterClick = (filterLabel) => {
    let filter
    if (selectedFilter.includes(filterLabel)) {
      filter = selectedFilter.filter((f) => f !== filterLabel)
    } else {
      filter = [...selectedFilter, filterLabel]
    }
    // console.log('filter', filter);
    setSelectedFilter(filter);
    onClick(filter)
  };


  return (
    <div>
      {filterStatus.map((filter) =>
        <DarkPurpleButton
          key={filter.label}
          onClick={() => handleFilterClick(filter.label)}
          style={{
            backgroundColor: selectedFilter.includes(filter.label)
              ? "var( --logo-colors-logo-purple-10)"
              : null,
            color: selectedFilter.includes(filter.label)
              ? "var(--logo-colors-logo-purple-light)"
              : null,
          }}
          sx={{ marginRight: "15px", outline: "none" }}
          size="small"
        >
          <>
            {React.cloneElement(filter.icon, { style: { marginRight: "8px" } })}
            {filter.label}
          </>
        </DarkPurpleButton>
      )}
    </div>
  );
}