import { useNavigate, useSearchParams } from "react-router-dom";
import { TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';

import v2 from "assets/scss/_variablesV2.scss";
import scssPeptide from 'views/peptide/PeptideDFBPIDResult.module.scss';
import paths from "utils/network/apiPath";
import { breadData } from 'components/bread/BreadBarV2';
import LayoutPageV2 from "layouts/LayoutPageV2";
import cookie from "utils/cookie";
import { Code } from "components/tag/Text";
import { getRow } from 'components/table/table';
import { useEffect, useState } from "react";
import StyledTableCell from 'components/table/StyledTableCell';
import { MuiTableContainer as TableContainer } from "components/table/MuiTableV2";
import { spp } from "utils/network/jsons";
import useLocationSPP from "utils/location";
import { snackInfo } from "components/SnackBar";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';

//------------------------------------------------------------------------------
// http://localhost:3000/tool_ppsp_r?id=177
// http://localhost:3000/tool_ppsp_r?id=180
// http://localhost:3000/tool_ppsp_r?id=118
// error
// http://localhost:3000/tool_ppsp_r?id=151
// http://localhost:3000/tool_ppsp_r?id=116
const jsonsJob = spp.tool.ppsp_ppd_job

const MuiTableContainer = (props) => (
  <TableContainer size='small' isPurple={props.isPurple}>
    {props.children}
  </TableContainer>
)

const MuiTableCell = (props) => (
  <StyledTableCell style={{ whiteSpace: 'pre-line', maxWidth: '900px', wordBreak:'break-word' }} {...props}>
    {props.children}
  </StyledTableCell>
)

const MuiTableHeadCell1st = (props) => {
  const isDocking = props.isDocking ? props.isDocking : false
  const backgroundColor = isDocking ? 'var(--logo-colors-logo-purple-16)' : 'var(--green-alpha-alpha-16)'
  return (
  <StyledTableCell style={{ width: 190, backgroundColor: backgroundColor}} align='right'
    component="th" scope="row" {...props}>
    {props.children}
  </StyledTableCell>
)}

//---------------------------------------------------------------------------
export default function ToolPPStructurePredictionResult({ setInfo }) {
  const [searchParams] = useSearchParams()
  const jobID = searchParams.get(paths.params.id)
  // console.log('id', jobID);  
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  let texts = {
    bread_sp:  breadData.create('Tools', 'Prediction Result', bread3rd, 'Prediction Result'),
    bread_docking:  breadData.create('Tools', 'Docking Result', bread3rd, 'Docking Result'),
    
    table: {
      head: [
        // 'ID',
        'Name',
        "Sequence length",
        "Sequence",
      ],
      json: [ //for style
        // "job_id",
        jsonsJob.output.display_name,
        "sequence_length",
        "sequence",
      ],
    },
  }
  texts.table_docking = {
    head: [
      ...texts.table.head.slice(0, 0+1),
      "Protein sequence length",
      "Protein sequence",
      "Peptide sequence length",
      "Peptide sequence",
    ],
    json: [ //for style
      ...texts.table.json.slice(0, 2+1),
      "sequence_length_b",
      "sequence_b",
    ],
  }
  
  const [bread, setBread] = useState(texts.bread_sp)
  const [isDocking, setIsDocking] = useState(false)
  const [dockingSequence, setDockingSequence] = useState('')
  const [jobSequence, setJobSequence] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [jobError, setJobError] = useState('')
  const [isError, setIsError] = useState(false)
  
  //------------------------------------------------------------------------------
  const navigate = useNavigate()
  let location = useLocationSPP()
  const [state, setState] = useState(axiosState.init())
  const apiJob = () => {
    setState(axiosState.loadingState())
    
    const input = jsonsJob.input(jobID)
    const config = apiConfig.tool.ppsp_ppd_job(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        let data = result.data
        // console.log(data)
        
        const type = data[jsonsJob.output.job_type]
        const isD = type ? (type === jsonsJob.job_type.docking) : false
        setIsDocking(isD)
        const error = data[jsonsJob.output.error_message]
        setJobError(error)
        const isErr = (error ? true : false)
        setIsError(isErr)
        // console.log(isErr, error);
        const name = data[jsonsJob.output.display_name]
        setDisplayName(name)
        const input = data[jsonsJob.output.job_input]
        const lv1arr = input ? input[jsonsJob.output_job.lv1arr] : ''
        const lv1arr0 = lv1arr ? lv1arr[0] : ''
        const lv2obj = lv1arr0 ? lv1arr0[jsonsJob.output_job.lv2obj] : ''
        const lv3seq = lv2obj ? lv2obj[jsonsJob.output_job.lv3] : ''
        const lv3seqUp = lv3seq?.toUpperCase()
        setJobSequence(lv3seqUp)
        if( isD ) {
          setBread(texts.bread_docking)
          const lv1arr1 = lv1arr ? lv1arr[1] : ''
          const lv2obj2 = lv1arr1 ? lv1arr1[jsonsJob.output_job.lv2obj] : ''
          const lv3seq2 = lv2obj2 ? lv2obj2[jsonsJob.output_job.lv3] : ''
          const lv3seq2Up = lv3seq2?.toUpperCase()
          setDockingSequence(lv3seq2Up)
          
          cookie.setCookie(cookie.keys.tool.ppspJobType, 'd') // 'd' same with \public\af3Bv8NK\index_af3.html
        } else {
          cookie.setCookie(cookie.keys.tool.ppspJobType, 'sp')
        }
        setRows(getRows(texts, output, isD, isErr))
        
        //for molstar
        const jobName = data[jsonsJob.output.job_name]
        const fileName = jobName
        cookie.setCookie(cookie.keys.tool.ppspJobName, jobName)
        cookie.setCookie(cookie.keys.tool.ppspJobFile, fileName)
        // console.log('jobName', jobName);
        // console.log('fileName', fileName);
        
        //move to ${paths.af3}/index_af3.html
        // const cifFile = `${jobName}/${jobFile}_model_0.cif` // fold_eyklvvvgac_model_0.cif
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //------------------------------------------------------------------------------
  const [output, setOutput] = useState({})
  const [rows, setRows] = useState(getRows(texts, '', isDocking, isError))
  function getRows(texts, output, isDocking, isError) {
    // console.log('isDocking=', isDocking, 'isError=', isError);
    let table = texts.table
    if(isDocking) {
      table = texts.table_docking
    }
    if(isError) {
      table.head.push('Message')
      table.json.push(jsonsJob.output.error_message)
    }
    
    // console.log('table', table);
    return ([
      getRow(table, output),
    ])
  }
  
  const rowChildren = (row, index) => {
    // console.log(row);
    let name = row.name
    let value = row.value
    // console.log("00",name, value);
    switch(name) {
      case 'ID':
        value = jobID;
        break
      case 'Name':
        value = displayName;
        break
      case "Sequence length":
      case "Protein sequence length":
        value = jobSequence.length
        break
      case "Sequence":
      case "Protein sequence":
        value = (
          <div className={scssPeptide[getScssNameCell(name)]}>
            <Code>{jobSequence.toCode()}</Code>
          </div>)
        break
      case "Peptide sequence length":
        value = dockingSequence.length
        break
      case "Peptide sequence":
        value = (
          <div className={scssPeptide[getScssNameCell(name)]}>
            <Code>{dockingSequence.toCode()}</Code>
          </div>)
        break
      case 'Message':
        value = <span style={{color: v2.red}}>{jobError}</span>;
        break
      default:
        break
    }
    
    return (
      <TableRow key={name}>
        <MuiTableHeadCell1st isDocking={isDocking}>{name}</MuiTableHeadCell1st>
        <MuiTableCell>{value}</MuiTableCell>
      </TableRow>)
  }
  
  function getScssNameCell(name = '', json = '') {
    // console.log(name, json);
    if (name === "Sequence" || json === "sequence" ||
      name === "Protein sequence" || 
      name === "Peptide sequence" || json === "sequence_b" )
    {
      if(isDocking) {
        return 'right_cell_code_purple'
      }
      else
        return 'right_cell_code'
    }
    return ''
  }
  
  useEffect(() => {
    apiJob()
  }, [])
  
  //------------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={bread}>
        {!state.isLoading
        && <>
        <MuiTableContainer isPurple={isDocking}>
            <TableBody style={{border:'none'}}>
              {rows[0].map((row, index) => (
                rowChildren(row, index)
              ))}
            </TableBody>
          </MuiTableContainer>
        
        {!isError && <>
          <div style={{height:'10px'}}></div>
          
          <div style={{
            position: 'relative',
            // width: '95vw', 
            // height: '95vh',
            }}>
            <iframe
              src={`${process.env.PUBLIC_URL}${paths.af3}/index_af3.html`}
              width="100%"
              height="540px" //40px + height(molstar index.html #app)
              title="JS MolstarViewer"
              style={{ border: 'none' }}
            />
          </div>
        </>}
      </>}
    </LayoutPageV2>);
};
