import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import scss from './Dialog.module.scss';
import GreenButton from 'components/button/GreenButton';

const AlertDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  confirmText = 'Confirm',
  cancelText = 'Cancel'
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={scss.dialog}
    >
      <DialogTitle className={scss.dialog_title}>
        {title}
      </DialogTitle>
      <DialogContent className={scss.dialog_content}>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={scss.dialog_actions}>
        <GreenButton onClick={onClose}>
          {cancelText}
        </GreenButton>
        <GreenButton onClick={onConfirm}>
          {confirmText}
        </GreenButton>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;